.fui-compare-bar {
    position: fixed;
    bottom: 0;
    background: $white;
    position: fixed;
    background: $white;
    width: 100%;
    height: 80px;
    bottom: 0;
    left: 0;
    z-index: 101;
    bottom: -180px;
    transform: translateY();
    transition: transform 0.3s ease;
    padding: 15px 0;

    &.is-open {
        bottom: 0; }

    .fui-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;

        .fui-compare-item {
            font-weight: $font-medium;

            .compare-label {
                margin-right: 6px; }

            .compare-item {
                display: inline-block;
                color: $white;
                font-weight: $font-regular;
                background: $darkgray50;
                height: 28px;
                line-height: 28px;
                margin-left: 6px;
                padding: 0 12px;
                border-radius: 14px; } }

        .fui-compare-action {
            text-align: right;

            .fui-button {
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0; } } } }

    @media screen and (max-width: 768px) {
        .fui-compare-action {
            .fui-button {
                min-width: 0;
                padding: 0 20px; } } } }

.fui-modal-content.is-compare-table {
    width: calc(100% - 20px);
    max-width: 994px;
    padding: 0 30px;
    max-height: 660px;

    .fui-modal-body {
        height: 465px;
        max-height: 465px;
        padding: 0;

        .fui-plan-table {
            margin: 0;
            box-shadow: inset 0 0 0px 2px rgba(47, 89, 196, 0.2); } }

    .fui-modal-head,
    .fui-modal-foot {
        text-align: left; }

    .fui-plan-table-item.more-height,
    .fui-plan-table-item.more-height .fui-plan-table-label {
        height: 62px !important; }

    .fui-modal-foot {
        margin: 0 -6px;

        .fui-button {
            margin: 0 6px; } }

    .fui-plan-table-header {
        padding-top: 0;
        position: relative;
        float: left;

        .fui-plan-table-item {
            box-shadow: inset 0 -1px 0 0 #95a3a4; }

        .fui-plan-table-item.more-height .fui-plan-table-label {
            height: 62px;
            line-height: 62px; }

        .fui-plan-table-item.is-title,
        .fui-plan-table-item.is-title .fui-plan-table-label {
            height: 155px;
            line-height: 155px; } }

    .fui-plan-table-body {
        display: flex;

        .fui-plan-table-segment {
            flex: 1;
            flex-grow: 1;

            .fui-plan-table-title {
                display: inline-block;
                width: 100%;
                height: 155px;
                text-align: center;
                box-shadow: inset 0 0 1px 1px rgba(47, 89, 196, 0.2);

                .fui-button {
                    margin: 0; }

                h1 {
                    margin: ($space * 2) 0; } }

            > .fui-plan-table-item {
                padding: $space;
                text-align: center;
                box-shadow: inset 0 0 1px 1px rgba(47, 89, 196, 0.2);

                &.more-height {
                    color: $darkgray50;

                    &:first-child {
                        color: $black50; } } } } }

    @media screen and (max-width: 768px) {
        padding: 0 15px;
        max-height: 530px;
        margin-left: 10px;
        margin-right: 10px;

        .fui-modal-head {
            text-align: center; }

        .fui-modal-foot {
            text-align: center;

            .fui-button {
                margin: 0 6px; } }

        .fui-modal-body {
            position: relative;
            max-height: 350px;
            margin: 0 -15px;

            .fui-plan-table {
                position: relative;
                display: block;
                padding: 0 0 0 15px;
                box-shadow: none;

                .fui-plan-table-header {
                    .fui-plan-table-item .fui-plan-table-label {
                        width: 84px;
                        padding: 0 6px; }

                    .fui-plan-table-item.is-title,
                    .fui-plan-table-item.is-title .fui-plan-table-label {
                        height: 125px;
                        line-height: 125px; } }

                .fui-plan-table-body {
                    width: calc(100% - 84px);
                    margin-left: 84px;
                    margin-right: 0;
                    overflow-x: auto;

                    .fui-plan-table-segment {
                        min-width: 131px;

                        .fui-plan-table-title {
                            height: 125px;

                            h1 {
                                margin-bottom: 6px; }

                            .fui-button {
                                padding: 0 12px;
                                min-width: 0; } } } } } } } }
