section.promotion-card {
    // background-color: $white
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-position-y: 100%;

    &.card-multi-step {
        .fui-card {
            min-height: 202px;
            background-size: 154px;
            background-repeat: no-repeat;
            background-position: right bottom; } }

    .horizontal-cards .slick-list .slick-slide {
        width: 100%; }

    .fui-card {
        text-align: center;
        height: 100%;
        position: relative;

        p {
            line-height: 32px; }

        h3 {
            // height: 42px
            max-width: 414px;

            @include ellipsis(2); }

        .circle {
            position: absolute;
            top: -28px;
            background-color: $darkgreen;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 24px;
            font-weight: 500;
            line-height: 2.3; }

        .tag {
            position: absolute;
            left: 0;
            top: 0;

            // background-color: $coral100
            color: $white;
            font-size: 14px;
            padding: 4px 12px;

            &::after {
                border-top: 28px solid $coral100;
                content: "";
                width: 0;
                height: 0;
                border-right: 28px solid transparent;
                position: absolute;
                right: -28px;
                bottom: 0; } }

        .tag-red {
            background-color: $coral100;

            &::after {
                border-top: 28px solid $coral100;
                top: 0px; } }

        .price {
            font-size: 42px; }

        .fui-button {
            width: 190px;
            margin: 0 auto; }

        ul {
            list-style: none;
            padding-left: 0;
            margin: 0 0 30px;

            li {
                display: flex;
                align-items: baseline !important;
                margin: 0 0 10px;
                font-weight: 500;
                text-align: left;

                .subtitle {
                    font-size: 20px;
                    font-weight: 500; }

                h6 {
                    font-weight: 400;
                    margin: 0; } }

            i {
                margin-right: 10px; } } } }

@media screen and (max-width: 960px) {
    section.promotion-card {
        &.card-multi-step {
            .fui-card {
                min-height: 152px;
                background-size: 105px;

                p {
                    font-size: 16px; }

                h3 {
                    height: auto; } } }

        .fui-card {
            h3 {
                font-size: 24px;
                height: 72px;
                line-height: 36px; }

            h1 {
                .price {
                    font-size: 42px; } }

            ul {
                padding-bottom: 30px !important;

                li {
                    .subtitle {
                        font-size: 16px; } } }

            .circle {
                width: 48px;
                height: 48px; } } } }

@media screen and (max-width: 640px) {
    section.promotion-card {
        .promotion-card-title {
            h2 {
                max-width: 256px; }

            .slick-slide[style] {
                width: 290px !important;
                height: auto !important; } } } }
