.radio-group {
    display: flex;
    flex-wrap: wrap;

    .radio {
        position: relative;
        width: calc(50% - 12px);
        margin: 0 12px 12px 0;

        input {
            position: absolute;
            opacity: 0;
            left: 0; }

        input + .content {
            font-weight: $font-regular;
            color: $darkgray50;
            font-size: 1rem;
            border: solid 2px transparentize($business50, 0.8);
            transition: all 0.3s ease;
            background-color: #fff;

            .text {
                font-weight: 500; }

            .icon img {
                margin: 0px auto 10px;
                height: 40px;

                &.default {
                    display: block; }

                &.selected {
                    display: none; } }

            &::before,
            &::after {
                box-sizing: border-box; }

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 10px;
                height: 10px;
                top: $space * 2;
                left: $space * 2;
                border-radius: $space;
                background: $white;
                opacity: 0;
                transform: scale(0);
                transform-origin: 50%;
                transition: all 0.3s ease; }

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 30px;
                height: 30px;
                top: 6px;
                left: 6px;
                border-radius: $space * 2;
                background: transparent;
                opacity: 1;
                border: 2px solid $lightgray100;
                transition: all 0.3s ease; } }

        input:checked + .content {
            color: $error;
            border-color: $error;

            .icon img {
                &.default {
                    display: none; }

                &.selected {
                    display: block; } }

            &::before {
                background: $error;
                border-color: $error;
                opacity: 1; }

            &::after {
                opacity: 1;
                transform: scale(1); } }

        &.is-icon-box {
            text-align: center;
            display: flex;

            .content {
                text-align: left;
                flex: 1;
                height: 76px;
                display: flex;
                align-content: center;
                align-items: center;
                padding: 10px 4px 10px 42px;

                @media screen and (max-width: 374px) {
                    justify-content: center;
                    padding: 10px 12px !important; } }

            .content::after,
            .content::before {
                top: 0;
                bottom: 0;
                margin: auto; }

            @media screen and (min-width: 960px) {
                .content {
                    justify-content: center;
                    justify-items: center;
                    padding: 15px 4px; } } }

        &.with-description {
            width: 100%;

            .content {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                justify-items: center;
                height: 88px;
                padding-left: ($space * 5) !important;
                padding-right: ($space * 2) !important;

                .description {
                    display: none;
                    font-size: 0.875rem; } }

            input:checked + .content .description {
                display: block; }

            @media screen and (min-width: 960px) {
                width: calc(33.3333333% - 12px);

                .content {
                    align-items: center;
                    height: 98px;
                    padding-left: ($space * 3) !important;
                    padding-right: ($space * 3) !important;

                    .description {
                        text-align: center; } } } } } }

.fui-radio-cards {
    margin: 0 -6px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .radio-card {
        position: relative;
        margin: 0 6px 12px;
        width: calc(50% - 12px);
        display: flex;
        flex-grow: 1;

        input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0; }

        .content {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            color: $black50;
            border: 2px solid $lightgray100;
            padding: 54px 12px 30px;

            &::before {
                content: "";
                position: absolute;
                left: 12px;
                top: 12px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 2px solid rgba(47, 89, 196, 0.2);
                transition: border 0.3s ease; }

            h5 {
                font-size: 1rem;
                line-height: 1.3;
                flex: 1;
                flex-grow: 1;
                margin-top: $space * 3;
                margin-bottom: $space * 1.5; }

            .body {
                font-size: 14px; }

            .ic-plan {
                position: relative;
                width: 100px;
                height: 100px;
                text-align: center;
                color: $white;
                padding-top: 20px;
                font-size: 26px;
                background: url(#{$publicURL}/resources/cbu/e-service/images/ic-plan-unselected.png) no-repeat center;
                background-size: contain;
                margin: 0 auto;

                small {
                    font-size: 8px; } } }

        input:checked + .content {
            border-color: $error;

            .ic-plan {
                background: url(#{$publicURL}/resources/cbu/e-service/images/ic-plan-selected.png) no-repeat center;
                background-size: contain; }

            &::before {
                border-width: 10px;
                border-color: $error; } } }

    @media screen and (min-width: 960px) {
        .radio-card {
            width: calc(25% - 12px);

            .content {
                padding: 35px 24px 30px;

                .ic-plan {
                    width: 160px;
                    height: 160px;
                    font-size: 48px;

                    small {
                        font-size: 14px; } } } } } }

@media screen and (min-width: 960px) {
    .radio-group {
        .radio {
            width: calc(33.3333333% - 12px);

            &.is-icon-box .content {
                padding: $space * 3;

                &::before {
                    top: $space * 1.5;
                    left: $space * 1.5;
                    bottom: auto; }

                &::after {
                    top: 22px;
                    left: 22px;
                    bottom: auto; } } } } }

.radio-to-bottom {
    .form-group,
    .radio-buttons {
        margin-bottom: 0 !important; }

    .radio-buttons {
        margin-top: 40px; } }

.radio-buttons {
    display: block;
    margin-bottom: 0;

    .receipt-radio.radio-group {
        align-items: flex-start;
        flex-direction: column;
        flex: 1;
        flex-grow: 1;

        .fui-select {
            width: 100%; }

        > div {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;

            .radio-button {
                min-width: 164px;
                width: auto;
                white-space: nowrap;
                margin: 18px 12px 18px 0;

                + div {
                    flex: 1;
                    flex-grow: 1; } }

            .search-code {
                position: absolute;
                right: 0px;
                top: 20px; } } }

    .radio-group {
        display: block;

        p {
            display: inline-block;
            margin: 0; }

        .radio-button {
            margin-bottom: $space * 1.5;

            // width: calc( 100% - 12px )
 } }            // margin: 0 12px 18px 0

    @media screen and (min-width: 960px) {
        margin-bottom: 38px;
        display: flex;

        .radio-group {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: flex-start;

            .pickup-action {
                font-size: 0.875rem; } }

        .radio-group.receipt-radio {
            > div {
                position: relative;
                align-items: center;
                align-content: center;
                flex-direction: row;

                .search-code {
                    position: absolute;
                    right: -100px;
                    height: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 2; } }

            .radio-button {
                width: calc( 25% - 12px ); } } } }

.radio-button {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    margin-right: 72px;
    font-weight: $font-regular;

    @media screen and (max-width: 768px) {
        margin-right: 0px; }

    input {
        position: absolute;
        opacity: 0;
        left: 0;
        cursor: pointer; }

    &:hover input ~ .checkmark {
        background-color: $lightgray100; }

    input:checked ~ .checkmark {
        background-color: $error;
        border: 2px solid $error; }

    input:checked ~ .checkmark:after {
        display: block; }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 2px solid $gray70;

        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 8px;
            left: 8px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background: white; } } }

.radio-sex {
    .radio-buttons {
        margin-top: 55px; } }

.is-ebu,
.is-help-center {
    .radio-button {
        input:checked ~ .checkmark {
            background-color: $business50;
            border: 2px solid $business50; } }

    .radio-group .radio {
        input:checked + .content {
            color: $business50;
            border-color: $business50;

            &::before {
                background: $business50; } } } }

@media screen and (max-width: 768px) {
    .radio-sex {
        .radio-buttons {
            margin-top: 0;

            .radio-button {
                width: calc( 50% - 12px ); } } } }
