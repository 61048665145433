.fui-app-trial-modal {
    .fui-app-trial-body {
        max-width: 800px;
        background: $white;
        text-align: center;
        margin: auto 10px;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12) !important;

        img {
            width: 100%; }

        .fui-buttons {
            margin: 24px 0 30px;

            .fui-button {
                margin: 0 6px; } } }

    .gamer_center {
        position: relative;
        display: flex;
        margin: 50px auto;
        width: calc(100% - 60px);
        max-width: 1024px;
        padding: 0 30px;
        text-align: center;
        background: $white;
        flex-direction: column;

        .content {
            width: 100%;
            height: auto;
            flex: 1 1 auto;
            flex-grow: 1; } }

    @media screen and (max-width: 580px) {
        .gamer_center {
            width: calc(100% - 30px); } }

    @media screen and (min-width: 960px) {
        .fui-app-trial-body {
            margin: auto; } } }

.fui-exclusive-modal {
    .modal-content {
        width: 100%;
        padding: 0 15px;
        max-width: 800px;
        height: 500px;
        text-align: center;
        margin: 50px auto;
        background: $white;

        .modal-action {
            padding: 20px 0; }

        .modal-body > * {
            width: 100%;
            height: auto; } } }

.fui-plan-table {
    margin: ($space * 1.5) -15px 0;

    .fui-plan-table-item {
        height: 52px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        font-size: 0.875rem;
        font-weight: $font-medium;

        &.title {
            display: none; }

        &.more-height,
        &.more-height .fui-plan-table-label {
            height: 62px !important; } }

    .fui-plan-table-body {
        margin-left: 96px;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12) !important;

        .slick-slider {
            .slick-arrow {
                display: none !important;
                position: absolute;

                &.slick-disabled {
                    display: none; } }

            .fui-plan-table-segment {
                text-align: center;
                min-width: 180px;
                border-top: solid 3px $white;
                border-bottom: solid 3px $white;
                box-shadow: inset -1px 0 0 0 $gray30;

                &.is-select {
                    border: solid 3px $coral70; }

                .fui-plan-table-item {
                    background: $white;
                    color: $darkgray50;
                    text-align: center;
                    box-shadow: inset 0 1px 0 0 $gray30, inset -1px 0 0 0 $gray30;

                    &.is-hover {
                        background: $ice; }

                    > div {
                        display: flex;
                        height: 100%;
                        flex: 1;
                        text-align: center;
                        align-content: center;
                        align-items: center;
                        justify-content: center;

                        + div {
                            box-shadow: (-1px) 0 0 0 $gray30; } }

                    &:last-child {
                        box-shadow: inset 0 1px 0 0 $gray30, inset -1px 0 0 0 $gray30;
                        height: 49px; } }

                .fui-plan-table-title + .fui-plan-table-item {
                    color: $black50; }

                .fui-plan-table-title {
                    background: $white;
                    height: 120px;
                    padding: ($space * 1.5) ($space * 2.5);
                    box-shadow: inset -1px 0 0 0 $gray30;

                    h1 {
                        margin: 0;
                        font-size: 42px;
                        line-height: 63px;

                        small {
                            font-size: 0.875rem; }

                        .price {
                            margin-right: 6px;

                            small {
                                font-size: 20px;
                                vertical-align: super; } } }

                    .fui-button {
                        margin: 0;
                        height: 36px;

                        &.is-primary .text {
                            height: 36px;
                            line-height: 36px; }

                        .text {
                            height: 32px;
                            line-height: 32px; } } } } } }

    .fui-plan-table-header {
        position: absolute;
        padding-top: 123px;

        .fui-plan-table-item {
            background: $white;
            justify-content: flex-start;

            &:hover {
                background: $ice; } }

        .fui-plan-table-item {
            .fui-plan-table-label {
                display: flex;
                width: 96px;
                height: 52px;
                line-height: 52px;
                font-size: 0.875rem;
                padding: 0 12px;
                color: $white;
                background: $darkgreen;
                align-content: center;
                box-shadow: inset 0 -1px 0 0 #95a3a4; }

            &.more-height .fui-plan-table-label {
                line-height: 72px; } } }

    @media screen and (min-width: 960px) {
        margin: 12px 0 0;

        .fui-plan-table-item {
            font-size: 1rem;
            height: 62px;

            &.more-height,
            &.more-height .fui-plan-table-label {
                height: 72px !important; } }

        .fui-plan-table-header {
            padding-top: 154px;

            .fui-plan-table-item {
                &:first-child,
                &:first-child .fui-plan-table-label {
                    height: 52px;
                    line-height: 52px; }

                .fui-plan-table-label {
                    font-size: 1rem;
                    height: 62px;
                    line-height: 62px;
                    width: 144px; } } }

        .fui-plan-table-body {
            margin-left: 144px;

            .slick-slider {
                .slick-arrow {
                    display: block !important;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    background: $white;
                    border-radius: 20px;
                    overflow: hidden;
                    border: 0;
                    z-index: 1;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                    color: transparent;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    opacity: 1;
                    transition: opacity 0.3s ease;

                    &.slick-disabled {
                        opacity: 0; }

                    &::after {
                        position: absolute;
                        font-family: "icomoon" !important;
                        speak: none;
                        color: $black50;
                        width: 28px;
                        height: 28px;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1; }

                    &.slick-prev {
                        left: -20px;

                        &::after {
                            content: ""; } }

                    &.slick-next {
                        right: -20px;

                        &::after {
                            content: ""; } } }

                .fui-plan-table-segment {
                    .fui-plan-table-title {
                        height: 151px;
                        padding: ($space * 1.5) ($space * 1.5) ($space * 3);

                        h1 {
                            margin-bottom: $space * 1.5; } }

                    .fui-plan-table-title + .fui-plan-table-item {
                        height: 52px; }

                    .fui-plan-table-item:last-child {
                        height: 59px; } } } } } }

.fui-nav-tab.is-box-tab {
    .fui-tab {
        box-shadow: 0 18px 24px 0 rgba(75, 88, 105, 0.12); }

    &.with-scrollbar .fui-tab {
        white-space: nowrap;
        overflow-x: auto;
        display: block;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        .fui-tab-item {
            display: inline-flex;
            width: 112px;

            &.is-active {
                box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18); } } }

    @media screen and (min-width: 960px) {
        &.with-scrollbar .fui-tab {
            display: flex;

            .fui-tab-item {
                width: auto;
                flex: 1; } } } }

section.fui-app-trial {
    background-color: #eff1f2;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: $darkgreen;
    padding-bottom: 124px;

    h2 {
        margin-bottom: 6px; }

    .description {
        font-size: 1rem;
        margin-bottom: 18px; }

    .content {
        > div {
            display: block;
            padding: 3px 0;

            * {
                display: inline-block;
                vertical-align: middle; }

            > img {
                width: 36px;
                margin-right: $space * 1.5; } } }

    .fui-action {
        margin: 30px -6px 0;
        display: flex;

        .fui-button {
            width: 50%;
            margin: 0 6px 0; } }

    @media screen and (min-width: 960px) {
        background-position: center;
        background-size: cover;
        padding-bottom: 60px;

        h2 {
            margin-bottom: $space * 1.5; }

        .description {
            margin-bottom: $space * 4.5; }

        .content {
            > div {
                padding: 6px 0; } }

        .fui-action {
            .fui-button {
                width: auto; } } } }

.slick-slider.horizontal-cards.is-rate-plan {
    margin-bottom: 0;

    .fui-card {
        position: relative;

        .fui-card-extra {
            padding-left: 0;
            padding-right: 0; }

        &.is-promotion {
            background-image: url(#{$publicURL}/resources/cbu/exclusive/card-promotion-pattern-02@2x.png);
            background-size: cover;

            .fui-card-action {
                background: none;
                display: flex;
                height: 100%; }

            .fui-card-content {
                background: none;
                color: $white;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .fui-item {
                    padding: 4px 0;

                    &.is-text-white .prefix i {
                        color: $white; } }

                h4 {
                    margin-bottom: 0; }

                .fui-card-title {
                    font-size: 22px; }

                .fui-card-extra {
                    margin-top: $space * 3; } } }

        .fui-card-action {
            height: 100%; }

        .fui-card-caption {
            height: 100%;
            padding: ($space * 6) 20px ($space * 4.5);

            img {
                height: 90px;
                display: block;
                margin: 0 auto; }

            .fui-card-title {
                margin-top: 18px;
                text-align: left;
                display: inline-flex; }

            .fui-list {
                display: inline-flex;
                flex-direction: column;

                .item-with-tooltip {
                    width: auto;
                    display: inline-flex;
                    align-items: center;
                    align-content: center;

                    .fui-tooltip-container {
                        margin-left: 6px; } }

                .fui-item {
                    width: auto;
                    padding: 2px 0;

                    .prefix i {
                        color: $main; }

                    .content {
                        font-size: 1rem;
                        text-align: left;
                        font-weight: $font-medium;
                        color: $darkgray50; } } }

            .fui-card-extra {
                padding-bottom: 0;
                margin-top: $space * 3;
                display: flex;

                .fui-button {
                    flex: 1;
                    flex-grow: 1;
                    min-width: 0;
                    margin: 0 6px; } }

            .fui-card-content {
                padding: 0; }

            .fui-card-ribbon {
                position: absolute;
                left: 0;
                top: 0;
                color: $white;
                background: $coral100;
                font-size: 0.875rem;
                height: 28px;
                line-height: 28px;
                padding: 0 12px;
                z-index: 9;

                &::after {
                    content: "";
                    float: right;
                    display: block;
                    margin-right: -40px;
                    width: 0px;
                    height: 0px;
                    border-left: 14px solid $coral100;
                    border-top: 14px solid $coral100;
                    border-right: 14px solid transparent;
                    border-bottom: 14px solid transparent; } } } }

    @media screen and (min-width: 960px) {
        .fui-card {
            &.is-promotion {
                .fui-card-content {
                    .fui-card-title {
                        font-size: 24px; } } }

            .fui-card-caption {
                img {
                    height: 132px; }

                .fui-list {
                    .fui-item {
                        padding: 4px 0;

                        .content {
                            font-size: 1.125rem; } } }

                .fui-card-extra {
                    margin-top: $space * 4; } } } } }
