@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0px); }

    100% {
        opacity: 1;
        transform: translateY(10px); } }

.fui-tooltip-container {
    display: inline;

    .fui-button.is-text {
        margin: 0;
        padding: 0;
        vertical-align: middle;

        i {
            font-size: 1.5rem;
            color: $navy50; }

        &:hover i {
            margin-left: 6px !important; } }

    > .fui-tooltip {
        top: auto;
        width: 90%;
        max-width: 388px;
        right: 0;
        margin: 0 auto;
        color: #5f6e84;
        font-size: 1rem;
        font-weight: $font-regular;
        padding: 18px 24px;
        line-height: 1.5;

        ul > li + li,
        ol > li + li {
            margin-top: $space; } }

    &.is-show > .fui-tooltip {
        display: block; }

    @media screen and (min-width: 768px) {
        position: relative;

        > .fui-tooltip {
            width: 388px;
            right: -1000%;
            left: calc(-1000% + 5px);
            margin: 8px auto 0;

            &::before {
                left: 0;
                right: 0;
                margin: auto; } } } }

.fui-nav-anchor,
.fui-nav-tab {
    ~ .fui-tooltip {
        transform: translateY(-45px); }

    @media screen and (max-width: 960px) {
        ~ .fui-tooltip.is-nest {
            width: calc( 100% - 30px );
            max-width: 260px;

            // margin-left: -109px
            margin-left: 0; } }

    &.is-fixed ~ .fui-tooltip {
        transform: translateY(-90px); }

    @media screen and (min-width: 960px) {
        //~ .fui-tooltip
        //    transform: translateY(0px)

        &.is-fixed ~ .fui-tooltip {
            transform: translateY(-45px); } } }

.fui-tooltip {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    width: 100%;
    background: $white;
    border: solid 1px $gray70;
    z-index: 100;

    &.is-nest {
        width: auto;
        max-width: 240px;

        // margin-left: -109px

        // margin: 0 30px 0 0!important

        &::before {
            left: 0;
            right: 0;
            margin: auto; } }

    .fui-tooltip-body {
        position: static;
        display: block;
        color: $darkgray50;
        padding: $space * 3;
        max-height: 360px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        line-height: 1.75;

        &::-webkit-scrollbar {
            width: 6px;
            opacity: 1;
            display: block; }

        &::-webkit-scrollbar-thumb {
            background: $gray50;
            border-radius: 10px; } }

    ol, ul {
        padding-left: 15px; }

    @media screen and (min-width: 960px) {
        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 12px;
            height: 12px;
            right: 20px;
            top: -7px;
            background: $white;
            border-top: 1px solid $gray70;
            border-left: 1px solid $gray70;
            transform: rotate(45deg); } }

    @media screen and (min-width: 960px) {
        &.is-nest {
            max-width: 388px;

 } } }            // margin-left: -172.5px
