.fui-item,
a.fui-item,
a.fui-item:link,
a.fui-item:visited {
    position: relative;
    display: flex;
    padding: $space 0;
    font-size: 1rem;
    align-content: center;
    align-items: center;
    color: $black50;
    background: none;
    border: 0;
    margin: 0;
    width: 100%;

    &.is-fcoin {
        > * {
            vertical-align: middle; }

        > img {
            margin-right: $space / 2; }

        a.is-text-underline {
            text-decoration: underline !important; } }

    .prefix {
        min-width: 24px;
        height: 24px;
        margin-right: $space * 0.75;
        color: $business50;
        align-self: flex-start;
        display: flex;
        align-content: center;
        align-items: center;

        * {
            vertical-align: middle; }

        .icon-check {
            margin-left: $space * 0.5; }

        .bulleted-dot {
            display: inline-block;
            margin-left: $space;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: $business50; }

        .number {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 12px;
            font-style: normal;
            font-weight: $font-medium;
            text-align: center;
            background: transparentize($business30, 0.8); } }

    .content {
        flex: 1 1 auto;
        flex-grow: 1; }

    .extra {
        line-height: 0;

        > i[class*="icon"] {
            font-size: 24px; } }

    &.is-disabled,
    &.is-disabled * {
        color: $gray70; }

    &.is-underline {
        text-decoration: underline; }

    &.text-sm {
        font-size: 0.875rem; }

    &.paded {
        padding: ($space * 1.5) 30px; }

    &[role='button']:hover {
        opacity: 0.6; }

    &.is-active {
        color: $accent;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background: $accent; } } }

header.fui-header .fui-item {
    &[role='button']:hover {
        opacity: 1;
        color: $accent; } }

.is-ebu header.fui-header .fui-item {
    &[role='button']:hover {
        opacity: 1;
        color: $business50; } }

button.fui-item,
a.fui-item {
    color: $black50;
    cursor: pointer;

    &::after {
        display: none; } }

.fui-inline-items {
    margin-bottom: $space * 6;

    .fui-item {
        display: inline-flex;
        width: 48%; } }

main .fui-item,
.fui-item {
    &.with-image {
        align-content: flex-start;
        align-items: flex-start;

        // flex-direction: column

        .image {
            line-height: 0;
            width: 130px;

            img {
                line-height: 0;
                width: 100%;
                height: auto; } } }

    &.with-list {
        margin-bottom: $space * 2;

        &.is-accent > .content {
            padding-top: 0;
            margin-left: 12px;

            .fui-list .fui-item {
                padding: 3px 0;

                .prefix i {
                    color: $accent; }

                .content {
                    font-size: 18px;
                    font-weight: $font-regular;
                    color: $darkgray50; } } }

        > .content {
            width: 100%;

            .fui-list {
                width: 100%; } } }

    > .content {
        h4 {
            font-size: 22px;
            color: $black50;
            margin: 0 0 18px; }

        .fui-item {
            text-align: left;
            font-weight: $font-medium;
            align-content: flex-start;
            align-items: flex-start;
            padding: 3px 0;

            .content {
                font-size: 1rem; }

            .prefix {
                line-height: 1.2;
                vertical-align: middle; }

            .prefix i[class*='icon-'] {
                color: $business50; } } } }

.is-estore .fui-item.with-list,
.is-estore main .fui-item.with-list {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 374px) {
        flex-direction: column;

        > .image {
            width: 100%;
            text-align: center; } }

    > .image > img {
        width: 130px; }

    > .content {
        text-align: left;

        .fui-list {
            margin-left: 0; } } }

.fui-list.contact-with-dot {
    .fui-item {
        font-weight: $font-medium;

        &::before {
            content: "";
            float: left;
            display: block; }

        a {
            display: inline-block;
            margin-left: $space * 0.5;
            margin-right: $space * 0.5; } } }

.fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link,
.fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited,
.fui-panel a:not(.sub-link-title):not(.fui-button):link,
.fui-panel a:not(.sub-link-title):not(.fui-button):visited {
    color: $accent;

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: $accent !important;
        transition: width 0.3s ease; }

    &:hover::after {
        width: 100%; } }

.fui-panel .fui-list > a.fui-item:link,
.fui-panel .fui-list > a.fui-item:visited {
    color: $black50;

    &::after {
        display: none; } }

.is-help-center,
.is-ebu {
    .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link,
    .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited,
    .fui-panel a:not(.sub-link-title):not(.fui-button):link,
    .fui-panel a:not(.sub-link-title):not(.fui-button):visited {
        color: $business50 !important;

        &::after {
            background: $business50 !important; } } }

.is-help-center header.fui-header {
    a.fui-item,
    .fui-item[role="button"] {
        color: $black100;

        &:hover,
        &:focus {
            color: $accent; }

        &.is-active {
            color: $accent;

            &::before {
                background: $accent; } } } }

.is-ebu header.fui-header {
    a.fui-item,
    .fui-item[role="button"] {
        color: $black100;

        &:hover,
        &:hover * {
            color: $business50; }

        &.is-active,
        &.is-active * {
            color: $business50;

            &::before {
                background: $business50; } } } }

@media screen and (min-width: 960px) {
    .fui-inline-items {
        margin-bottom: 60px;

        .fui-item {
            width: 200px; } }

    .fui-item.with-list > .content {
        .fui-item {
            padding: $space 0; }

        .content {
            font-size: 1rem; }

        .fui-list {
            margin: 0;
            max-width: none; } } }

.paper {
    .fui-item.is-fcoin {
        > * {
            vertical-align: middle; }

        > img {
            margin-right: $space / 2; }

        a.is-text-underline,
        a:link.is-text-underline,
        a:visited.is-text-underline {
            text-decoration: underline !important; } } }

@media screen and (min-width: 1200px) {
    .fui-item.with-image {
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;

        .image {
            width: 160px;
            align-content: flex-start;

            img {
                width: 160px;
                margin: 0; } }

        > .content {
            text-align: left;
            margin-left: $space * 3; } } }
