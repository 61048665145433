@keyframes modalIn {
    0% {
        opacity: 0;
        z-index: 99; }

    100% {
        opacity: 1;
        z-index: 99; } }

@keyframes modalOut {
    0% {
        opacity: 1; }

    99% {
        opacity: 0; }

    100% {
        display: none;
        opacity: 0;
        z-index: -1; } }

html.modal-open,
html.cart-open {
    overflow: hidden;
    height: auto;

    body {
        height: auto; }

    main {
        z-index: initial; }

    header,
    .emma-service {
        z-index: 9; }

    .fui-breadcrumb-container,
    .fui-banner,
    .fui-nav-tab {
        z-index: 2 !important;

        ~ .fui-tab-pane.is-active {
            z-index: 2; } }
    .fui-cart-bar {
        transform: translateY(0px) !important; } }

.ReactModalPortal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 2999;
    display: none;

    .ReactModal__Overlay {
        background-color: transparentize($black50, 0.8) !important; }

    .ReactModal__Content {
        position: absolute;
        display: flex;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;

        &.address-input {
            .fui-prompt-modal-body {
                padding: 30px;

                .form-group.modal-radio {
                    margin-bottom: 14px;

                    input {
                        z-index: 11;
                        top: 0; } }

                h5 {
                    margin-bottom: 32px; }

                .radio-button {
                    margin-bottom: 30px;

                    .dropdown-wrapper {
                        margin-bottom: 24px;
                        flex-direction: row; }

                    p {
                        margin-bottom: 20px; }

                    .menu-wrapper {
                        span {
                            color: $black50; } } }

                button.fui-button {
                    margin-bottom: 0;
                    max-width: 190px; } } }

        .fui-prompt-modal-body {
            align-self: center;
            background: $white;
            width: 90%;
            max-width: 600px;
            left: 0;
            right: 0;
            margin: auto;
            padding: 30px 15px;
            text-align: center;
            box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18);

            .description {
                position: relative;
                width: 100%;
                justify-content: space-between;
                display: flex;
                flex-direction: row;
                height: 160px;
                margin-bottom: 24px;

                &::before {
                    content: "";
                    border-top: 2px solid $gray50;
                    position: absolute;
                    width: 100%;
                    top: 2px; }

                .mark-1, .mark-2, .mark-3 {
                    height: 100px;
                    border-left: 2px dashed $gray50;
                    position: relative;
                    margin-bottom: 16px;

                    &::before {
                        content: "•";
                        position: absolute;
                        top: -10px;
                        font-size: 58px;
                        color: #D6D6D6;
                        left: -10px;
                        font-family: "Arial"; } }

                .mark-2 {
                    width: 2px;
                    margin: 0 auto 16px;

                    &::before {
                        font-size: 124px;
                        color: $darkgray50;
                        left: -23px; } }

                .mark-3 {
                    border-right: 2px dashed $gray50;
                    border-left: 0;

                    &::before {
                        left: auto;
                        right: -10px; } } }

            .usage-graph {
                height: 48px;
                width: 50%;
                position: absolute;
                top: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-size: 18px;
                background-image: linear-gradient(to right, #98b2b7 18%, #5f7b80 90%); }

            .success {
                max-width: 144px; }

            h5 {
                margin: 0 0 ($space * 1.5); }

            .fui-buttons.two-button {
                display: flex;

                .fui-button {
                    flex: 1;
                    flex-grow: 1;

                    &:last-child {
                        margin: 0; } } } }

        &.is-large {
            .fui-prompt-modal-body {
                text-align: left;
                max-width: 1080px;
                padding: 30px; } } }

    @media screen and (min-width: 600px) {
        .ReactModal__Content {
            .fui-prompt-modal-body {
                padding: 30px 60px; } } }

    @media screen and (max-width: 960px) {
        .ReactModal__Content {
            &.is-large {
                .fui-prompt-modal-body {
                    text-align: center;
                    padding: 30px 15px;

                    .description {
                        height: 114px; }

                    .mark-1, .mark-2, .mark-3 {
                        height: 62px; }

                    p.body {
                        text-align: left;
                        font-size: 18px; }

                    .usage-graph {
                        height: 30px;
                        font-size: 12px;
                        top: 30px; }

                    .body {
                        font-size: 16px; } } } } } }

.modal-open .ReactModalPortal,
.ReactModal__Body--open .ReactModalPortal {
    display: block; }

.ReactModalPortal {
    .fui-query-modal {
        > div {
            position: relative;
            margin: 0 auto;
            width: 100%; }

        .close {
            position: absolute;
            display: block;
            width: 30px;
            height: 30px;
            right: 10px;
            top: 60px;
            z-index: 29;

            i {
                font-size: 30px; } }

        div.query-table-content {
            width: 100%;
            max-width: 980px;
            margin: 0 auto;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch; }

        div.query-table-content > table {
            border: 2px solid $black50;
            color: $black50;
            margin: 50px auto;
            background: $white;

            > tbody > tr > td {
                padding: 5px !important; } }

        td[style="padding-top:5px;padding-bottom:0px"] > table[width="970"] {
            > tbody > tr > td {
                border: 1px solid $black50; } }

        table[width="120"] {
            width: 120px !important; }

        table[width="300"] {
            width: 300px !important; }

        table {
            border-collapse: separate;
            border-width: 2px;

            > table {
                margin: 0;

                tr > td {
                    padding: 5px;
                    border: 1px solid $black50; } }

            *[bgcolor="#E0E0E0"] {
                background: #E0E0E0; }

            tr:nth-child(2n) {
                background: none; }

            tr th {
                background: #E0E0E0;
                font-weight: bold; }

            tr th,
            tr td {
                padding: 0 !important;
                border: 0;
                text-align: left;
                color: $black50;
                height: auto; }

            tr,
            tr td {} } }

    .fui-discount-modal {
        .fui-modal-content {
            width: 90%;
            max-width: 480px; }
        .fui-modal-body {
            margin-top: 0;
            padding-left: $space*5;
            padding-right: $space*5;
            text-align: center;
            > p {
                margin-top: 0; } }
        .fui-modal-head {
            position: relative;
            h5 {
                color: $main;
                margin-bottom: 0; } }
        .discount-divider {
            position: relative;
            padding: 0;
            background: none;
            line-height: 0;
            > img {
                width: 100%;
                line-height: 0; }
            &::before {
                content: '';
                position: absolute;
                width: calc(100% - 60px);
                height: 0;
                left: 0;
                right: 0;
                margin: auto;
                top: 0;
                bottom: 0;
                display: block;
                border-bottom: 4px dotted #979797; } } } }

.fui-alert-modal {
    .fui-modal-content {
        background: $white; }

    .fui-modal-body {
        text-align: center; } }

.fui-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #{"rgba(32, 32, 32, 0.3)"};
    display: none;
    z-index: -1;

    .check-modal {
        // width: 392px
        // height: 282px
        width: 600px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        // display: flex
        background-color: $white;
        padding: 30px;

        .checkbox {
            margin: 0; }

        .clean {
            cursor: pointer; }

        h6 {
            margin: 12px 0 0; } }

    .error-modal {
        width: 392px;
        height: 282px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #fff;
        padding: 60px 88px; }

    .fui-close {
        position: absolute;
        right: 20px;
        top: 20px;
        border: 0;
        color: $white;
        width: 30px;
        height: 30px;
        background: none;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
            font-size: 20px; } }

    .fui-modal-content {
        position: absolute;
        width: 100%;
        max-width: 80%;

        // max-width: 95%
        min-height: 600px;
        max-height: 90%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transform-origin: 50%;
        transform: scale(0.8);
        transition: all 0.3s ease; }

    .fui-player {
        iframe {
            width: 100%; } }

    &.is-open {
        opacity: 0;
        display: block;
        z-index: 9999;
        position: fixed;
        animation: modalIn 0.5s ease forwards;

        &:not(.is-closing) .fui-modal-content {
            transition-delay: 0.5s; }

        .fui-modal-content {
            opacity: 1;
            transform: scale(1);
            display: flex;
            justify-content: center;
            align-items: center; }

        .fui-modal-body {
            width: 90vw;
            position: relative;
            display: flex;
            justify-content: center;

            .fui-player {
                width: 100%; } } }

    &.is-closing {
        opacity: 1;
        display: block;
        z-index: 9999;
        animation: modalOut 0.5s 0.2s ease forwards;

        .fui-modal-content {
            opacity: 0;
            transform: scale(0.8); } }

    .tableBtn-modal {
        // width: 600px
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        // display: flex
        text-align: center;
        background-color: #fff;
        padding: 30px; } }

@media screen and (max-width: 960px) {
    .fui-modal {
        .error-modal {
            padding: 60px 0; }

        .check-modal {
            padding: 30px 15px;

            .list-container {
                max-height: 412px;
                overflow: scroll; }

            .fui-button {
                margin-bottom: 0; }

            h6 {
                margin: 16px 0 0;
                font-size: 16px; }

            label {
                .content {
                    font-size: 16px; } }

            a.fui-button {
                width: 100%; } } }

    .ReactModalPortal {
        .ReactModal__Content {
            &.address-input {
                .fui-prompt-modal-body {
                    padding: 30px 15px;

                    .form-group.modal-radio {
                        margin-bottom: 0; }

                    .radio-button {
                        padding-left: 0;

                        .dropdown-wrapper {
                            flex-direction: column;
                            margin-bottom: 0;

                            .fui-dropdown {
                                margin-bottom: 24px; } }

                        p {
                            padding-left: 35px; } }

                    button.fui-button {
                        max-width: 100%;
                        min-width: auto; } } } } } }

@media screen and (max-width: 480px) {
    .fui-modal.is-open {
        .fui-modal-body {
            // width: 80vw
            width: calc(100vw - 20px);
            max-width: 100%;
            height: 80vh;
            align-items: center;
            padding: 0 15px; } }

    .fui-modal {
        .fui-modal-content {
            max-width: 100%; }

        .tableBtn-modal {
            padding: 30px 15px;

            button.fui-button {
                width: 100%; } } } }
