.fui-plan-flow-container .fui-plan-flow .fui-flow-body {
    .fui-validation-input {
        .form-group.is-invalid {
            input,
            input:focus {
                background: rgba(240, 92, 56, 0.1); } }

        .fui-cards {
            margin: 36px -6px 48px;
            padding: 0; }

        .fui-cards .fui-card {
            .fui-card-action {
                min-height: 0; }

            .circle-number {
                text-align: center;
                width: 60px;
                height: 60px;
                line-height: 60px;
                font-size: 28px;
                font-weight: $font-medium;
                color: $white;
                background: $darkgray50;
                border-radius: 30px;
                margin-top: -54px;
                margin-bottom: 18px; }

            h3.fui-card-title {
                font-size: 28px !important; }

            .fui-card-description {
                color: $darkgray50;
                margin-bottom: $space * 3; } }

        .fui-cards.is-simple-step {
            margin-top: $space * 10;

            .fui-card {
                box-shadow: none !important;
                margin-left: 10px;
                margin-right: 10px; }

            &.four-card > .fui-card {
                width: calc(25% - 20px); }

            &.three-card > .fui-card {
                width: calc(33.333333% - 20px); }

            .fui-card .fui-card-action {
                background: none;

                .fui-card-content {
                    padding: 0;

                    .circle-number {
                        margin-bottom: $space * 1.5; }

                    h3.fui-card-title {
                        font-size: $space * 3.5;
                        line-height: 1.35;
                        height: 74px; }

                    p.fui-card-description {
                        display: block;
                        font-size: 1.125rem;
                        height: auto;
                        margin-top: $space * 1.5;

                        > br {
                            display: block !important; } } } } }

        @media screen and (max-width: 1200px) {
            .fui-cards.is-simple-step .fui-card .fui-card-action .fui-card-content {
                h3.fui-card-title {
                    font-size: ($space * 3) !important; } } }

        @media screen and (max-width: 960px) {
            .fui-cards.is-simple-step {
                margin-top: $space * 3;
                margin-bottom: $space * 5;

                .fui-card {
                    position: relative;
                    width: 100% !important;
                    margin-bottom: $space * 2;

                    .fui-card-content {
                        padding: 0 0 0 60px !important;

                        .circle-number {
                            position: absolute;
                            left: 0;
                            top: 0;
                            margin-top: 0;
                            font-size: 24px;
                            width: 48px;
                            height: 48px;
                            line-height: 48px; }

                        h3.fui-card-title {
                            height: auto !important;
                            font-size: ($space * 2.5) !important;
                            margin: 0 0 ($space * 0.75); }

                        p.fui-card-description {
                            height: auto;
                            font-size: 1rem !important;
                            margin: 0; } } } } }

        @media screen and (max-width: 768px) {
            .fui-button {
                width: 100%; }

            .fui-cards {
                margin-top: 18px;
                margin-bottom: 0; }

            .fui-cards .fui-card {
                margin-bottom: $space * 4.5;

                .circle-number {
                    font-size: 24px;
                    width: 48px;
                    height: 48px;
                    line-height: 48px; } } }

        .form-group {
            margin-bottom: 0;

            label {
                margin-bottom: 18px;
                height: auto; }

            + .text-sm {
                margin-top: 10px;
                margin-bottom: $space * 6;

                a:link,
                a:visited {
                    color: #1a73e8; } } }

        &.paper .form-group {
            margin-bottom: 30px;

            label {
                margin-bottom: 18px;
                height: auto; } } } }

.tag-filter {
    display: flex;
    margin-bottom: $space * 2;

    .tag-group {
        display: inline-block;
        margin-right: $space * 1.5;

        label.fui-button.is-label {
            border-radius: 0;
            margin: 0;
            z-index: 0;

            + label.fui-button.is-label {
                margin-left: -2px; }

            &:hover {
                z-index: 9;
                border-color: $black50; }

            &.is-active {
                z-index: 9;
                background: $black50 !important;
                border-color: $black50 !important;
                color: $white !important; }

            &:first-child {
                border-left-width: 2px;
                border-radius: 20px 0 0 20px; }

            &:last-child {
                border-radius: 0 20px 20px 0; }

            &:first-child:last-child {
                border-radius: 20px; } } }

    .fui-dropdown.is-dropdown-tag {
        .fui-menu {
            width: 300px;

            .menu-wrapper {
                padding: 0 15px;
                height: 300px;
                overflow-y: auto; }

            @media screen and (max-width: 768px) {
                right: 0; } } }

    .fui-button.is-label {
        min-width: 0;

        .text {
            font-size: 14px;
            line-height: 26px; }

        &.is-active {
            border-color: $black50; } } }

.fui-radio-card-section {
    .fui-compare {
        position: absolute;
        right: 15px;
        top: 12px;

        .fui-button {
            margin: 0; }

        @media screen and (max-width: 768px) {
            top: 10px;

            .fui-button {
                height: 24px;
                padding: 0 12px;

                .text {
                    line-height: 24px;
                    font-size: 12px; } } }

        @media screen and (max-width: 640px) {
            top: 10px; }

        .content {
            flex: 1 1 auto; }

        .fui-dropdown.is-dropdown-tag {
            &.is-show .fui-dropdown-item {
                i {
                    display: inline-block;
                    transform: rotate(180deg); } }

            .fui-menu {
                right: 0;
                left: auto;
                min-width: 0;
                width: 300px;

                .menu-wrapper {
                    padding: 30px 15px;
                    height: 300px;
                    overflow: visible;

                    p {
                        margin-top: 0;
                        margin-bottom: $space * 2; } } } } }

    .slick-slider {
        margin: 0 -6px;

        .slick-arrow {
            display: block !important;
            position: absolute;
            width: 40px;
            height: 40px;
            background: $white;
            border-radius: 20px;
            overflow: hidden;
            border: 0;
            z-index: 1;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            color: transparent;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 1;
            transition: opacity 0.3s ease;

            &.slick-disabled {
                opacity: 0; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 28px;
                height: 28px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 28px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1; }

            &.slick-prev {
                left: -10px;

                &::after {
                    content: ""; } }

            &.slick-next {
                right: -10px;

                &::after {
                    content: ""; } } } }

    .slick-slider .slick-track {
        display: flex;
        flex-wrap: nowrap; }

    .slick-slider .slick-list {
        overflow: visible;

        .no-result {
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
            color: $gray100;
            min-height: 428px;

            img {
                margin: 0 auto ($space * 1.5);
                line-height: 0; } }

        .slick-track {
            margin-left: 0; }

        .slick-track .slick-slide {
            display: flex;
            flex: 1 1 auto;
            padding: 0 6px;
            height: auto !important;

            > div {
                display: flex;
                width: 100%;
                flex: 1 1 auto;

                .fui-card {
                    display: flex !important;
                    flex-direction: column;
                    width: calc(100% - 6px);
                    flex: 1 1 auto;

                    label.fui-card-action {
                        flex: 1 1 auto; } } } } }

    .slick-slider.no-result {
        .slick-list .slick-track {
            margin: 0 auto;

            .slick-slide {
                max-width: none; } } }

    .fui-card.is-radio-card {
        > label {
            position: absolute; }

        // &::before,
        // &::after
        //     content: ""
        //     position: absolute
        //     display: block
        //     border-radius: 50%
        //     z-index: 3
        //     box-sizing: border-box
        //     pointer-events: none

        // &::before
        //     width: 30px
        //     height: 30px
        //     left: 12px
        //     top: 40px
        //     border: solid 2px rgba(47, 89, 196, 0.2)

        &.is-selected {
            border: solid 2px $error;

            .fui-card-extra {
                .fui-button {
                    border-color: $error;

                    .text {
                        color: $error; } } }

            // &::before
            //     border: 0
            //     background: $error

            // &::after
            //     background: $white
            //     top: 50px
            //     left: 22px
            //     width: 10px
            //     height: 10px
 }            //     z-index: 4

        .fui-card-action {
            // cursor: pointer

            input {
                position: absolute;
                opacity: 0; } } } }

.fui-card.is-radio-card {
    position: relative;
    display: flex;
    border: solid 2px rgba(47, 89, 196, 0.2);
    background: $white;

    .fui-card-action {
        cursor: default;
        position: relative;
        padding: ($space * 4) 30px 0;
        flex: 1 1 auto;
        flex-grow: 1 1 auto;

        > .fui-card-content {
            padding: 0;
            flex: 1 1 auto;

            + .fui-card-extra {
                padding: 0; } } }

    .fui-card-sticker {
        position: absolute;
        right: 12px;
        top: 0px;
        height: 64px; }

    .fui-card-plan {
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 132px;
        color: $black50;
        border-bottom: 2px solid transparentize($business50, 0.8);
        padding-bottom: $space * 2.5;
        justify-content: center;

        .limited {
            position: relative;
            display: inline-block;
            font-size: 0.875rem;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 0;
                left: 0;
                bottom: 0;
                border-bottom: 8px solid rgba(240, 92, 56, 0.4);
                z-index: 0; } }

        .plan-container {
            margin: 0 auto;

            small.unit {
                font-size: 1.5rem;
                margin-right: 6px;
                font-weight: $font-regular;
                color: $grayblue;
                text-align: center;
                opacity: 1;
                line-height: 1;
                margin-top: $space * 0.25;

                del {
                    display: block;
                    line-height: 1;
                    font-size: 0.875rem;
                    margin-top: $space * 0.25; } }

            .plan-content {
                display: flex;

                // margin-top: $space
                align-content: flex-end;
                align-items: center;
                font-weight: $font-bold;

                .divider {
                    margin: 0 ($space / 2); }

                .divider,
                .month {
                    line-height: 48px;
                    color: $grayblue;
                    font-weight: $font-bold; } } }

        h5 {
            line-height: 1;
            margin: 0; }

        h1 {
            font-size: 42px;
            margin: 0;
            line-height: 1; }

        .month {
            font-weight: $font-regular; }

        .note {
            color: $darkgray50;
            margin-top: $space * 1.5;
            font-weight: $font-medium; } }

    .fui-plan-list {
        margin-top: $space * 2.5;
        font-size: 1rem;
        line-height: 28px;
        min-height: 112px;

        .fui-plan-item {
            display: flex;
            color: $darkgray50;
            margin-bottom: $space * 1.5;

            .item-title {
                white-space: nowrap;
                margin-right: $space * 4;
                width: $space * 11;
                line-height: 1.35; }

            .item-tooltip {
                line-height: 1;
                margin-left: $space / 2;

                .icon-info {
                    content: "i";
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    color: $gray100;
                    border: 2px solid $gray100;
                    border-radius: 50%;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 2px;
                        height: 2px;
                        top: 3px;
                        left: 0;
                        right: 0;
                        background: $gray100;
                        margin: auto; }

                    &::after {
                        height: 7px;
                        top: 7px; } } }

            .item-content {
                flex: 1;
                flex-grow: 1;
                font-weight: $font-medium;
                line-height: 1.35;

                del + span,
                big {
                    float: left;
                    font-weight: bold;
                    font-size: 1.5rem;
                    vertical-align: middle;
                    line-height: 1;
                    margin-top: -$space / 2;
                    margin-right: $space / 2; }

                del {
                    color: $gray70;
                    margin-right: 3px;
                    vertical-align: text-bottom; } } } }

    .fui-card-ribbon {
        position: absolute;
        left: 0;
        top: 0;
        color: $white;
        height: 26px;
        padding: 0 6px 0;
        line-height: 26px;
        font-size: 0.875rem;
        background-color: $coral100;
        z-index: 1;

        > * {
            position: relative;
            z-index: 1; }

        &::before {
            content: "";
            position: absolute;
            right: -26px;
            z-index: 0;
            border-left: 13px solid $coral100;
            border-top: 13px solid $coral100;
            border-bottom: 13px solid transparent;
            border-right: 13px solid transparent; }

        &.is-blue {
            background-color: $blue50;

            &::before {
                border-left: 13px solid $blue50;
                border-top: 13px solid $blue50; } } }

    .fui-card-extra {
        padding: ($space * 2) 30px 30px;
        text-align: center;
        font-weight: $font-medium;

        i {
            margin-left: $space * 1.5; } }

    &.is-more-option {
        > .fui-card-extra {
            display: flex;

            .fui-button {
                flex: 1;
                flex-grow: 1;
                margin-right: 0 !important;
                margin-bottom: 0 !important;
                min-width: 0 !important;

                + .fui-button {
                    margin-left: $space; } } }

        > .fui-card-action {
            * {
                color: $grayblue;
                font-weight: $font-medium; }

            > img {
                width: 239px;
                margin: 20px auto 30px; } } }

    // @media screen and (max-width: 1366px)
    //     .fui-card-plan
    //         h1
    //             font-size: 2rem

    // @media screen and (max-width: 1024px)
    // // .fui-card-plan
    // //     h1
    // //         font-size: 1.75rem
    //
    // //         .unit
    // //             font-size: 1.25rem
    //
    // //     .note
    // //         font-size: 0.875rem

    @media screen and (max-width: 959px) {
        .fui-card-plan {
            h1 {
                font-size: 1.75rem; }

            .plan-container small.unit {
                font-size: 1.25rem; }

            .note {
                font-size: 0.875rem; } }

        .fui-plan-list .fui-plan-item .item-content {
            del {
                font-size: 0.875rem; }

            del + span,
            big {
                font-size: 1.25rem;
                margin-top: 0; } } }

    @media screen and (max-width: 480px) {
        .fui-card-action {
            min-height: 0px;

            .fui-card-plan {
                h1 {
                    font-size: 2rem;

 }                    // margin-top: $space / 2

                .plan-container .plan-content {
                    > h1,
                    > .divider,
                    > .month {
                        line-height: 2.5rem; } }

                .note {
                    font-size: 0.875rem;
                    margin-top: $space; } }

            .fui-plan-list .fui-plan-item .item-title {
                margin-right: $space; } } } }

.is-compare .fui-radio-card-section {
    .fui-card.is-radio-card {
        &::before {
            border-radius: 0; }

        &::after {
            content: "";
            font-family: "icomoon" !important;
            width: 30px;
            height: 30px;
            line-height: 30px;
            top: 47px;
            left: 13px;
            text-align: center;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background: none;
            color: $white;
            opacity: 0; }

        &.is-selected::after {
            opacity: 1; } } }

.fui-plan-flow-container {
    padding-bottom: 120px;

    @media screen and (min-width: 960px) {
        padding-top: 60px; }

    .fui-plan-flow {
        padding: 30px 0;
        border-bottom: solid 2px $lightgray70;

        .fui-flow-body {
            display: none;

            .form-group {
                > label {
                    height: 0;
                    min-height: 0;
                    margin: 0; } } }

        .fui-flow-title {
            margin: 0 0 6px;
            display: flex;
            align-items: center;
            align-content: center;

            .extra {
                display: flex;
                align-content: center;

                .fui-button {
                    margin: 0; } }

            .content {
                flex: 1;
                display: flex;
                align-items: center;
                align-content: center;
                margin-right: $space * 1.5;

                .selected-value {
                    font-size: 28px;
                    line-height: 1;
                    color: $accent;
                    display: inline-block;
                    margin-left: $space * 1.5;
                    font-weight: $font-regular; } }

            h2 {
                margin: 0; } }

        &.is-active {
            border-bottom: 0;

            .fui-flow-body {
                display: block; }

            .fui-flow-title {
                border-bottom: 0;

                .content .selected-value {
                    display: none; }

                .extra {
                    display: none; } } }

        .fui-flow-description {
            font-weight: $font-regular;
            margin: 0 0 30px; } }

    @media screen and (max-width: 768px) {
        .tag-filter {
            .content {
                display: block;
                flex: none;
                width: calc(100% + 30px);
                margin: 0 -15px;
                padding-left: 15px;
                -webkit-overflow-scrolling: touch;

                .tag-list {
                    position: relative;

                    // width: calc(100% - 115px)
                    display: block;
                    white-space: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    -webkit-overflow-scrolling: touch; } }

            .extra {
                width: 80px;
                flex: 1 1 auto;

                .fui-dropdown .fui-dropdown-item {
                    width: 100%;
                    margin: 0;
                    white-space: nowrap; } } }

        .form-group .radio-group {
            .radio.is-icon-box {
                .content {
                    padding: 0;
                    justify-content: center;

                    &::after {
                        width: 8px;
                        height: 8px;
                        left: 14px;
                        top: 14px;
                        bottom: auto; }

                    &::before {
                        left: 6px;
                        top: 6px;
                        bottom: auto;
                        width: 24px;
                        height: 24px; } } } }

        .fui-plan-flow .fui-flow-title {
            .fui-button {
                height: 24px;
                padding: 0 12px;
                min-width: 0;

                .text {
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;
                    height: 24px;
                    padding: 0 12px; } }

            .content {
                .selected-value {
                    display: block;
                    font-size: 24px;
                    margin-left: 0; } } }

        .slick-slider .slick-track {
            .slick-slide {
                max-width: 302px; } }

        .fui-card.is-radio-card {
            .fui-card-action {
                .fui-card-plan {
                    background-size: auto 102px;

 }                    // min-height: 102px

                @media screen and (max-width: 374px) {
                    max-width: 280px; } }

            .slick-slide .fui-card.is-radio-card {
                .fui-card-action {
                    .fui-card-plan {
                        // min-height: 102px
                        background-size: auto 102px; }

                    h1 {
                        font-size: 32px; }

                    small.unit {
                        font-size: 18px !important; }

                    .note {
                        font-size: 14px; } }

                .fui-plan-item {
                    .item-title {
                        white-space: nowrap;
                        margin-right: $space * 1.5;
                        width: $space * 10; } } }

            .fui-card-action,
            .fui-card-extra {
                padding-left: 18px;
                padding-right: 16px; }

            .fui-card-extra {
                padding: ($space * 3) 18px;

                div[role='button'] .text {
                    font-size: 0.875rem; } } } } }
