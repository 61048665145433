.search-bar {
    position: relative;
    background: #eff1f2;
    z-index: 2;
    height: 60px;

    .fui-container {
        padding: 0;
        height: 60px; }

    form {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #eff1f2;
        padding: 10px 15px;

        &.with-shadow {
            box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24); } }

    .form-group {
        margin-bottom: $space * 1.5;

        .reset {
            right: 15px; }

        label {
            font-size: 18px;
            margin-bottom: $space * 1.5; } }

    .fui-dropdown {
        min-width: 120px;
        margin: 0 0 0;
        width: 100%;

        &.with-arrow {
            margin-left: 0; }

        button.fui-dropdown-item {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between; }

        &:not(.is-button) button.fui-dropdown-item {
            background: none;
            border: 0; } }

    .search-body {
        flex: 1 1 auto;
        display: flex;
        margin-top: $space * 3;
        flex-direction: column;
        width: 100%;
        left: 0;
        padding: 0 0 20px;
        top: 100%;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            .MuiGrid-root.MuiGrid-container > .MuiGrid-root.MuiGrid-item {
                padding-left: 0px;
                padding-right: 0px; } }

        > div {
            width: 100%;
            min-height: 50px; } }

    .fui-switch {
        vertical-align: middle;
        align-self: center;
        justify-self: center;
        margin-left: -10px; }

    .search-groups {
        display: flex;
        flex-direction: column-reverse; }

    .fui-buttons {
        margin: 0 -6px;

        .fui-button {
            min-width: 0px;
            width: calc(50% - 6px);
            margin: 0 6px;

            &:first-child {
                margin-left: 0; }

            &:last-child {
                margin-right: 0; } } }

    .search-head {
        width: 100%;
        height: 40px;
        display: flex;
        align-content: center;
        align-items: center;

        i {
            font-size: 24px; }

        [role="button"] {
            position: absolute;
            right: 0; }

        .form-group {
            margin-bottom: 0; } }

    .form-group.search-group > .form-group {
        margin-bottom: 0 !important; }

    label.fui-switch {
        display: inline-flex !important;

        // margin-left: 12px
        margin-bottom: 0;

        input + .content {
            display: flex;
            height: 25px;
            padding: 0 52px;

            &::before {
                display: block; }

            span.on,
            span.off {
                width: 52px; }

            span.off {
                text-align: right; } } }

    .search-group {
        position: relative;

        > .form-group {
            margin-bottom: 0; }

        &.theme-number .dropdown-wrapper {
            position: absolute;
            width: 100%;
            top: 100%;
            padding: 30px 15px;
            background: $white;
            height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);

            p {
                font-weight: $font-medium; }

            .fui-button.is-label {
                min-width: 0;
                margin-right: $space * 1.2;
                margin-bottom: $space * 1.2;

                .text {
                    font-size: 0.875rem; } } }

        .valid {
            display: none; }

        .reset {
            right: 50px; }

        > .fui-button {
            position: absolute;
            right: 0;
            top: 28px;
            bottom: 0;
            margin: auto 0 !important;
            padding: 0;
            width: 50px;
            height: 50px;
            display: block !important;
            text-align: center;

            i {
                width: 50px;
                height: 50px;
                transition: none;
                margin: 0 !important; }

            &:hover i {
                margin: 0 !important; } }

        @media screen and (min-width: 600px) {
            > .fui-button {
                top: 0px; } } }

    @media screen and (min-width: 961px) {
        height: 80px;

        .fui-select .fui-dropdown {
            .fui-dropdown-item {
                background: none;
                border: 0; } }

        .fui-container {
            height: 80px;
            padding: 0 15px; }

        form {
            position: relative;
            flex-direction: row;
            padding: 0;
            box-shadow: none !important;
            height: 80px;
            align-items: center; }

        .search-head {
            width: auto;
            height: 50px;

            .fui-switch {
                margin-right: 94px; } }

        .search-body {
            flex-direction: row;
            margin-top: 0px;
            padding: 0; }

        .search-groups {
            justify-content: space-between;
            align-content: center;
            align-items: center;
            flex-direction: row; }

        .form-group {
            max-width: 404px;
            margin: 0;

            .reset {
                right: 50px; } }

        .fui-dropdown {
            margin: 0;
            width: 100%;
            margin-left: 0;

            .fui-dropdown-item {
                height: 50px; } } }

    @media screen and (min-width: 1200px) {
        .form-group {
            *:not(.fui-switch) {
                input {
                    width: 404px; } } } } }

.theme-select .search-bar {
    height: 154px;
    padding-bottom: 0;

    @media screen and (min-width: 768px) {
        height: 80px; } }

.fui-tab-pane {
    .search-bar {
        padding: 0 15px;

        .fui-container {
            width: 100%; }

        .search-group {
            position: relative;
            display: flex;
            justify-content: flex-end; } } }

.fui-container .search-bar {
    + .fui-cards {
        margin: 12px -6px 0; }

    @media screen and (max-width: 768px) {
        padding: 0; } }

.fui-banner.is-find-product {
    min-height: 200px;
    height: 200px;
    display: flex;
    background-size: cover;
    background-position: center;
    color: $white;
    padding-top: 60px;
    align-items: center;
    align-content: center;

    .fui-container {
        padding: 0 15px;
        display: flex;
        width: 100%;
        max-width: 1200px;

        h1 {
            margin-bottom: 0;

            @media screen and (max-width: 374px) {
                font-size: 28px; } } }

    @media screen and (min-width: 960px) {
        min-height: 360px;
        height: 360px;

        .fui-container {
            width: 90%; } } }

.fui-search-tag-list {
    position: relative;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin: (-70px) -15px 8px;
    z-index: 3;
    padding-left: 15px;
    padding-right: 15px;

    .fui-search-tag-item {
        display: inline-block;
        position: relative;
        width: 120px;
        height: 62px;
        border: solid 2px $white;
        background-color: rgba(255, 255, 255, 0.24);
        margin-right: $space * 1.5;
        padding: 8px 12px;
        font-size: 18px;
        color: $white;
        font-weight: $font-medium;

        i.icon-check {
            color: $accent;
            display: none;
            font-size: 16px; }

        &.is-active {
            background: $white;
            color: $black50;

            i.icon-check {
                position: absolute;
                width: 20px;
                height: 20px;
                right: 6px;
                bottom: 6px;
                display: block;
                text-align: center;
                line-height: 20px; } } }

    @media screen and (min-width: 960px) {
        margin-top: -130px;
        margin-bottom: $space * 3;
        overflow-x: visible;

        .fui-search-tag-item {
            width: calc(100% / 6 - 12px);
            height: 106px;
            padding: 18px;

            i.icon-check {
                right: 18px;
                bottom: 18px;
                width: 30px !important;
                height: 30px !important;
                font-size: 24px;
                line-height: 30px !important; } } }

    @media screen and (min-width: 1200px) {
        .fui-search-tag-item {
            font-size: 22px; } } }

.fui-cards.is-product {
    .fui-card.is-prod-promo {
        width: calc(50% - 12px);
        margin: 0 6px 12px; }

    .no-result {
        text-align: center;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        color: $gray100;
        margin: 0 auto 12px;
        line-height: 0;

        img {
            margin: 0 auto ($space * 1.5);
            line-height: 0; } }

    @media screen and (min-width: 960px) {
        &.four-card .fui-card.is-prod-promo {
            width: calc(25% - 12px); } } }

.fui-card.is-prod-promo {
    .fui-card-image {
        img {
            width: auto;
            height: 100px; } }

    .fui-card-image {
        height: 160px; }

    .fui-card-caption {
        padding: 12px;
        background-image: linear-gradient(301deg, #c4d3d6 20%, #f4f9fa);

        .fui-card-title {
            color: $darkgreen;
            font-size: 18px; } }

    @media screen and (min-width: 960px) {
        .fui-card-image {
            height: 244px;

            img {
                width: auto;
                height: 160px; } }

        .fui-card-caption {
            padding: 24px 30px;

            .fui-card-title {
                font-size: 22px; } } } }

.fui-card.is-promotion {
    .fui-card-action {
        background-image: url(#{$publicURL}/resources/cbu/exclusive/card-promotion-pattern-02@2x.png) !important;
        background-size: cover !important;
        display: flex;
        height: 100%;

        .fui-card-content {
            background: none;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: $space * 1.5;
            padding-right: $space * 1.5;

            .fui-card-title {
                font-size: 22px; }

            .fui-card-extra {
                padding: 0;

                .fui-button {
                    min-width: 0;
                    width: 100%; } } } }

    @media screen and (min-width: 960px) {
        .fui-card-action .fui-card-content {
            padding-left: $space * 3;
            padding-right: $space * 3;

            .fui-card-extra .fui-button {
                min-width: 190px;
                width: auto; } } } }

.fui-card.is-combine,
.fui-card.is-radio-card {
    flex-direction: column;
    border: solid 2px rgba(47, 89, 196, 0.2);

    > .fui-card-action {
        min-height: 0px;

 }        // padding-bottom: ($space * 2) !important

    > .fui-card-button {
        padding: 0 20px ($space * 2) !important;

        > .fui-button {
            margin: 0;
            flex: 1;
            width: 100%; } } }

.fui-card.is-combine {
    label {
        position: absolute; }

    > .fui-card-action {
        padding: ($space * 6) 0 ($space * 2) !important;

        .fui-card-extra {
            text-align: left; }

        .fui-card-content .fui-card-plan {
            background: none;

            h4 {
                text-align: left; } } }

    .fui-card-action {
        flex: 1 1 auto;
        flex-grow: 1;
        padding-bottom: 20px;

        .fui-card-plan {
            border-bottom: 0; }

        .MuiGrid-root.MuiGrid-container {
            position: relative;
            flex: 1;
            flex-grow: 1;

            &:last-child .MuiGrid-root.MuiGrid-item {
                justify-content: flex-end; }

            &::after {
                content: "";
                width: 2px;
                height: calc(100% - 55px);
                position: absolute;
                left: 0%;
                right: 0;
                top: 0;
                margin: auto;
                background: #D8D8D8;
                z-index: 0; }

            &::before {
                content: "";
                font-family: "icomoon";
                font-size: 2.5rem;
                width: 36px;
                height: 54px;
                background: $white;
                position: absolute;
                left: -5px;
                right: 0;
                top: 50px;
                margin: auto;
                text-align: center;
                line-height: 54px;
                color: #D8D8D8;
                z-index: 2; }

            .MuiGrid-item {
                padding: 0 20px;
                display: flex;
                flex-direction: column;

                .fui-card-content {
                    padding: 0;
                    flex: 1 1 auto;
                    flex-grow: 1 1 auto;

                    .fui-card-image {
                        display: flex;
                        align-content: center;
                        align-items: center;
                        justify-content: center; } }

                .fui-card-plan h5 {
                    color: $darkgray50;
                    font-size: 1rem;
                    text-align: left;
                    line-height: 1.5;
                    margin-top: $space * 5;

                    b {
                        font-size: 24px; } } } }

        .fui-card-extra {
            position: relative;
            display: block;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0px;
            z-index: 9;

            .option-selector,
            .fui-card-price {
                .label {
                    font-size: 0.875rem;
                    white-space: nowrap;
                    margin-right: $space; }

                del {
                    margin-right: $space / 2;
                    font-size: 14px;
                    font-weight: 400; } }

            .option-selector {
                display: flex;
                align-content: center;
                align-items: center;
                margin-top: $space; } }

        .fui-card-caption {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            .body {
                font-size: 18px;
                overflow: visible;
                height: auto;
                -webkit-line-clamp: initial; } }

        .fui-card-image {
            height: 152px;

            img {
                width: auto;
                height: 100%; }

            &.rateplan > img {
                max-width: 100%;
                height: auto; } }

        .fui-card-plan {
            h3, h4 {
                margin-top: 42px;
                color: $darkgray50;
                width: auto; } } } }

.slick-slider.exclusive-radio {
    .slick-arrow {
        display: block !important;
        position: absolute;
        width: 40px;
        height: 40px;
        background: $white;
        border-radius: 20px;
        overflow: hidden;
        border: 0;
        z-index: 1;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        color: transparent;
        top: 0px;
        bottom: 0;
        margin: auto;
        opacity: 1;
        transition: opacity 0.3s ease;

        &.slick-disabled {
            opacity: 0; }

        &::after {
            position: absolute;
            font-family: "icomoon" !important;
            speak: none;
            color: $black50;
            width: 28px;
            height: 28px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 28px;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1; }

        &.slick-prev {
            left: -10px;

            &::after {
                content: ""; } }

        &.slick-next {
            right: -10px;

            &::after {
                content: ""; } } }

    .slick-list {
        position: relative;
        margin-left: -6px;
        margin-right: -6px;
        overflow: visible; }

    .slick-track,
    .slick-list {
        display: flex;

        .slick-slide {
            position: relative;
            display: inline-flex;
            flex-grow: 1;
            padding: 0 6px;
            min-width: 360px !important;

            > div {
                display: flex;
                width: 100%; }

            @media screen and (max-width: 374px) {
                min-width: 304px !important;
                max-width: 304px !important; } } }

    .fui-card.is-combine {
        .fui-card-extra .fui-card-price {
            del, b {
                display: inline-block; } } }

    .fui-card.is-radio-card {
        .fui-card-action {
            padding-bottom: 16px !important; } }

    .fui-card.is-combine,
    .fui-card.is-radio-card {
        padding-top: 0;
        display: flex !important;
        flex-grow: 1;

        label {
            position: absolute; }

        &::before,
        &::after {
            content: "";
            position: absolute;
            display: block;
            border-radius: 50%;
            z-index: 3;
            box-sizing: border-box;
            pointer-events: none; }

        // &::before
        //     width: 30px
        //     height: 30px
        //     left: 12px
        //     top: 40px
        //     border: solid 2px rgba(47, 89, 196, 0.2)

        &.is-selected {
            border: solid 2px $error;

            &::before {
                border: 0;
                background: $error; }

            &::after {
                background: $white;
                top: 50px;
                left: 22px;
                width: 10px;
                height: 10px;
                z-index: 4; }

            .fui-button.is-secondary {
                border-color: $error;
                color: $error;

                .text {
                    color: $error; } } }

        .fui-card-action {
            padding: 48px 20px 0; }

        .MuiGrid-root.MuiGrid-container {
            .MuiGrid-item {
                padding: 0 ($space * 2.5); }

            .MuiGrid-item.product {
                width: 60%;
                flex-basis: 60%; }

            .MuiGrid-item.rateplan {
                width: 40%;
                flex-basis: 40%; } }

        .fui-card-action .fui-card-extra {
            padding: 0 !important;

            > [role="button"] {
                line-height: 40px; }

            .fui-dropdown.is-button button {
                height: 40px; }

            .text {
                line-height: 40px; } }

        .fui-card-action + .fui-card-extra {
            padding: 0 ($space * 2.5) 16px;

            > [role="button"] {
                line-height: 40px; }

            .text {
                line-height: 36px; } }

        @media screen and (max-width: 1024px) {
            .fui-card-action {
                .MuiGrid-root.MuiGrid-container .MuiGrid-item {
                    .fui-card-plan h5 {
                        font-size: 1rem;

                        b {
                            font-size: 1.5rem; } } } }

            .fui-card-extra {
                .fui-card-price {
                    .label {
                        font-size: 0.875rem;
                        margin-right: $space / 2; }

                    .price {
                        font-size: 1rem; }

                    del {
                        margin-right: 0;

 } } } }                        // font-size: 12px

        @media screen and (max-width: 374px) {
            .fui-card-action {
                min-height: 0px;

                .MuiGrid-root.MuiGrid-container .MuiGrid-item {
                    padding: 0 10px;

                    .fui-card-plan h5 {
                        font-size: 0.875rem;

                        b {
                            font-size: 1.125rem; } } }

                .fui-card-plan h4,
                .fui-card-caption .body {
                    font-size: 1rem;
                    line-height: 1.5; }

                .fui-plan-list {
                    .fui-plan-item {
                        .item-title {
                            width: $space * 10;
                            margin-right: $space; }

                        .item-content,
                        .item-title {
                            font-size: 0.875rem; } } }

                .fui-card-extra {
                    .fui-card-price {
                        margin-top: $space;

                        > div {
                            display: block;

                            * {
                                vertical-align: middle; } }

                        span.label {
                            display: block;
                            font-size: 12px;
                            line-height: 22px; }

                        * {
                            vertical-align: middle; }

                        b, del {
                            line-height: 22px;
                            display: inline-block;
                            vertical-align: top; }

                        b.price {
                            font-size: 14px; } } } } } } }
