.radio-carousel-container {
    position: relative;

    .slick-slider.slick-initialized {
        min-height: 300px;

        .slick-arrow {
            display: block !important;
            position: absolute;
            width: 40px;
            height: 40px;
            background: $white;
            border-radius: 20px;
            overflow: hidden;
            border: 0;
            z-index: 1;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            color: transparent;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 1;
            transition: opacity 0.3s ease;

            &.slick-disabled {
                opacity: 0; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 28px;
                height: 28px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 28px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1; }

            &.slick-prev {
                left: -10px;

                &::after {
                    content: ""; } }

            &.slick-next {
                right: -10px;

                &::after {
                    content: ""; } } }

        .slick-list {
            overflow: visible; }

        .slick-list .slick-track .slick-slide {
            padding: 6px;

            .no-result {
                text-align: center;
                display: flex;
                justify-content: center;
                align-content: center;
                color: $gray100;

                img {
                    margin: 0 auto ($space * 1.5);
                    line-height: 0; } }

            > div {
                width: 100%; }

            .radio-buttons.is-number-group {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -6px;
                width: calc(100% + 12px) !important;

                label.radio-button {
                    position: relative;
                    display: inline-flex;
                    width: calc(50% - 12px);
                    margin: 6px;
                    min-height: 76px;
                    height: 76px;
                    height: 76px;
                    background: $white;

                    &.is-disabled {
                        opacity: .5;
                        cursor: default;

                        input:not(:checked) ~ .checkmark {
                            background: none;
                            opacity: 0; }

                        input:not(:checked) ~ .content {
                            filter: grayscale(100); } }


                    input[type="radio"] {
                        position: absolute;
                        opacity: 0; }

                    input[type="radio"]:checked,
                    input[type="radio"][checked] {
                        + .content {
                            color: $error;
                            border-color: $error;

                            .fui-tag {
                                background: $error; } }

                        + .content + .checkmark {
                            border-color: $error; } }

                    .checkmark {
                        border-color: rgba(47, 89, 196, 0.2);
                        left: 12px;
                        top: 12px; }

                    .content {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: $darkgray50;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        font-size: 1rem;
                        font-weight: $font-medium;
                        border: 2px solid rgba(47, 89, 196, 0.2);
                        padding: ($space * 1.5) 30px;

                        .phone-number {
                            position: relative;
                            margin-bottom: 6px;
                            line-height: 28px; }

                        .text-sm {
                            font-weight: $font-regular;
                            text-align: center; } }

                    .fui-tag {
                        position: absolute;
                        top: 0;
                        margin: auto;
                        right: 0px;
                        font-size: 0.875rem;
                        color: $white;
                        font-weight: $font-regular;
                        background: #95a3a4;
                        padding: 0 12px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 15px; } }

                &.is-disabled {
                    label.radio-button {
                        cursor: default;
                        opacity: 0.5;

                        &:hover input:not(:checked) ~ .checkmark {
                            background: none; } } } } } }

    .search-input {
        position: absolute;
        width: 360px;
        right: 0;
        top: -60px;
        z-index: 3;

        .dropdown-wrapper {
            padding: 30px 15px;
            background: $white;
            height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);

            p {
                font-weight: $font-medium; }

            .fui-button.is-label {
                min-width: 0;
                margin-right: $space * 1.2;
                margin-bottom: $space * 1.2;

                .text {
                    font-size: 0.875rem; } } }

        .input-group {
            position: relative;
            height: 50px;

            input {
                border: solid 2px $lightgray100;
                background-color: #ffffff;
                width: 100%;
                height: 50px;
                font-size: 1rem;
                padding: 0 ($space * 9.5) 0 ($space * 1.5);

                &:disabled::placeholder {
                    color: $lightgray100; }
                &:focus {
                    border-color: $black50; } }

            button {
                border: 0;
                background: none;
                position: absolute;
                width: 24px;
                height: 50px;
                top: 0;
                padding: 0;

                i {
                    font-size: 24px; }

                &.search {
                    right: 18px; }

                &.reset {
                    right: 48px; } } } }

    @media screen and (min-width: 1440px) {
        .slick-slider.slick-initialized {
            .slick-list .slick-track .slick-slide {
                .radio-buttons.is-number-group {
                    label.radio-button {
                        width: calc(33.33333% - 12px); } } } } }

    @media screen and (max-width: 768px) {
        .search-input {
            position: relative;
            top: 0;
            width: 100%; }

        .slick-slider.slick-initialized {
            .slick-list .slick-track .slick-slide {
                max-width: none;

                .radio-buttons.is-number-group {
                    display: flex !important; }

                .radio-buttons.is-number-group label.radio-button {
                    padding: 0;
                    min-height: 151px;
                    height: 151px;

                    .content {
                        position: relative;
                        padding: 24px 8px 12px;

                        .phone-number {
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            justify-content: center;
                            align-content: center;
                            right: auto;
                            top: auto;
                            bottom: auto;

                            > div {
                                display: block;
                                width: 100%;
                                text-align: center; }

                            .fui-tag {
                                position: static;
                                width: auto;
                                min-width: 70px;
                                bottom: auto;
                                display: inline-block;
                                top: 0;
                                height: 24px;
                                line-height: 24px;
                                margin-top: 4px; }

                            + .text-sm {
                                font-size: 12px;
                                text-align: center; } } }

                    .checkmark {
                        width: 24px;
                        height: 24px;
                        left: 6px;
                        top: 6px;

                        &::after {
                            top: 6px;
                            left: 6px;
                            width: 8px;
                            height: 8px; } } } } } } }

.fui-tab-pane .fui-checkbox-cards-container {
    padding-top: $space * 1.5; }

.fui-radio-cards-container {
    .tag-filter {
        margin-bottom: 18px;

        .fui-button.is-label {
            height: 36px;

            .text {
                height: 36px;
                line-height: 32px; }

            &.is-active {
                border-color: $black50 !important; } } }

    .slick-slider {
        .slick-arrow {
            display: block !important;
            position: absolute;
            width: 40px;
            height: 40px;
            background: $white;
            border-radius: 20px;
            overflow: hidden;
            border: 0;
            z-index: 1;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            color: transparent;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 1;
            transition: opacity 0.3s ease;

            &.slick-disabled {
                opacity: 0; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 28px;
                height: 28px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 28px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                z-index: 0; }

            &.slick-prev {
                left: -10px;

                &::after {
                    content: ""; } }

            &.slick-next {
                right: -10px;

                &::after {
                    content: ""; } } } }

    .slick-slider {
        margin: 0 -6px; }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        padding-bottom: $space * 3;

        .slick-slide {
            padding: 0 6px; } }

    .slick-slider .slick-track {
        white-space: nowrap;

        .slick-slide {
            display: inline-block;
            float: none; } }

    .slick-slider .slick-list {
        overflow: visible; }

    .fui-card {
        position: relative;
        border: solid 2px rgba(47, 89, 196, 0.2);
        box-shadow: none !important;
        background: $white;
        display: inline-flex;
        flex-direction: column;
        padding-top: 0;

        label.fui-card-action {
            position: relative;
            padding-top: 48px;
            background: none;
            z-index: 1;
            display: flex;
            flex: 1 1 auto;
            flex-grow: 1;
            flex-direction: column; }

        &.is-radio {
            padding-top: 0;

            input[type='radio'] {
                position: absolute;
                opacity: 0; }

            &::after {}

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 30px;
                height: 30px;
                color: transparent;
                display: block;
                top: 12px;
                left: 12px;
                border: solid 2px rgba(47, 89, 196, 0.2);
                text-align: center;
                line-height: 30px;
                box-sizing: border-box;
                z-index: 0;
                border-radius: 50%; }

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 10px;
                height: 10px;
                color: transparent;
                display: block;
                top: 22px;
                left: 22px;
                text-align: center;
                box-sizing: border-box;
                background: $white;
                z-index: 0;
                border-radius: 50%; } }

        &.is-checked {
            border-color: $error;

            &::before {
                border-color: $error;
                background: $error; }

            &::after {
                display: block; } }

        &.is-plan {
            height: 380px;
            display: flex !important;
            flex-direction: column;

            .fui-card-extra {
                padding-bottom: 30px; }

            .fui-card-action {
                flex: 1 1 auto;
                padding-top: $space * 6;

                .fui-card-plan {
                    width: 100%;
                    height: 141px;
                    text-align: center;
                    color: $white;
                    background-size: auto 141px;
                    background-position: center;
                    background-repeat: no-repeat;

                    h1, h4 {
                        margin: 0; }

                    h1 {
                        line-height: 63px; }

                    h4 {
                        margin-top: -12px; }

                    &.is-friday {
                        background-image: url(#{$publicURL}/resources/cbu/estore-product/addition-plan-friday@2x.png); }

                    &.is-music {
                        background-image: url(#{$publicURL}/resources/cbu/estore-product/addition-plan-music@2x.png); } }

                .fui-card-caption {
                    .fui-card-title {
                        font-size: 20px;
                        height: auto;
                        font-weight: $font-regular; }

                    .fui-card-price {
                        margin-top: $space * 1.5; } } } }

        .fui-card-caption {
            padding: ($space * 3) 30px ($space * 1.5);

            .fui-card-title {
                font-size: 20px;
                height: 56px;

                @include ellipsis(2);

                span.tag {
                    display: inline-block;
                    line-height: 18px;
                    height: 20px;
                    padding: 0 6px;
                    font-size: 0.875rem;
                    border: 1px solid $black50;
                    border-radius: 3px;
                    margin-right: 6px;
                    vertical-align: middle;
                    font-weight: $font-medium; } }

            .fui-card-price {
                * {
                    font-size: 1rem; }

                .label {
                    margin-right: 6px; }

                del {
                    color: $gray100; }

                .price {
                    margin-left: 3px; } } }

        .fui-card-extra {
            padding-left: 30px;
            padding-right: 30px;

            > * {
                font-size: 0.875rem;
                font-weight: $font-medium; }

            .is-text-underline {
                display: inline-block;

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: $black50; } } }

        .fui-number-selector-container {
            padding: 0 30px 30px;
            width: 100%;

            .fui-number-selector {
                width: 100%;
                display: table;

                > div {
                    display: table-cell;
                    width: calc(100% - 80px);
                    margin: 0 40px;
                    line-height: 0;
                    height: 40px;
                    box-sizing: border-box;
                    vertical-align: middle;

                    input {
                        padding: 0;
                        height: 36px;
                        line-height: 36px; } }

                [role="button"] {
                    width: 40px;
                    line-height: 36px; } } } }

    .option-selector {
        display: flex;
        align-items: center;
        margin-top: $space * 2;

        span.label {
            white-space: nowrap;
            margin-right: $space * 2; } }

    @media screen and (max-width: 1024px) {
        .slick-slider .slick-track .slick-slide {
            min-width: 302px;
            max-width: 302px; } }

    @media screen and (max-width: 960px) {
        .slick-list {
            overflow: visible; }

        .fui-cards.no-scrollbar .fui-card {
            width: calc(50% - 12px); }

        .fui-card {
            width: 100%;

            &.is-plan {
                min-width: 290px;
                width: 290px;
                max-width: 290px; }

            &.is-checkbox::after {
                width: 24px;
                height: 24px;
                line-height: 20px; }

            .fui-card-caption {
                .fui-card-title {
                    height: 46px;
                    font-size: 1rem;

                    span.tag {
                        font-size: 0.75rem; } } }

            .fui-card-extra,
            .fui-card-caption {
                padding-left: 12px;
                padding-right: 12px; }

            .fui-card-action {
                padding-top: $space * 3; }

            .fui-number-selector-container {
                padding-left: 12px;
                padding-right: 12px;
                padding-bottom: 12px;

                .fui-number-selector > div {
                    position: relative;

                    input {
                        left: 0;
                        top: 0;
                        width: 100%; } } } } }

    @media screen and (max-width: 374px) {
        .slick-slider .slick-track .slick-slide {
            min-width: 290px;
            max-width: 290px; } } }
