.carousel-banner-3 {
    height: 360px;
    background-color: $gray100;
    background-position: center;
    margin-bottom: 60px;

    .slick-dots {
        position: absolute;
        bottom: 30px;
        z-index: 1;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        left: 0;
        right: 0;
        text-align: center;

        .slick-active {
            background: $white;

            button {
                opacity: 0;
                background-color: #fff; } }

        li {
            position: relative;
            display: inline-block;
            margin-right: 6px;
            background-color: $gray100;
            height: 4px;
            width: 50px;

            button {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                left: 0;
                cursor: pointer; }

            &:nth-last-child(1) {
                margin-right: 0;

                button {
                    cursor: pointer;
                    width: 50px;
                    height: 4px;
                    border: 0;
                    vertical-align: top; } } } }

    .slide {
        position: relative; }

    .bg-img {
        position: absolute;
        width: 100%;
        height: 100%;
        filter: brightness(0.8); }

    .content {
        height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 100px;

        p {
            font-weight: 600; }

        .subtitle {
            margin-top: 30px;
            margin-bottom: 0; }

        h1 {
            margin-top: 10px;
            margin-bottom: 22px; }

        a {
            border-color: #fff !important;
            margin-right: 0; } } }

@media screen and (max-width: 960px) {
    .carousel-banner-3 {
        height: 320px;
        margin-bottom: 0;

        .content {
            padding-left: 0;
            text-align: center;
            display: block;

            .subtitle {
                margin-top: 60px; }

            h1 {
                margin-bottom: 80px; }

            a {
                min-width: 190px;
                padding: 2px 16px; } }

        .slick-dots {
            position: relative;
            bottom: 40px; }

        .bg-img {
            height: 320px; } } }
