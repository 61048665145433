.fui-cards {
    position: relative;
    display: block;
    overflow-y: visible;
    overflow-x: auto;
    margin: 0 0;
    padding: 30px 0 60px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    .fui-card {
        display: inline-flex;
        vertical-align: top;
        margin: 0 6px; }

    &.four-card {
        display: flex; }

    &::-webkit-scrollbar {
        display: none; }

    &.with-collapse-card {
        display: block !important;

        &.two-card {
            .fui-cards {
                width: calc(50% - 12px); } }

        &.three-card {
            .fui-card .fui-card-caption .fui-card-description > div {
                -webkit-line-clamp: 8 !important; } } }

    // .is-video.fui-card
    // // margin-right: 12px!important
    &.is-video {
        flex-wrap: wrap;

        // height: 430px
        overflow: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        flex-wrap: nowrap;

        // width: 100%
        display: flex;

        &.two-card {
            margin: 0 -21px;
            padding: 0px 15px 60px 15px; }

        .fui-card-action {
            min-height: initial;

            // height: 340px
            width: 270px; }

        .fui-card.is-video .fui-card-image {
            padding: 0;
            display: inline-block;
            height: 152px; }

        // .fui-card
        //     margin-bottom: 12px!important
        //     margin-right: 12px!important
        // // margin: 0 6px
        .fui-card .fui-card-caption {
            // height: 162px
            // .fui-card-content
 }            //     flex: 1 1 0

        + .load-more {
            text-align: center;
            position: relative;
            margin: 36px 0 90px 0;

            a.expand {
                color: $black50;
                font-weight: 500;

                &::after {
                    display: none; } }

            .no-more {
                color: #bfbfbf;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: default !important;

                i {
                    font-size: 30px;
                    margin-right: 6px; } } } }

    &.no-scrollbar {
        overflow: visible;
        white-space: normal;
        flex-wrap: wrap;

        .fui-card {
            width: calc(100% - 12px);
            margin-bottom: 12px; }

        @media screen and (min-width: 640px) {
            .fui-card {
                width: calc(50% - 12px); } } }

    &.news {
        overflow: visible;

        &::after {
            display: none; } }

    @media screen and (max-width: 768px) {
        &.news {
            .fui-card {
                // width: calc(100% - 12px) !important
                width: 100%;
                margin: 0; }

            .fui-card + .fui-card {
                display: none; } }

        &.four-card {
            flex-wrap: wrap;

            .fui-card.service-entry {
                margin-bottom: $space * 1.5;
                width: calc(50% - #{$space * 1.5}); } } }

    &.two-card:not(.is-video) .fui-card {
        width: 100%;
        margin: 0 0 ($space * 1.5); }

    a.fui-card-action:hover {
        color: $black50; }

    &.is-flat {
        padding: 0 !important;
        overflow: visible;

        .fui-card {
            min-width: 0; } } }

.fui-video-card-container {
    .fui-cards.is-video-list {
        flex-wrap: wrap;
        white-space: normal;
        margin: 0;
        padding: 0;
        overflow: visible;

        .fui-card.is-video {
            width: 100%;
            margin-bottom: 12px;
            margin-left: 0;
            margin-right: 0; }

        @media screen and (min-width: 600px) {
            margin: 0 -6px;

            .fui-card.is-video {
                margin: 6px;
                width: calc(50% - 12px); } }

        @media screen and (min-width: 960px) {
            .fui-card.is-video {
                width: calc(33.33333% - 12px); } } } }

.load-more .no-more {
    color: $gray100 !important;
    cursor: default !important; }

.fui-container .fui-cards {
    margin: 0 -6px;
    padding: 30px 15px; }

.fui-card {
    display: flex;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12) !important;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18) !important; }

    .fui-card-action:hover {
        .fui-button.is-arrow {
            color: transparent; } }

    &.is-number-step {
        position: relative;
        margin-top: 10px !important;

        .fui-card-caption {
            padding: 70px 30px 36px; }

        .number {
            position: absolute;
            top: -40px;
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            font-size: 42px;
            color: $white;
            background: $darkgreen;
            border-radius: 50%; }

        .fui-card-title {
            margin-bottom: 0; }

        .fui-card-description {
            color: $darkgray50;
            font-size: 18px;
            line-height: 1.78; }

        @media screen and (max-width: 768px) {
            margin-top: 62px !important;

            .number {
                width: 60px;
                height: 60px;
                line-height: 60px;
                top: -30px; }

            .fui-card-caption {
                padding: 58px 16px 36px; } } }

    &.is-document {
        position: relative;
        min-height: 72px;
        border: solid 1px $lightgray100;
        align-content: center;
        align-items: center;

        .content {
            flex: 1 1 0;
            flex-grow: 1;
            padding: 22px 20px;

            a,
            a:link {
                display: block;
                color: $black50;

                &::after {
                    display: none; } } }

        .extra {
            padding-left: 12px;
            padding-right: 12px;
            border-left: 1px solid $gray50;

            .fui-button,
            .fui-button i {
                margin: 0;
                padding: 0;
                color: $navy50;

                &[disabled] i {
                    color: $gray50; } }

            .fui-button i {
                font-size: 24px; } } }

    &.is-price-card .fui-card-action .fui-card-content {
        position: relative;
        text-align: center;
        padding: 30px 20px;

        h6 {
            margin: 0; }

        .ribbon {
            position: absolute;
            left: 0;
            top: 0;
            color: $white;
            height: 30px;
            padding: 0 10px 0 12px;
            line-height: 28px;
            background-color: #85D1DC;

            > * {
                position: relative;
                z-index: 1; }

            &::before {
                content: "";
                position: absolute;
                width: 160%;
                height: 100%;
                left: 0;
                background: url(#{$publicURL}/resources/cbu/product/images/price-ribbon.png) right center no-repeat;
                background-size: auto 100%;
                z-index: 0; } }

        .fui-price {
            color: $business50;
            font-size: 56px;
            margin-bottom: 30px;

            span.prefix {
                font-size: 22px;
                vertical-align: top;
                margin-right: $space * 0.5; }

            span.unit {
                color: $black50;
                font-size: 0.875rem;
                vertical-align: bottom;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 16px;
                    background: $gray70;
                    transform: rotate(40deg);
                    margin: 0 $space;
                    vertical-align: middle; } }

            span {
                display: inline-block;
                line-height: 1; } }

        .fui-list {
            display: inline-block; }

        .fui-item {
            text-align: left;
            color: $darkgray50;

            .prefix {
                color: $business50; } } }

    &.is-simple {
        display: flex;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: $space * 1.5;

        > div {
            width: 100%; }

        .fui-card-caption {
            background: $white;
            min-height: 0;

            .fui-card-content {
                padding: 30px; }

            .fui-card-description {
                margin-bottom: 0;
                font-size: 1rem;
                line-height: 1.75rem;
                height: auto;
                display: block;
                -webkit-line-clamp: initial; }

            .fui-card-title {
                margin-top: 0; } }

        .fui-card-extra {
            display: none; }

        &.is-blue {
            .fui-card-caption .fui-card-content .fui-card-title {
                color: $business50; } } }

    &.is-tooltip {
        .fui-card-content .fui-card-title {
            margin-bottom: $space * 1.5;

            &.fui-tooltip-container {
                margin-left: $space; }

            + .fui-card-description {
                margin-top: 0; } } }

    &.is-collapse {
        display: inline-block;
        flex-direction: column;
        background: $white;
        margin-bottom: $space * 1.5;

        .fui-card-image {
            width: auto; }

        .fui-card-extra {
            display: block;
            padding: 0 !important;

            > * {
                display: block;
                padding: 10px 0;
                text-align: center;

                * {
                    vertical-align: middle; } } }

        .fui-card-caption {
            flex: 1 1 0;

            .fui-card-content {
                padding-bottom: $space * 10;

                + .fui-card-extra {
                    margin-top: -$space * 5; } }

            .fui-card-title {
                text-align: center;

                img {
                    width: 48px;
                    height: 48px;
                    display: block;
                    margin: ($space * 3) auto; } }

            .fui-card-description {
                font-size: 1rem;
                margin-bottom: 0;
                transition: height 0.3s ease;
                -webkit-line-clamp: initial;

                > div {
                    @include ellipsis(5); } } }

        &.is-open {
            .fui-card-caption .fui-card-description div.text {
                -webkit-line-clamp: initial !important; } }

        &.is-blue {
            .fui-card-caption .fui-card-title {
                color: $business50; }

            .fui-card-extra > .fui-button {
                padding-top: 0;
                padding-bottom: 0;
                margin: 0;
                width: 100%;
                color: $business50;
                background: $lightgray50; } } }

    .fui-card-action,
    a.fui-card-action {
        min-height: 218px;
        display: flex;
        white-space: initial;
        flex-direction: column;
        cursor: pointer;

        // width: 268px
        width: 100%;
        color: $black50;
        background: #FFF;

        &::after {
            display: none; }

        &:hover {
            color: $black50;
            background: #FFF;

            .fui-card-image {
                img {
                    transform: scale(1.1); } } } }

    .np-card-action {
        min-height: 218px;
        display: flex;
        white-space: initial;
        flex-direction: column;

        // width: 268px
        width: 100%;
        color: $black50;
        background: #FFF;

        &::after {
            display: none; }

        &:hover {
            color: $black50;
            background: #FFF;

            .fui-card-image {
                img {
                    transform: scale(1.1); } } } }

    .fui-card-caption {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto; }

    .fui-card-content {
        flex: 1 1 auto;
        padding: ($space * 3) ($space * 3) 0;

        .fui-card-date {
            font-size: 1rem;
            margin-bottom: $space;
            font-weight: 500; }

        .fui-card-meta {
            color: $gray100;
            font-size: 0.875rem;
            margin-bottom: $space; }

        .fui-card-title {
            font-weight: $font-medium;
            font-size: 1.5rem;
            margin: $space 0;

            @include ellipsis(2);

            span.tag {
                display: inline-block;
                line-height: 18px;
                height: 20px;
                padding: 0 6px;
                font-size: 0.75rem;
                border: 1px solid $black50;
                border-radius: 3px;
                margin-right: 6px;
                vertical-align: middle;
                font-weight: $font-medium; }

            .text {
                font-size: 1.5rem;
                line-height: 1.5; }

            .icon {
                width: 48px;

                img {
                    width: 100%; } } }

        .fui-card-description {
            font-size: 1rem;
            line-height: 28px;
            display: block;
            display: -webkit-box;
            height: 3.5em;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            * {
                margin: 0 !important; }

            br {
                display: none !important; } } }

    .fui-card-extra {
        padding: 0 ($space * 3) ($space * 2); }

    .fui-card-image + .fui-caption > .fui-content {
        min-height: 0; }

    .fui-card-image {
        position: relative;
        overflow: hidden;
        padding-bottom: 56.25%; }

    .fui-card-image,
    .fui-card-image img {
        line-height: 0;
        width: 100%; }

    .fui-card-image img {
        position: absolute;
        left: -100%;
        right: -100%;
        top: -100%;
        bottom: -100%;
        margin: auto;
        height: 100%;
        transform: scale(1);
        transition: transform 0.3s ease; }

    &.service-entry {
        margin-bottom: $space * 1.5;

        .fui-card-action {
            width: 100%;
            min-height: 0;
            background: #FFF;

            &:hover {
                background-size: 110% auto; }

            .fui-card-content {
                text-align: center;
                padding: 20px 0;

                .fui-card-title {
                    margin: 0;

                    .text {
                        font-size: 1rem; } } }

            .fui-card-extra {
                display: none; } } }

    &.box {
        .fui-card-action {
            position: relative;
            min-height: 150px;

            .icon {
                position: absolute;
                color: #FFF;
                right: 0;
                bottom: 0;
                z-index: 3;
                line-height: 0;

                img {
                    width: 80px;
                    line-height: 0; } }

            .fui-card-content {
                padding: 20px;
                height: 150px;
                word-break: break-all;
                color: #FFF;
                background: $lightgray50;
                background-size: cover;
                background-position: left top;

                h4 {
                    margin-top: 0; } } }

        &.is-blue .fui-card-action .fui-card-content {
            background-image: url(#{$publicURL}/resources/common/images/ebu-theme-pattern-01.png); }

        &.is-business .fui-card-action .fui-card-content {
            background-image: url(#{$publicURL}/resources/common/images/ebu-theme-pattern-02.png); }

        &.is-navy .fui-card-action .fui-card-content {
            background-image: url(#{$publicURL}/resources/common/images/ebu-theme-pattern-03.png); }

        &.is-gray .fui-card-action .fui-card-content {
            background-image: url(#{$publicURL}/resources/common/images/ebu-theme-pattern-04.png); }

        &.is-yellow .fui-card-action .fui-card-content {
            background-image: url(#{$publicURL}/resources/cbu/cbu-index/cbu-menu-pattern-1.png); }

        &.is-red .fui-card-action .fui-card-content {
            background-image: url(#{$publicURL}/resources/cbu/cbu-index/cbu-menu-pattern-2.png); }

        &.is-sky .fui-card-action .fui-card-content {
            background-image: url(#{$publicURL}/resources/cbu/cbu-index/cbu-menu-pattern-3.png); }

        .fui-card-extra {
            display: none; }

        .fui-card-content {
            .fui-card-title {
                height: auto;

                @extend .subtitle; } } }

    &.promotion-article {
        min-width: 0;
        width: calc(50% - 16px);
        color: #FFF;
        margin: 0 8px ($space * 2);

        .fui-card-action {
            color: #FFF;
            min-height: 0;

            &:hover {
                color: #FFF; } }

        .fui-card-extra,
        .fui-card-content {
            padding-left: $space * 3;
            padding-right: $space * 3; }

        .fui-card-content {
            flex: 1 1 auto;
            flex-grow: 1;
            padding-bottom: 0;

            .fui-card-meta {
                font-size: 0.85rem; }

            .fui-card-title {
                margin: 6px 0;
                font-size: 1.25rem;
                line-height: 30px;
                display: block;
                display: -webkit-box;
                height: 6em;
                overflow: hidden;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical; }

            .fui-card-description {
                font-size: 1rem; } }

        &.is-blue {
            .fui-card-action {
                background: $navy50; }

            .fui-card-meta {
                color: $blue30; } }

        &.is-main {
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: $space * 2;

            .fui-card-action {
                flex-direction: column-reverse; }

            .fui-card-caption {
                background: $navy50 url(#{$publicURL}/resources/common/images/patternd-blue-article.png) right bottom no-repeat;
                background-size: 508px auto; }

            .fui-card-content {
                padding: 30px;

                .fui-card-description {
                    height: 5rem;
                    -webkit-line-clamp: 3; }

                .fui-card-title {
                    height: auto;
                    -webkit-line-clamp: 2;

                    .text {
                        font-size: 1.75rem;
                        line-height: 1.5;
                        -webkit-line-clamp: 2; } } }

            .fui-card-image {
                position: relative;
                padding-bottom: 50%;
                overflow: hidden;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 103px;
                    height: 75px;
                    background: url(#{$publicURL}/resources/ebu/images/red-arrow.png) no-repeat left bottom;
                    background-size: contain; }

                img {
                    position: absolute;
                    width: 100%;
                    height: auto;
                    top: -100%;
                    bottom: -100%;
                    left: -100%;
                    right: -100%;
                    margin: auto; } } } }

    &.paded .fui-card-content {
        padding: 4rem; } }

.promotion-article-list {
    margin: 0 -6px;
    display: flex;
    flex-wrap: wrap;

    .fui-card.promotion-article {
        width: calc(50% - 12px);
        margin: 0 6px ($space * 2);

        .fui-card-title {
            height: auto; } } }

.fui-card.is-video {
    margin-left: 6px;
    margin-right: 6px;

    // max-height: 340px
    .fui-card-image {
        height: 220px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 2; }

        &::before {
            width: 60px;
            height: 60px;
            background: $white;
            opacity: 0.84;
            border-radius: 30px;
            box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3); }

        &::after {
            left: 20px;
            width: 0;
            height: 0;
            border-left: 16px solid $main;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 16px solid transparent; } } }

@media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .fui-cards.is-video .fui-card.is-video {
        float: left;
        display: block;
        width: initial; } }

@media screen and (min-width: 768px) {
    // .fui-cards.is-video
    //     height: 430px
    // .fui-cards.is-video .fui-card.is-video
    //     width: calc(50% - 12px)
    //     margin-left: 6px
    //     margin-right: 6px
    //     background-color: #fff
    //     margin-bottom: 12px
    //     height: 382px
    //     .fui-card-action
    //         width: 100%

    .fui-card {
        .fui-card-extra {
            padding: 0 ($space * 3) ($space * 2); } }

    .fui-container .fui-cards {
        padding-left: 0;
        padding-right: 0;
        margin: 0 - $space; }

    .fui-cards.two-card {
        .fui-card {
            width: calc(50% - 12px);
            margin-left: 6px;
            margin-right: 6px; } }

    .fui-cards {
        display: flex;
        flex-wrap: no-wrap;
        margin: unquote("#{$space * 6} - 6px");

        .fui-card {
            margin: 0 6px 12px;

            .fui-card-extra {
                padding-left: $space * 3;
                padding-right: $space * 3; }

            .fui-card-content {
                padding: ($space * 3) ($space * 3) 0; }

            .fui-card-content {
                .fui-card-title {
                    -webkit-line-clamp: initial;
                    font-size: 1.5rem; } } }

        &.news .fui-card-content .fui-card-title {
            -webkit-line-clamp: 2; }

        &.four-card .fui-card {
            width: calc(25% - 12px); }

        &.three-card:not(.is-exclusive) {
            &.is-video {
                flex-wrap: wrap; }

            .fui-card {
                width: calc(33.33333% - 12px); } }

        &.service-entry {
            margin-top: 0;

            .fui-card .fui-card-content {
                min-height: 90px;
                color: #ffffff;
                text-align: center; } } }

    .fui-card.service-entry {
        .fui-card-action .fui-card-content {
            padding: ($space * 4) 0; } }

    .fui-card.box .fui-card-action .fui-card-content .fui-card-title {
        font-size: 1.125rem;
        height: auto; }

    .fui-card.promotion-article {
        &.is-main {
            background-size: auto 100%; }

        &.is-main .fui-card-content {
            background-size: auto 100%; }

        &.is-main .fui-card-content,
        .fui-card-content {
            .fui-card-description,
            .fui-card-title {
                -webkit-line-clamp: 3;
                height: auto; } } } }

@media screen and (min-width: 960px) {
    .fui-cards .fui-card .fui-card-content .fui-card-title {
        font-size: 1.125rem !important; }

    .fui-card .fui-card-action:hover .fui-button.is-arrow {
        color: $accent; }

    .fui-cards.two-card:not(.is-video) .fui-card {
        width: calc(50% - 12px);
        margin-left: 6px;
        margin-right: 6px; }

    .fui-cards.is-video .fui-card.is-video {
        width: calc(50% - 12px);
        margin-left: 6px;
        margin-right: 6px;
        background-color: #fff;
        margin-bottom: 12px;

        // height: 382px
        .fui-card-action {
            width: 100%; } }

    .fui-container .fui-cards.is-video {
        overflow: visible;
        padding: 30px 15px 0 15px; }

    .fui-cards.is-video {
        height: auto;
        white-space: initial;

        .fui-card-image {
            height: 220px !important; } }

    .fui-cards.three-card {
        overflow: visible;
        flex-wrap: no-wrap; }

    .fui-card.is-price-card .fui-card-action .fui-card-content {
        padding-top: 60px;
        padding-bottom: 60px; }

    .fui-cards.is-video {
        flex-wrap: wrap !important; }

    .fui-card.is-simple {
        .fui-card-content {
            padding: 60px; } }

    .fui-card.is-collapse {
        .fui-card-title img {
            height: 72px; } }

    .fui-card.promotion-article {
        margin: 0 0 ($space * 2);

        .fui-card-extra {
            padding: 0 ($space * 3) ($space * 2); }

        .fui-card-content {
            padding: ($space * 4) ($space * 3) 0;

            .fui-card-title {
                font-size: 1.375rem;
                line-height: 33px; } }

        &.is-main {
            margin: 0;
            width: 100%;
            height: 100%;

            .fui-card-action {
                display: flex;
                flex-direction: row;
                height: 594px;

                .fui-card-caption {
                    flex: 1 1 0;
                    flex-grow: 1;

                    .fui-card-content {
                        flex: 1;
                        flex-grow: 1;
                        padding-bottom: 0; } }

                .fui-card-image {
                    width: 390px;

                    img {
                        height: 100%;
                        width: auto; } } } } }

    .promotion-article-list {
        flex-direction: column;
        margin: 0;
        flex: 1 1 auto;
        flex-grow: 1;

        .fui-card.promotion-article {
            width: 100%;
            flex: 1 1 0;
            flex-grow: 1;
            margin-left: 0;
            margin-right: 0;

            &:last-child {
                margin-bottom: 0; } } } }

@media screen and (min-width: 1200px) {
    .fui-cards.three-card.is-exclusive {
        &.is-video {
            flex-wrap: wrap; }

        .fui-card {
            width: calc(33.33333% - 12px); } }

    .fui-card.promotion-article {
        &.is-main {
            .fui-card-extra,
            .fui-card-content {
                padding-left: $space * 7;
                padding-right: $space * 7; }

            .fui-card-image::after {
                width: 218px;
                height: 160px; }

            .fui-card-content {
                .fui-card-description {
                    line-height: 1.75em; }

                .fui-meta {
                    font-size: 0.875rem; }

                .fui-card-title {
                    font-size: 2rem;
                    line-height: 1.5em; } } } } }
