a.fui-button,
a.fui-button:link,
a.fui-button:visited,
button.fui-button,
.fui-button {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    padding: 0 ($space * 2);
    border: 0;
    font-size: 1rem;
    min-width: 140px;
    text-align: center;
    opacity: 1;
    vertical-align: top;
    font-weight: $font-medium;
    margin-right: $space;
    margin-bottom: $space * 2;
    transition: all 0.3s ease;
    background: none;
    line-height: 0;
    cursor: pointer;
    height: 40px;

    &::after {
        display: none;
        background: none; }

    &.is-block {
        width: 100%; }

    .text {
        display: inline-block;
        height: 40px;
        line-height: 40px; }

    * {
        vertical-align: middle; }

    &.disabled,
    &[disabled],
    &.disabled:hover,
    &.disabled:active,
    &[disabled]:hover,
    &[disabled]:active,
    &.disabled.hover,
    &[disabled].hover {
        cursor: default !important;
        pointer-events: none;
        color: $gray100 !important;
        background: $gray50 !important;
        border-color: transparent !important;
        opacity: 0.7;

        &.is-text {
            background: none !important; }

        &.is-secondary {
            background: none !important;
            border-color: $gray70 !important; }

        &::after {
            display: none !important; } }

    &.is-api-loading {
        pointer-events: none !important;

        &::before {
            background-color: rgba(255, 255, 255, 0.2); }

        > .text {
            opacity: 0.3; } }

    &.is-text {
        min-width: 0;
        background: none;
        padding-left: 0;
        color: $black50;
        height: initial;

        i {
            font-size: 24px !important;
            margin-right: 36px; }

        &.is-small .text {
            font-size: 14px; }

        [class*="icon-"] {
            position: relative;
            left: 0;
            margin-left: 6px;
            font-size: 1.2rem;
            transition: all 0.3s ease; }

        &:hover,
        &:active {
            opacity: 0.6;

            [class*="icon-"] {
                margin-left: 12px; } } }

    &.is-arrow {
        min-width: 0;
        color: transparent;
        height: 36px;
        line-height: 36px;
        padding-left: 0;
        padding-right: 0;
        transition: all 0.3s ease;

        &::after {
            content: "";
            text-align: left;
            position: absolute;
            display: block;
            width: 100%;
            height: 32px;
            font-size: 42px;
            color: $accent;
            font-family: "icomoon" !important;
            speak: none;
            transform: translateX(0%);
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 0.75;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: all 0.3s ease; }

        &.is-reverse {
            &::after {
                color: $white; } }

        .text {
            display: inline-block;
            opacity: 0;
            vertical-align: middle;
            transition: all 0.2s ease; }

        &.is-reverse.hover,
        &.is-reverse:hover,
        &.is-reverse:active {
            color: $white; }

        &.hover,
        &:hover,
        &:active {
            color: $black100;

            &::after {
                transform: translateX(120%); }

            .text {
                opacity: 1; } } }

    &.is-tag {
        min-width: 160px;
        height: 40px;
        border-radius: 20px;
        background: transparent;
        color: $darkgray50;
        padding-left: $space * 4;
        padding-right: $space * 4;
        border: solid 2px $darkgray50;

        .text {
            line-height: 38px; }

        &.hover,
        &:active,
        &:hover {
            color: $white;
            background: $darkgray50; } }

    &.is-label {
        min-width: 72px;
        border-radius: 30px;
        padding: 0 ($space * 1.5);
        border: solid 2px #d6d6d6;
        margin-right: 6px;
        margin-bottom: 6px;
        background: $white;
        color: $black50;
        font-size: 0.875rem;
        height: 30px;
        white-space: nowrap;

        .text {
            line-height: 26px; }

        @media screen and (max-width: 768px) {
            &:hover {
                border-color: #d6d6d6; } }

        &.is-reverse {
            color: $darkgray50;
            background: $white;
            border: 0;
            font-size: 0.875rem;
            height: 36px;
            line-height: 36px;
            border-radius: 18px;
            margin-right: $space * 1.5; } }

    &.is-tiny {
        min-width: 72px;
        height: 30px;

        .text {
            height: 20px !important;
            line-height: 28px !important; } }

    @media screen and (max-width: 960px) {
        &.is-tiny {
            height: 24px;
            min-width: auto;
            padding: 2px 11px;

            .text {
                font-size: 12px;
                line-height: normal !important; } } }

    &.is-small {
        min-width: 72px;
        height: 30px;

        &.is-secondary {
            border-color: $black50;

            .text {
                height: 20px;
                line-height: 20px; } }

        &.is-secondary.is-text-sm {
            padding: 0 ($space * 1.5);

            .text {
                font-size: 0.875rem; } }

        .text {
            height: 24px;
            line-height: 24px; } }

    &.is-large {
        min-width: 170px;
        height: 50px;
        padding: 0 40px;

        .text {
            height: 50px;
            line-height: 50px; }

        &.is-secondary {
            border-color: $gray70;

            .text {
                height: 46px;
                line-height: 46px; }

            &:hover,
            &:active {
                border-color: $accent;
                color: $accent;
                opacity: 1;

                .text {
                    color: $accent; }

                &::after {
                    color: $accent; } }

            &.is-reverse:hover,
            &.is-reverse:active {
                border-color: $white; } }

        &::after {
            content: "";
            font-family: "icomoon" !important;
            speak: none;
            position: absolute;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            right: 12px;
            font-size: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 0;
            transform: translateX(-5px);
            background-size: contain;
            transition: all 0.3s ease;
            opacity: 0;
            display: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale; }

        &:hover::after,
        &:active::after {
            opacity: 1;
            transform: translateX(0px); } }

    &.is-primary {
        color: #FFF;
        background: $main;

        &.hover,
        &:hover,
        &:active {
            background: $accent; }

        &.is-reverse {
            color: $black50;
            background: $white;

            &.is-large {
                color: $accent; } } }

    &.is-secondary {
        color: $black50;
        border: 2px solid $black50;

        .text {
            display: inline-block;
            height: 36px;
            line-height: 36px; }

        &.hover,
        &:hover,
        &:active {
            border-color: $accent;

            .text {
                color: $accent;
                transition: all 0.3s ease; } }

        &.is-reverse,
        &.is-reverse .text,
        &.is-reverse:not(.is-small)::after,
        &.is-reverse:hover .text,
        &.is-reverse:not(.is-small):hover::after,
        &.is-reverse:active .text,
        &.is-reverse:not(.is-small):active::after {
            color: $white;
            background: none;
            border-color: $white !important;

            .text {
                color: $white; } }

        &.is-reverse:hover,
        &.is-reverse:active {
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAAqADAAQAAAABAAAAAgAAAADO0J6QAAAAFElEQVQIHWP8//+/LwMQMIEIEAAANDwDTjsE0m4AAAAASUVORK5CYII="); } }

    &.is-black {
        border: 2px solid #333333; }

    @media screen and (min-width: 960px) {
        min-width: 190px;

        .text {
            height: 40px;
            line-height: 40px; }

        &.is-arrow {
            &::after {
                font-size: 42px; } }

        &.is-small {
            min-width: 92px;

            .text {
                height: 30px;
                line-height: 30px; } }

        &.is-large {
            min-width: 220px;

            .text {
                height: 50px;
                line-height: 50px; }

            &::after {
                display: block; } }

        &.is-secondary {
            .text {
                height: 36px;
                line-height: 36px; }

            &.is-small {
                .text {
                    height: 26px;
                    line-height: 26px; } }

            &.is-large {
                .text {
                    height: 46px;
                    line-height: 46px; } } }

        &.is-no-arrow,
        &.no-arrow {
            &:after {
                display: none !important; } } } }

.fui-banner .fui-button.is-primary,
.fui-banner a.fui-button.is-primary,
.fui-banner a.fui-button.is-primary:link,
.fui-banner .fui-button.is-secondary,
.fui-banner a.fui-button.is-secondary,
.fui-banner a.fui-button.is-secondary:link {
    min-width: 202px;
    height: 50px;

    .text {
        height: 50px;
        line-height: 50px; }

    &.is-secondary .text {
        height: 46px;
        line-height: 46px; }

    @media screen and (min-width: 960px) {
        min-width: 220px; } }

.fui-go-top {
    position: fixed;
    display: block;
    width: 40px;
    height: 40px;
    right: $space * 3;
    bottom: $space * 3;
    background: $darkgray100;
    border-radius: 50%;
    transform: translateY($space * 12);
    transition: all 0.3s ease;
    z-index: 19;
    background: $grayblue url(#{$publicURL}/resources/common/images/go-top.png) center no-repeat;
    cursor: pointer;
    background-size: 30px;

    &.is-show.is-hidden {
        transform: translateY($space * 12) !important; }

    &.is-show:not(.is-hidden) {
        transform: translateY(0);

        + .emma-service {
            transform: translateY(-54px) !important; } }

    &.is-hidden.is-show,
    &.is-hidden {
        + .emma-service,
        + .emma-service.is-open {
            display: none;
            transform: translateY(480px) !important; } }

    @media screen and (min-width: 960px) {
        width: 48px;
        height: 48px;

        &.is-show:not(.is-hidden) + .emma-service {
            transform: translateY(-76px) !important; } } }

.fui-card .fui-card-action:hover .fui-button.is-arrow,
.fui-card .fui-card-action:active .fui-button.is-arrow {
    color: $accent;

    &::after {
        transform: translateX(120%); }

    .text {
        opacity: 1; }

    &.is-reverse {
        color: $white; } }

.related-keyword button.fui-button.is-tag,
.related-keyword a.fui-button.is-tag {
    min-width: 0; }

.button-group {
    .two-buttons {
        .fui-button.is-large {
            margin-bottom: 0;
            padding-right: 24px;
            padding-left: 24px;
            min-width: 220px; } } }

@media screen and (max-width: 960px) {
    .fui-card .fui-card-action:hover .fui-button.is-arrow,
    .fui-card .fui-card-action:active .fui-button.is-arrow,
    .fui-button.is-arrow {
        color: transparent;

        &:active {
            color: transparent; }

        &::after {
            transform: initial !important; }

        .text {
            opacity: 0 !important; }

        &.is-reverse {
            color: transparent; } } }

@media screen and (max-width: 768px) {
    .button-group {
        .two-buttons {
            .fui-button.is-large {
                width: 49%;
                min-width: auto;
                margin-right: 2%;
                display: inline-block;
                padding: 0 $space !important;
                margin-bottom: 16px;

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0; } } } }

    a.fui-button,
    a.fui-button:link,
    a.fui-button:visited,
    button.fui-button,
    .fui-button {
        &.is-sm-block,
        &.is-xs-block {
            width: 100%; } } }

@media screen and (max-width: 480px) {
    .button-group {
        .two-buttons {
            .fui-button.is-large {
                width: 100%;
                margin-bottom: 12px; } } } }
