.fui-form-modal {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    .fui-modal-content {
        background: $white; }

    .fui-modal-content .fui-modal-body {
        background: $lightgray50;
        margin-top: 18px;
        padding-top: 18px;
        min-height: 250px;
        overflow-y: auto;

        > div {
            font-size: 18px;
            font-weight: $font-medium;
            margin-bottom: $space * 3; }

        .captcha {
            margin-top: $space * 4; }

        > form {
            .radio-buttons .radio-group label.radio-button {
                margin-bottom: 0; }

            .form-group {
                margin-bottom: $space * 3;

                > label {
                    font-size: 1rem;
                    margin-bottom: $space * 1.5; }

                .radio-button {
                    margin-bottom: $space * 1.5; } } } }

    @media screen and (max-width: 768px) {
        .fui-modal-content .fui-modal-body {
            max-height: 350px;

            > form {
                .radio-buttons .radio-group label.radio-button {
                    margin-bottom: $space * 3;
                    width: calc(50% - 20px);
                    margin-right: 20px; } } } }

    @media screen and (max-width: 374px) {
        .fui-modal-content .fui-modal-body {
            min-height: 250px;
            max-height: 300px; } } }

.fui-iframe-modal {
    .fui-modal-content {
        iframe {
            width: 100%; } } }

.fui-discount-input {
    position: relative;

    .discount-label {
        position: absolute;
        right: 120px;
        top: 56px;
        border-radius: 20px;
        background: $blue50;
        color: $white;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        padding: 0 ($space * 2); }

    @media screen and (max-width: 768px) {
        .discount-label {
            top: 53px;
            right: 98px; } } }

.fui-notice-modal {
    .fui-modal-content {
        background: $white;

        img {
            max-width: 100%;
            height: auto; }

        .fui-item {
            .prefix .bulleted-dot {
                background: $darkgray50; } }

        table {
            tr td {
                white-space: normal; } }

        .image-content {
            display: flex;
            margin-bottom: 30px;

            .content {
                flex: 1;
                flex-grow: 1;
                margin-left: $space * 3;

                h6 {
                    font-size: 1rem;
                    margin: 0 0 6px; } }

            .image {
                width: 160px; } }

        form {} }

    @media screen and (max-width: 768px) {
        .fui-modal-content {
            .fui-modal-body {
                padding-top: 0;
                margin-top: $space * 1.5;

                ol, ul {
                    padding-left: $space * 2; } }

            .image-content {
                .content {
                    margin-left: $space * 1.5; } }

            table {
                tr td {
                    text-align: left;
                    white-space: normal; } } } } }

.fui-modal-content {
    height: auto;
    max-height: 560px;
    width: 90%;
    max-width: 600px;
    margin: auto;

    > div {
        padding: 30px 0;
        background: $white; }

    .fui-modal-foot {
        text-align: center;
        padding: 30px 0;

        &.two-buttons {
            .fui-button {
                margin: 0 6px;
                min-width: 40%; } }

        .fui-button {
            margin: 0; } }

    .fui-modal-body {
        margin-top: 12px;
        padding: 0 30px 0;
        max-height: 390px;
        overflow-x: hidden;
        overflow-y: auto; }

    .fui-modal-head {
        padding-bottom: 0;
        text-align: center;
        display: inline-block;
        width: 100%;

        h5 {
            margin: 0 0 ($space / 2); } }

    @media screen and (max-width: 768px) {
        .fui-modal-body {
            max-height: 350px; } }

    @media screen and (max-width: 374px) {
        .fui-modal-body {
            min-height: 80px;
            max-height: 300px; } } }

body.ReactModal__Body--open > .ReactModalPortal {
    .ReactModal__Content.fui-notice-modal {
        display: flex;
        align-content: center;
        align-items: center;

        > .fui-modal-content {
            position: relative;
            display: inline-block; } } }

.fui-panel.is-sim-card-feature {
    font-size: 1rem;
    padding-left: 30px;
    padding-right: 30px;

    h2, h4 {
        font-weight: $font-medium; }

    .MuiGrid-root.MuiGrid-container {
        margin: 0 -15px;

        .MuiGrid-root.MuiGrid-item {
            padding: 0 15px; } }

    .fui-sim-card-item {
        display: flex;
        align-content: center;
        align-items: center;

        .image {
            margin-right: $space * 1.5;

            img {
                max-width: 160px; } }

        .content h4 {
            margin: 0; } } }

.fui-number-selector-container {
    display: inline-block;

    + .fui-button {
        margin-left: $space * 1.5; }

    .fui-number-selector {
        display: flex;
        width: 100%;

        > * {
            display: inline-block;
            width: 50px;
            font-size: 1.25rem;
            flex: 1 1 auto;
            text-align: center;
            vertical-align: top;
            border-top: 2px solid $lightgray100;
            border-bottom: 2px solid $lightgray100;

            input {
                border: 0;
                font-size: 1rem;
                width: 50px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                -webkit-appearance: none; } }

        [role='button'] {
            border: 2px solid $lightgray100;
            height: 50px;
            line-height: 50px;
            flex: none;

            &.disabled i {
                opacity: 0.25; } } }

    .is-text-error {
        padding: 6px 0;
        font-size: 0.875rem; }

    @media screen and (max-width: 768px) {
        width: 100%;

        + .fui-button {
            margin: 18px 0;
            width: 100%; } } }

.fui-cart-detail-container {
    .fui-number-selector-container .fui-number-selector {
        > * {
            width: 36px;
            height: 36px;
            line-height: 0;

            &[role='button'] {
                line-height: 36px; }

            input {
                width: 36px;
                height: 32px;
                line-height: 32px; } }

        > div {
            width: 36px;
            height: 36px; } } }

.fui-banner.is-form.is-product-spec {
    height: 240px;

    .fui-container {
        height: 240px;
        margin-top: 0; }

    @media screen and (max-width: 768px) {
        height: 160px;

        .fui-container {
            height: 160px;

            h1 {
                font-size: 28px; } } } }

.fui-product-section,
section.fui-product-section {
    padding-top: 30px;
    background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-m@2x.png);
    background-repeat: no-repeat;
    background-size: 100% auto !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .fui-panel {
        position: relative;

        &.is-product-feature article {
            width: 100%;
            max-width: 900px;
            margin: 0 auto;

            a.fui-item {
                font-size: 18px;

                &::after {
                    display: none !important; } }

            a[href="#"] {
                display: block;
                pointer-events: none; }

            span[style] {
                color: $black50 !important; }

            .table-responsive {
                margin-bottom: $space * 6;
                overflow-x: auto;

                table > tbody {
                    > tr:first-child > td {
                        background: $white !important; }

                    > tr > td:first-child {
                        background: $white !important; } } }

            strong > span {
                color: $black50; }

            h2 {
                font-size: 28px;
                margin-top: $space * 6; }

            img {
                display: block;
                width: 100%;
                max-width: 640px;
                margin: 0 auto; } }

        .fui-replenishment-ribbon {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            color: $white;
            width: 140px;
            height: 124px;
            z-index: 2;

            .text {
                position: relative;
                color: $white;
                font-size: 18px;
                z-index: 1;
                padding: 18px 12px;
                display: block; }

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 0;
                border-left: 70px solid #bfbfbf;
                border-right: 70px solid transparent;
                border-bottom: 62px solid transparent;
                border-top: 62px solid #bfbfbf;
                z-index: 0; }

            @media screen and (max-width: 768px) {
                .text {
                    font-size: 1rem; } } } }

    .fui-panel.is-spec-panel {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 1rem;

        p {
            font-size: 1rem; }

        .fui-item {
            align-items: flex-start;

            img {
                margin-right: 6px; }

            .text {
                font-size: 1rem;
                font-weight: $font-medium; } }

        .spec-group {
            display: flex;
            flex-direction: column; }

        .spec-item {
            display: flex;
            margin-bottom: $space * 1.5;
            line-height: 28px;

            .spec-content {
                flex: 1;
                flex-grow: 1;
                font-weight: $font-light; }

            .spec-title {
                width: 150px;
                font-weight: $font-medium;
                margin-right: 30px; } } }

    h2 {
        margin-bottom: 1.875rem;

        .fui-tag {
            font-size: 0.85rem;
            border: 1px solid $black50;
            padding: 0 6px;
            line-height: 21px;
            border-radius: 3px;
            margin-right: 6px;
            vertical-align: middle;
            font-weight: $font-regular; } }

    .slick-slider.fui-product-slider {
        margin-top: 30px;

        &.is-replenishment {
            opacity: 0.4; }

        .slick-arrow {
            position: absolute;
            width: 32px;
            height: 32px;
            top: 0;
            bottom: 0;
            margin: auto;
            color: transparent;
            border: 0;
            background: none;
            z-index: 9;

            &.slick-disabled {
                opacity: 0.25; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 32px;
                height: 32px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 32px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1; }

            &.slick-prev {
                left: 0px;

                &::after {
                    content: ""; } }

            &.slick-next {
                right: 0px;

                &::after {
                    content: ""; } } }

        ul.slick-dots {
            display: block;
            text-align: center;
            list-style: none;
            margin: ($space * 3) 0 0;
            padding: 0;

            li {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 4px;
                vertical-align: top;
                background: $black50;
                opacity: 0.5;
                margin: 0 3px;

                &.slick-active {
                    opacity: 1; }

                button {
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0; } } }

        .slick-list .slick-track {
            .slick-slide {
                text-align: center;

                img {
                    display: block;
                    width: 100%;
                    max-width: 360px;
                    height: auto;
                    margin: 0 auto; } } } }

    .price-section {
        margin-bottom: 18px;

        h4 {
            margin: 3px 0 ($space * 1.5);
            color: $error;

            del {
                color: $gray100; } } }

    .form-group {
        margin-bottom: 30px;

        label {
            font-size: 16px;
            margin-bottom: 0; } }

    .fui-discount-bar {
        display: flex;
        font-size: 0.875rem;
        flex-direction: column;

        .discount-ribbon {
            display: block;
            background: $error;
            color: $white;

            &::after {
                border-top: 7px solid $error;
                border-left: 7px solid $error; }

            &::before {
                border-bottom: 7px solid $error;
                border-left: 7px solid $error; } }

        > a.fui-discount-link:link,
        > a.fui-discount-link:visited {
            justify-content: space-between;
            display: flex;
            color: $darkgreen;
            align-content: center;
            align-items: center;
            width: 100%;

            &:hover * {
                color: $error;

                i[class*='icon-'] {
                    color: $error; } }

            &::after {
                display: none !important; }

            > span {
                display: flex;
                align-content: center;
                align-items: center; }

            > span.content {
                display: block;
                flex: 1 1 auto;
                margin-right: 18px; }

            i[class*='icon-'] {
                color: $black50;
                font-size: 1.5rem; } }

        .discount-ribbon {
            position: relative;
            width: 72px;
            line-height: 28px;
            height: 28px;
            color: $white;
            background: #95a3a4;
            padding-left: 6px;
            margin-bottom: 6px;
            display: inline-block;

            &::before,
            &::after {
                content: "";
                position: absolute;
                right: -14px;
                width: 0;
                height: 0; }

            &::before {
                top: 0;
                border-top: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #95a3a4;
                border-left: 7px solid #95a3a4; }

            &::after {
                bottom: 0;
                border-top: 7px solid #95a3a4;
                border-left: 7px solid #95a3a4;
                border-right: 7px solid transparent;
                border-bottom: 7px solid transparent; } } }

    .fui-notice {
        margin-top: $space * 1.5;

        .fui-item {
            display: block;
            padding: 4px 0;
            font-size: 0.875rem;

            .more,
            a:not(.sub-link-title):not(.fui-button):link,
            a:not(.sub-link-title):not(.fui-button):visited {
                position: relative;
                font-weight: $font-medium;
                color: $black50;
                margin-left: 6px;
                white-space: nowrap;
                display: inline-block;

                &:hover {
                    color: $error;

                    &::after {
                        background: $error !important; } }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: $black50 !important;
                    bottom: -2px;
                    left: 0; } } } }

    .radio-group {
        margin: 0 -6px;

        .radio.is-icon-box {
            width: calc(50% - 12px);
            margin: 6px 6px 0;

            @media screen and (max-width: 768px) {
                .content::before {
                    left: 6px;
                    bottom: auto;
                    top: 6px;
                    width: 24px;
                    height: 24px; }

                .content::after {
                    left: 14px;
                    top: 14px;
                    bottom: auto; }

                input:checked + .content:after {
                    width: 8px;
                    height: 8px; } } } }

    .fui-color-selector-container + .fui-discount-bar {
        margin-top: $space * 9; }

    .fui-color-selector-container .fui-color-selector-items {
        margin: 0.875rem 0 ($space * 2.875);

        label.fui-color-selector-item {
            position: relative;
            display: inline-block;
            vertical-align: top;
            margin-bottom: $space * 1.5;

            input {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;

                &:checked + .color-item {
                    box-shadow: 0 0 0 2px $error; } } }

        .color-item {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: solid 4px $white;
            box-shadow: 0 0 0 2px rgba(47, 89, 196, 0.2);
            margin-right: $space * 3.75; } }

    @media screen and (max-width: 768px) {
        .fui-panel.is-spec-panel {
            padding: 30px 20px;

            p,
            .fui-item .text {
                font-size: 1rem; }

            .spec-group {
                h2 {
                    margin-bottom: $space * 3; } }

            .spec-item {
                font-size: 1rem;
                flex-direction: column;
                margin-bottom: $space * 3; } }

        .fui-product-action .fui-button {
            width: 100%; }

        .subtitle.down-more {
            font-size: 1rem; }

        .fui-discount-bar {
            > a.fui-discount-link:link,
            > a.fui-discount-link:visited {
                span.content {
                    margin-right: 6px; } } }

        .fui-discount-bar .discount-ribbon {
            height: 24px;
            line-height: 24px;
            margin-right: 16px;

            &::before,
            &::after {
                right: -12px; }

            &::before {
                border-top: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid #95a3a4;
                border-left: 6px solid #95a3a4; }

            &::after {
                border-top: 6px solid #95a3a4;
                border-left: 6px solid #95a3a4;
                border-right: 6px solid transparent;
                border-bottom: 6px solid transparent; } }

        .slick-slider.fui-product-slider {
            margin-top: 10px;

            .slick-list .slick-track {
                .slick-slide {
                    img {
                        max-width: 170px; } } } } }

    @media screen and (min-width: 960px) {
        padding-top: 90px;
        background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);

        h2 .fui-tag {
            margin-right: 6px;
            font-size: 0.875rem; }

        .MuiGrid-root.MuiGrid-container {
            width: calc(100% + 60px);
            margin: 0 -30px;

            .MuiGrid-root.MuiGrid-item {
                padding: 0 30px; } }

        .slick-slider.fui-product-slider {
            ul.slick-dots {
                li {
                    width: 50px; } }

            .slick-list .slick-track {
                .slick-slide {
                    img {
                        max-width: 360px; } } } } } }

.fui-nav-tab {
    z-index: 13 !important; }

.fui-tab-pane.is-active {
    position: relative;
    z-index: 0; }
