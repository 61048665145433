.fui-cart-bar {
    position: fixed;
    background: $white;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 99;
    transform: translateY(0px);
    transition: transform 0.3s ease;

    &.is-hidden {
        bottom: -360px;
        transform: translateY(0px) !important; }

    .fui-cart-count-list {
        color: $blue50;
        display: block;
        text-align: right;
        margin-top: $space * 3;

        .count-item {
            display: flex;
            line-height: 24px;
            font-weight: $font-medium;

            > div:not(.price) {
                flex: 1 1 auto; }

            &.amount {
                color: $black50;

                .price {
                    color: $accent; } }

            .price {
                width: 95px; } } }

    .fui-cart-preview {
        position: relative;
        padding: 15px 0;
        height: 80px;
        box-shadow: 0 -18px 36px 0 rgba(75, 88, 105, 0.24);
        z-index: 1; }

    .fui-cart-detail-container {
        position: absolute;
        left: 0;
        bottom: 80px;
        height: 0;
        width: 100%;
        opacity: 0;
        background-color: rgba(32, 32, 32, 0.3);
        z-index: 0;

        .close {
            position: absolute;
            right: 0;
            top: 36px;
            font-size: 1.5rem;
            z-index: 3;
            line-height: 0;
            opacity: 1; }

        .fui-cart-detail {
            position: absolute;
            width: 100%;
            height: calc(100% - 36px);
            bottom: 0;
            background: $white;

            .fui-cart-detail-content {
                padding-top: 36px;
                transition: height 0.3s ease;
                overflow-x: hidden;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;

                h2 {
                    height: 108px; }

                h1 .fui-tooltip-container {
                    button {
                        margin-top: -5px; }

                    .icon-information {
                        color: $darkgray70;
                        font-size: 30px !important; } } }

            .phone-number-price img {
                vertical-align: middle;
                margin-right: 6px; }

            table {
                width: 100%;

                &.is-cart-list {
                    border-bottom: 2px solid $lightgray100; }

                thead tr {
                    border-bottom: 2px solid $lightgray100;

                    th {
                        color: $gray100;
                        font-size: 1rem; } }

                thead,
                tbody tr {
                    background: none;

                    td.delete {
                        .is-api-loading {
                            min-height: 30px; }

                        .is-api-loading::before {
                            width: 30px;
                            height: 30px;
                            background-size: 30px;
                            background-color: rgba(255, 255, 255, 0.9); } }

                    &.is-deleting > td {
                        opacity: 0.4;

                        .is-api-loading {
                            min-height: 30px;

                            &::before {
                                background-size: 30px; }

                            img {
                                display: none; } } }

                    td {
                        color: $black50;
                        font-weight: $font-medium;
                        vertical-align: top;
                        white-space: normal;

                        del {
                            color: $gray100;
                            margin-right: 3px; } } }

                tr > th,
                tr > td {
                    font-size: 1rem;
                    text-align: left;
                    border: 0;

                    del {
                        color: #8a8a8a; } } } } }

    &.is-open {
        bottom: 0 !important;

        .fui-cart-preview .fui-cart-amount {
            [role="button"] > i {
                transform: rotate(180deg); } }

        .fui-cart-detail-container {
            height: calc(100vh - 80px);
            animation: fadeIn 0.3s ease forwards;

            .fui-cart-detail-content {
                height: calc(100vh - 120px);
                padding-top: $space * 4.5;
                padding-bottom: $space * 4.5; } } }

    .fui-cart-amount {
        display: flex;

        [role='button'] {
            display: flex;
            align-content: center;
            align-items: center;
            margin-left: 6px;

            h2 {
                margin: 0;
                line-height: 1;
                margin-right: $space * 3; }

            i {
                font-size: 1.5rem; } } }

    .fui-cart-container {
        display: flex;
        align-items: center;
        align-content: center;

        .column {
            display: flex;

            .fui-button {
                margin: 0 0 0 ($space * 3); }

            &:first-child {
                flex: 1 1 auto;
                text-align: right;
                justify-content: space-between;
                align-content: center;
                align-items: center; } }

        .fui-cart-list-preview {
            span.number {
                display: inline-block;
                width: 28px;
                height: 28px;
                background: #5f6e84;
                color: $white;
                text-align: center;
                line-height: 28px;
                border-radius: 50%; } }

        .fui-steps {
            display: flex;
            align-items: center;
            align-content: center;

            .fui-step-item {
                position: relative;
                color: $white;
                background: $gray50;
                display: inline-block;
                width: auto;
                height: 28px;
                line-height: 0px;
                padding: 0 8px;
                border-radius: 50px;
                margin-right: 18px;
                text-align: center;

                &:last-child {
                    margin: 0; }

                &:last-child::after {
                    display: none; }

                .text {
                    line-height: 28px;
                    vertical-align: middle; }

                &::after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 1px;
                    background: $gray50;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: -18px; }

                &.is-done {
                    .text {
                        opacity: 0; }

                    .icon {
                        position: absolute;
                        opacity: 1;
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                        top: 0;
                        left: 0;
                        right: 0;
                        text-align: center;
                        margin: auto; }

                    &[role='button'] {
                        cursor: pointer; }

                    &[role='button']:hover {
                        background: $darkgray50;

                        .text {
                            opacity: 1; }

                        .icon {
                            opacity: 0; } } }

                &.is-active.is-done {
                    .text {
                        opacity: 1; }

                    .icon {
                        opacity: 0; } }

                &.is-active {
                    background: $darkgray50;

                    .text {
                        opacity: 1; } } } } }

    @media screen and (max-width: 768px) {
        &.is-hidden {
            transform: translateY(260px); }

        &.is-open .fui-cart-detail-container {
            .fui-cart-detail-content {
                height: calc(100vh - 190px); }

            .fui-cart-detail {
                padding-top: 72px;

                .fui-container {
                    position: relative; }

                .close {
                    top: 20px;
                    right: 15px;
                    width: 36px;
                    height: 36px;
                    background: $lightgray70;
                    line-height: 0;
                    border-radius: 18px;
                    text-align: center;

                    i {
                        line-height: 36px;
                        font-size: 24px; } }

                h2 {
                    font-size: 24px;
                    line-height: 1.35; }

                h5 {
                    font-size: 1rem; }

                table {
                    display: block;
                    padding-bottom: $space * 3;
                    border-bottom: 2px solid $lightgray100;

                    &.is-cart-list {
                        tr {
                            padding-top: 8px;
                            border-top: 2px solid $lightgray100; } }

                    thead {
                        display: none; }

                    tbody {
                        display: block;

                        tr {
                            position: relative;
                            display: block;
                            width: 100%;
                            margin-top: $space * 3;

                            &[data-row] {
                                margin-top: $space * 6;

                                .label {
                                    margin-top: -$space * 6; } }

                            > td {
                                display: block;
                                height: auto;
                                padding-right: 140px;
                                width: 100%;
                                padding-left: 0;

                                .fui-number-selector-container {
                                    width: 100px; }

                                &.align-center {
                                    text-align: left !important; }

                                &.number,
                                &.color {
                                    line-height: 1.2; }

                                &.label {
                                    color: $gray100;
                                    border-top: 2px solid $lightgray100;
                                    border-bottom: 2px solid $lightgray100; }

                                &.delete {
                                    position: absolute;
                                    right: 0;
                                    top: 10px;
                                    padding: 0;
                                    width: 50px;
                                    height: 50px;
                                    text-align: center;

                                    [role='button'] {
                                        position: relative;
                                        width: 30px;
                                        height: 30px; }

                                    .is-api-loading::before {
                                        width: 30px;
                                        height: 30px;
                                        background-size: 30px;
                                        background-color: rgba(255, 255, 255, 0.9); } }

                                &.align-right {
                                    text-align: left !important;
                                    padding: 0; } } } } } } }

        .fui-cart-preview {
            padding: $space 0; }

        .fui-cart-container {
            // flex-direction: column
            align-items: center;
            height: 64px;

            .column {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
                justify-content: center;
                height: 64px;

                &:first-child {
                    align-items: flex-start;
                    justify-content: center; }

                .fui-button.is-primary {
                    padding: 0 20px;
                    margin-left: $space * 2; }

                .fui-cart-amount {
                    align-items: center;
                    width: 100%;

                    > div {
                        font-size: 14px;
                        white-space: nowrap; }

                    div[role="button"] {
                        flex: 1;
                        flex-grow: 1; }

                    h2.price {
                        flex: 1;
                        flex-grow: 1;
                        font-size: 22px;
                        text-align: left;
                        margin-right: $space * 2; } } }

            .fui-steps {
                display: flex;
                white-space: nowrap;

                .fui-step-item {
                    padding: 0;
                    width: 12px;
                    height: 12px;
                    margin-right: 6px;
                    vertical-align: top;
                    line-height: 0;

                    &:after {
                        display: none; }

                    .text {
                        line-height: 21px;
                        font-size: 12px;
                        opacity: 0; }

                    &.is-done {
                        width: 18px;
                        height: 18px;

                        .icon {
                            width: 18px;
                            height: 18px;
                            line-height: 18px;

                            i {
                                font-size: 12px; } } }

                    &.is-active {
                        width: auto;
                        color: $white;
                        height: 21px;
                        line-height: 0px;
                        padding: 0 6px;
                        opacity: 1; } } } } }

    @media screen and (max-width: 374px) {
        .fui-cart-container .column .fui-button {
            min-width: 0; } } }
