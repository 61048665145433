.fui-record-panel {
    background: $white;
    color: $darkgray50;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
    margin-bottom: $space * 1.5;

    .collapse-head {
        padding: 60px 30px ($space * 5);
        font-size: 1.125rem;

        .is-underline-link:link {
            font-size: 1.125rem; }

        .product-info {
            display: flex;

            .image {
                min-width: 150px;
                width: 150px;
                height: 150px;
                margin-right: $space * 3;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                img {
                    max-width: 100%;
                    height: auto; } }

            .content {
                color: $black50;

                h3, h5 {
                    margin: 0 0 ($space * 1.5); } } }

        .order-steps {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: $space * 3;

            &::before {
                content: "";
                position: absolute;
                width: calc(100% - 56px);
                height: 2px;
                top: 15px;
                background: $gray50;
                left: 0;
                right: 0;
                margin: auto; }

            .order-step {
                position: relative;
                width: $space * 7;
                color: $gray70;
                text-align: center;
                padding-top: $space * 5;

                &.is-active {
                    .step-dot::before {
                        width: 30px;
                        height: 30px;
                        background: $darkgray50; }

                    .step {
                        color: $darkgray50;
                        font-weight: $font-medium; } }

                .step-dot {
                    position: absolute;
                    width: 100%;
                    height: 30px;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        background: $gray70;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        border-radius: 50%; } }

                .step {
                    width: $space * 7;
                    color: $gray70;
                    margin: 0 auto; } } } }

    .collapse-body {
        overflow: hidden;
        font-size: 1rem;
        transition: height 0.3s ease;

        .collapse-content {
            padding: 30px;

            h4 {
                margin-top: 30px; }

            table {
                tr td,
                tr th {
                    font-size: 1rem; }

                tr td {
                    text-align: left;
                    padding: 6px 12px; }

                form {
                    margin-top: $space * 1.5;

                    .text-input {
                        width: 100%;
                        margin-bottom: 0; } }

                .action {
                    float: right; }

                .fui-button {
                    margin: 0;

                    .text {
                        font-size: 0.875rem; } }

                label.fui-button {
                    input {
                        display: none; }

                    .text {
                        font-size: 0.875rem;
                        line-height: 26px; } }

                .promotion-list {
                    display: block;
                    align-items: center;
                    font-size: 0.875rem;
                    margin-top: 6px;

                    .text {
                        font-size: 0.875rem; }

                    .label {
                        position: relative;
                        display: inline-block;
                        background: #95a3a4;
                        color: $white;
                        height: 28px;
                        line-height: 28px;
                        padding: 0 6px;
                        margin-right: 20px;

                        &::before,
                        &::after {
                            right: -14px;
                            content: "";
                            position: absolute;
                            border-left: 7px solid #95a3a4;
                            border-right: 7px solid transparent; }

                        &::before {
                            border-top: 7px solid #95a3a4;
                            border-bottom: 7px solid transparent;
                            bottom: 0; }

                        &::after {
                            border-bottom: 7px solid #95a3a4;
                            border-top: 7px solid transparent;
                            top: 0; } } } } } }

    .collapse-trigger {
        background: $lightgray50;
        color: $darkgray50;
        height: 52px;
        line-height: 52px;
        text-align: center;
        font-weight: $font-medium;

        * {
            vertical-align: middle; }

        i[class*='icon-'] {
            font-size: 30px; } }

    @media screen and (max-width: 768px) {
        .collapse-head {
            padding: 30px 20px;
            font-size: 0.875rem;

            .MuiGrid-root.MuiGrid-item {
                text-align: left !important;
                padding-top: 0;
                padding-bottom: 0; }

            .product-info {
                margin-bottom: $space * 1.5;

                .image {
                    min-width: 100px;
                    width: 100px;
                    height: 100px;
                    margin-right: 6px; }

                .content {
                    h3, h5 {
                        margin: 0; }

                    h3 {
                        font-size: 20px;
                        line-height: 30px; } } }

            .is-underline-link:link {
                font-size: 0.875rem; }

            .order-steps {
                flex-direction: column;
                justify-content: flex-start;

                &::before {
                    display: none; }

                .order-step {
                    padding: 0;
                    display: flex;
                    width: 100%;
                    height: 28px;
                    align-content: flex-start;
                    justify-content: flex-start;
                    text-align: left;
                    margin-bottom: 18px;

                    &::before {
                        content: "";
                        top: 14px;
                        position: absolute;
                        width: 2px;
                        height: 46px;
                        left: 8px;
                        background: $gray70;
                        z-index: 0; }

                    .step-dot {
                        position: relative;
                        width: 18px;
                        height: 18px;
                        margin-left: 0;
                        z-index: 1;

                        &::before {
                            width: 9px;
                            height: 9px; } }

                    &:last-child::before {
                        display: none; }

                    &.is-active {
                        .step-dot::before {
                            width: 18px;
                            height: 18px; } }

                    .step {
                        margin-left: 15px;
                        flex-grow: 1;
                        height: 28px;
                        line-height: 28px;
                        font-size: 1rem; } } } }

        .collapse-body {
            font-size: 0.875rem;

            .collapse-content {
                padding: 0 20px 20px;

                h4 {
                    margin: 18px 0; }

                table {
                    width: 100%;

                    tr th,
                    tr td {
                        font-size: 0.875rem; }

                    tr td {
                        white-space: normal; }

                    .is-underline-link.is-text-underline {
                        font-size: 0.875rem; }

                    .action {
                        float: none;

                        .fui-button {
                            height: 24px;

                            .text {
                                font-size: 12px;
                                line-height: 20px; } } } } } } } }
