form.has-compare {
    position: relative;
    margin-top: -42.5px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: $black50;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s ease; }

    &.is-compare {
        &::before {
            opacity: 0.3;
            z-index: 2; }

        .fui-plan-flow-container {
            padding-bottom: 0; } }

    section.fui-plan-flow {
        background: transparent;

        &.is-compare {
            z-index: 11;
            background: $white;
            padding-bottom: 120px; } }

    @media screen and (max-width: 960px) {
        margin-top: 0; }

    @media screen and (max-width: 768px) {
        section.fui-plan-flow.is-compare {
            padding-bottom: 60px; } } }

.fui-prompt-modal {
    text-align: center; }

.form-group {
    label {
        display: block; }

    .fui-tooltip-container {
        float: right;

        .fui-button i {
            color: $black50; } }

    &.is-identity-selector {
        .radio-group {
            .radio input + .content,
            .radio input:checked + .content {
                height: auto;
                color: $grayblue;
                text-align: center;
                align-items: center;
                padding-left: ($space * 2) !important;
                padding-top: $space * 3;
                padding-bottom: $space * 3;

                &:before {
                    width: 24px;
                    height: 24px; }

                &:before {
                    left: 12px;
                    top: 12px;
                    bottom: auto; }

                &:after {
                    left: 19px;
                    top: 19px;
                    bottom: auto; }

                .icon {
                    height: 42px; }

                .description {
                    display: block;
                    font-size: 1rem; }

                .text {
                    font-size: 1.375rem;
                    color: $black50;
                    margin-top: $space * 0.8;
                    margin-bottom: $space * 1.5; } } }

        @media screen and (min-width: 960px) {
            .radio-group {
                .radio {
                    width: calc(50% - 12px);

                    input + .content,
                    input:checked + .content {
                        &:before {
                            width: 30px;
                            height: 30px; }

                        &:after {
                            left: 22px;
                            top: 22px; } } } } } } }

.fui-order-result {
    text-align: center;

    img {
        margin: 0 auto;
        width: 220px;
        height: auto; }

    h4 {
        margin: ($space * 1.5) 0; } }

.fui-banner.is-form h1 {
    font-weight: $font-medium; }

.fui-order-container {
    position: relative;
    z-index: 2;

    img.identity-feature {
        width: 175px;
        display: block;
        margin: 0 auto; }

    .fui-collapse {
        .collapse-header {
            font-size: 1rem; }

        .collapse-body article {
            font-size: 0.875rem; }

        ul, ol {
            padding-left: $space * 2; } }

    &.ribbon-bg {
        .price {
            color: $black50; } }

    .text-input {
        margin: 0; }

    .text-input,
    .text-input input {
        width: 100%; }

    .paper.is-promotion {
        padding: 30px; }

    .fui-card.is-horizontal-promotion {
        position: relative;

        .fui-card-action {
            flex-direction: row;

            .fui-card-image {
                width: 480px;
                min-height: 0;
                padding-bottom: 0;

                img {
                    position: relative;
                    left: 0;
                    top: 0;
                    right: auto;
                    bottom: auto; } }

            .fui-card-content {
                flex: 1;
                flex-grow: 1;
                background-image: url(#{$publicURL}/resources/cbu/order/bg-panel-ad-apply-gifts@2x.png);
                color: $white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                h3 {
                    margin: 0 0 $space; }

                .subtitle {
                    margin-bottom: $space * 3; } }

            @media screen and (max-width: 768px) {
                // .fui-card-image
                .fui-card-content {
                    position: absolute;
                    background: none;
                    width: 100%;
                    height: 100%;
                    padding: 30px;

                    .fui-button {
                        width: 100%;
                        margin: 0; } } } } }

    .fui-item .prefix,
    a.fui-item .prefix,
    a.fui-item:link .prefix,
    a.fui-item:visited .prefix {
        .icon-check {
            color: $accent; } }

    .fui-card.is-promotion {
        .fui-card-action {
            background-image: url(#{$publicURL}/resources/cbu/img/subtitle-title-content-copy-2@2x.png) !important;
            background-position: left top;
            background-size: auto 100%; }

        .fui-card-content {
            padding: ($space * 5) 60px ($space * 5);

            .fui-button {
                color: $accent !important;
                width: auto; } }

        @media screen and (max-width: 768px) {
            .fui-card-content {
                padding: ($space * 6) 30px;

                .fui-button {
                    width: 100%;
                    margin: 0; } } } }

    .form-group > label.checkbox {
        margin-top: 0; }

    .checkbox input + .content {
        font-size: 1rem;

        [role="button"].is-text-underline {
            text-decoration: underline;

            &:hover {
                color: $accent !important; } }

        .fui-item .content {
            font-weight: $font-regular;
            color: $darkgray50; } }

    .fui-happygo-notice {
        background: #EFF1F2;
        padding: 14px 18px;

        @media screen and (max-width: 768px) {
            b {
                display: block; } } }

    .fui-collapse {
        background: #FAFAFA;
        color: $darkgray50;
        margin-bottom: 30px;

        .collapse-body {
            font-size: 0.875rem !important;
            padding: 0 30px;

            article {
                padding-top: 0; }

            ul {
                padding-left: 20px;
                margin: 0;

                li {
                    margin: $space 0; } } }

        .collapse-header {
            padding: 30px;
            margin-right: 30px;
            margin-bottom: 0; } }

    .input-group {
        display: flex;

        .fui-input {
            flex: 1;
            flex-grow: 1;
            margin-right: 6px; }

        .action .fui-button {
            min-width: 0;
            margin: 0;
            height: 50px;
            min-width: 100px;

            .text {
                height: 46px;
                line-height: 46px; } }

        @media screen and (max-width: 768px) {
            .action .fui-button {
                min-width: 76px; } } }

    h4.is-text-darkgray50 > a,
    h4.is-text-darkgray50 > a:link,
    h4.is-text-darkgray50 > a.outer-link,
    h4.is-text-darkgray50 > a.outer-link:link {
        color: $accent !important;
        vertical-align: baseline;

        img {
            display: inline-block;
            vertical-align: middle; } }

    .fui-select .fui-dropdown.with-arrow {
        margin-left: 0; }

    .paper.is-paper-collapse {
        margin-bottom: $space * 1.5;

        &.is-collapsed {
            padding-top: 30px;
            padding-bottom: 30px;

            .collapse-header .check,
            .collapse-header .action {
                display: inline-block; }

            .collapse-body {
                display: none; } }

        .collapse-header {
            display: flex;

            h4 {
                margin: 0; }

            .fui-button {
                min-width: 0;
                margin: 0; }

            .action {
                display: none; }

            h4 {
                flex: 1;
                flex-grow: 1;

                span.check {
                    vertical-align: middle;
                    display: none;
                    border-radius: 50%;
                    margin-left: 6px;
                    width: 30px;
                    line-height: 26px;
                    font-size: 16px;
                    height: 30px;
                    color: $white;
                    background: $blue50;
                    text-align: center;

                    i {
                        vertical-align: middle; } } } } }

    .fui-item.is-text-darkgray50 span.prefix i.bulleted-dot {
        background: $darkgray50; }

    .fui-upload-container {
        margin-top: $space * 1.5; }

    .is-underline-link {
        font-size: 0.875rem; }

    h4.subtitle {
        font-size: 1.5rem;
        font-weight: $font-regular;
        color: $darkgray50;
        margin: ($space * 8) 0 ($space * 1.5);

        &:first-child {
            margin-top: 30px; }

        ~ .form-group {
            margin-top: $space * 3;
            margin-bottom: $space * 4.5;

            label.radio-button {
                margin-bottom: 0; } }

        a, a:link {
            position: relative;
            color: $black50;
            font-size: 1rem;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background: $black50; } }

        @media screen and (max-width: 768px) {
            a, a:link {
                font-size: 0.875rem; }

            ~ .form-group label.radio-button {
                margin-bottom: $space * 1.5; } } }

    &.section-upload {
        .fui-item .prefix i.bulleted-dot {
            background: $black50; }

        .fui-item.is-text-grayblue .prefix i.bulleted-dot {
            background: $grayblue; } }

    .fui-upload-more {
        width: 100%;
        height: 285px;
        text-align: center;
        display: flex;
        border: dashed 4px rgba(149, 163, 164, 0.4);
        align-content: center;
        align-items: center;
        justify-content: center;
        font-weight: $font-medium;

        i {
            margin-left: $space * 0.875;
            font-size: 1.5rem; }

        @media screen and (max-width: 768px) {
            height: 140px; } }

    .form-group.with-agree {
        margin: 0;

        .checkbox.is-agreement {
            position: relative;
            margin: 0;

            > .content {
                width: 100%;
                font-size: 0.875rem;
                padding-left: 42px;
                font-weight: $font-regular;

                &::before {
                    top: 8px; }

                &::after {
                    top: 12px; }

                &::before,
                &::after {
                    position: absolute; }

                > div {
                    // margin-left: $space * 6

                    .fui-list {
                        margin-top: $space * 1.5; }

                    .fui-item {
                        padding: 0 0;

                        i.bulleted-dot {
                            background: $darkgray50; }

                        .content {
                            font-size: 0.875rem;
                            font-size: $darkgray50; } } }

                .is-text-underline {
                    position: relative;
                    font-weight: $font-bold;
                    color: $black50; }

                &::after {
                    margin-left: 0; } } } }

    .paper {
        padding-top: 60px;
        padding-bottom: 60px;

        .fui-item a.is-text-underline {
            text-decoration: underline !important; }

        table {
            margin-bottom: 30px;

            tr th,
            tr td {
                font-size: 1rem !important; } }

        @media screen and (max-width: 768px) {
            table {
                tr td {
                    text-align: left; } } }

        + .with-agree.single-check {
            margin: 30px 0 60px; }

        .fui-order-item {
            display: flex;
            align-items: center;
            align-content: center;
            margin-top: $space * 1.5;

            > div {
                margin-left: 30px;

                &:not(.del) {
                    min-width: 100px; } }

            .pname {
                flex: 1;
                flex-grow: 1;
                margin-left: 0px;
                color: $black50;
                font-size: 1rem;
                font-weight: $font-medium; }

            .numberic {
                min-width: 110px !important;
                text-align: center;

                .fui-number-selector-container {
                    .fui-number-selector {
                        > div {
                            position: relative;
                            background: $white;
                            border-color: $lightgray100;
                            line-height: 36px;
                            width: 36px;
                            height: 36px;
                            overflow: hidden;

                            input {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                margin-left: auto;
                                margin-right: auto;
                                width: 36px;
                                height: 34px;
                                line-height: 34px;
                                padding: 0px 4px; } } } } }

            .price {
                width: 130px;
                text-align: right;

                del {
                    margin-right: 3px;
                    color: $gray100; }

                span {
                    font-weight: $font-medium; } }

            .del {
                width: 35px; }

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                align-content: flex-start;
                position: relative;

                + .fui-order-item {
                    margin-top: $space * 3; }

                > div {
                    margin-left: 0;
                    text-align: left !important;

                    &.del {
                        position: absolute;
                        right: 0;
                        top: -4px;
                        margin: 0;
                        width: 24px;

                        > div {
                            width: 24px;
                            height: 24px;
                            margin: 0; }

                        img {
                            width: 24px; } }

                    &.pname {
                        margin-right: 30px; }

                    &.pname ~ div {
                        margin-top: 3px !important; }

                    &.price span {
                        font-weight: $font-medium; } } } }

        .fui-collapse.with-trigger {
            border: 0;
            background: none;
            margin-top: $space * 3.75;
            margin-left: -30px;
            margin-right: -30px;
            margin-bottom: -60px;

            .collapse-body {
                box-sizing: content-box;
                padding: 0 ($space * 3.75);
                border: 0;
                box-shadow: none;
                font-size: 1rem !important;

                h4 {
                    font-weight: $font-regular;
                    margin: ($space * 3) 0 ($space * 3); }

                .gray-block {
                    margin-top: $space * 1.5; }

                .fui-order-item {
                    display: flex;

                    &:first-child {
                        margin-top: 0; }

                    .pname {
                        flex: 1;
                        flex-grow: 1; }

                    .action {
                        .fui-button {
                            min-width: 0;
                            margin: 0; } } }

                > div {
                    padding-bottom: $space * 3.75; }

                h4 {
                    color: $darkgray50; }

                table {
                    tr td {
                        white-space: normal; } }

                @media screen and (max-width: 768px) {
                    .fui-order-item {
                        flex-direction: column;
                        align-items: flex-start;
                        align-content: flex-start;
                        margin-top: $space * 3;

                        > div {
                            margin-left: 0; }

                        .numberic,
                        .price {
                            text-align: left; }

                        > .action .fui-button {
                            margin-top: $space * 1.5; } } } }

            .collapse-trigger {
                background: $lightgray50;
                text-align: center;
                height: 52px;
                line-height: 52px;
                font-weight: $font-medium;
                cursor: pointer;

                * {
                    vertical-align: middle; }

                [class*='icon-'] {
                    font-size: 30px; } } }

        .gray-block + .gray-block {
            margin-top: $space * 1.5; } }

    .fui-order-count {
        display: flex;
        margin: 30px 0 0;
        width: 100%;
        align-items: flex-end;
        flex-direction: column;

        .fui-order-count-item {
            width: 330px;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: $space * 1.5;

            &:last-child {
                margin-bottom: 0; }

            .label {
                font-weight: $font-medium; }

            .price {
                align-self: flex-end;
                display: flex;
                font-weight: $font-medium;

                h1 {
                    margin: 0 !important;
                    line-height: 1; } } }

        @media screen and (min-width: 768px) {
            padding: 0 30px;

            &.with-trashcan {
                padding: 0 90px; } } }

    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
        .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-6 {
            padding-top: 0;
            padding-bottom: 0; } }

    .form-group {
        .text-input {
            min-width: 0; }

        .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-6 {
            padding-top: $space;
            padding-bottom: $space; }

        @media screen and (max-width: 768px) {
            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-6 {
                padding-top: 6px;
                padding-bottom: 6px; } } }

    .MuiGrid-root.MuiGrid-container {
        .MuiGrid-root.MuiGrid-item {
            .gray-block {
                height: 100%; } } }

    .gray-block {
        padding: $space * 3;
        background: #EFF1F2;
        margin-top: 18px;

        h1 {
            font-weight: $font-medium; }

        h4, h1 {
            margin: 0; }

        h4 {
            margin-bottom: $space * 1.5;
            font-weight: $font-regular;
            color: $darkgray50;
            line-height: 1.3; }

        @media screen and (max-width: 768px) {
            padding: $space * 2.125;
            margin-top: $space * 1.5;

            h1 {
                font-size: 28px; } } }

    @media screen and (max-width: 768px) {
        padding-top: 30px;

        .paper table tr td {
            text-align: left;
            white-space: normal; }

        .form-group {
            > label {
                font-size: 18px; }

            .radio-buttons .radio-group .radio-button:last-child {
                margin-bottom: 0; } }

        .paper {
            padding-top: 30px;
            padding-bottom: 30px;

            .fui-select2 {
                margin-bottom: $space * 1.5; }

            .fui-collapse.with-trigger {
                margin-bottom: -30px;
                margin-left: -15px;
                margin-right: -15px;

                .collapse-body {
                    padding: 0 15px; } } } } }
