.fui-segments {
    background: $white;
    box-shadow: 0 6px 12px 0 #{"rgba(75, 88, 105, 0.12)"};

    .fui-segment:not(:last-of-type) {
        border-bottom: 1px solid $lightgray100; }

    .fui-segment {
        h5 {
            font-weight: $font-regular; }

        .fui-collapse {
            background: none;

            .collapse-body {
                padding-left: 0;
                padding-right: 0; } } }

    .rate-plan-box {
        background: $lightgray70;
        padding: 18px;

        h1, h2 {
            margin: 0; }

        h4, h5 {
            margin-top: 0;
            margin-bottom: 0;
            color: $darkgray50; }

        @media screen and (min-width: 960px) {
            padding: 30px;

            .rate-plan-box {
                padding: 24px 18px; } } }

    .fui-collapse {
        color: $darkgray50;
        background: $lightgray50;
        font-size: 0.875rem;

        .collapse-header {
            font-size: 1rem;
            padding: 30px 30px;

            &::before,
            &::after {
                right: 30px; } }

        .collapse-body {
            padding: 0 30px;

            > table tbody tr td {
                background: $white; }

            ol {
                padding-left: 20px;
                margin-top: 0; } }

        @media screen and (min-width: 960px) {
            .fui-collapse {
                padding: 0 30px; }

            .collapse-header {
                padding: 30px 60px;

                &::before,
                &::after {
                    right: 60px; } }

            .collapse-body {
                padding: 0 60px;

                article {
                    padding-bottom: 40px; } } } } }

.fui-segment-title {
    padding: 30px ($space * 3) 0;

    h4 {
        margin: 0; } }

.fui-segment {
    padding: 30px ($space * 3);

    .fui-boards.MuiGrid-container {
        margin-bottom: 60px;

        .MuiGrid-root.MuiGrid-item {
            width: 100%; } }

    .fui-item {
        .content {
            font-weight: $font-medium; } }

    .fui-board {
        background: $lightgray70;
        padding: 18px;

        h5 {
            color: $darkgray50;
            font-weight: $font-regular;
            font-size: 20px;
            margin: 0; }

        h3 {
            margin: ($space * 1.5) 0 0;

            .body1 {
                font-size: 1rem;
                font-weight: $font-regular; } } }

    @media screen and (min-width: 960px) {
        .fui-board h5 {
            font-size: 22px; }

        .fui-boards.MuiGrid-container {
            flex-wrap: nowrap;

            .MuiGrid-root.MuiGrid-item {
                flex: 1; } } } }
