@keyframes menuIn {
    0% {
        opacity: 0;
        transform: scale(0.9); }

    100% {
        opacity: 1;
        transform: scale(1); } }

.fui-select2[class*='-container'] {
    [class*='-IndicatorsContainer'] {
        [class*='-indicatorSeparator'] {
            display: none; }

        [class*='-indicatorContainer'] {
            font-family: "icomoon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 24px;
            color: $black50;

            &::before {
                content: ""; }

            svg {
                display: none; } } }

    [class*='-menu'] {
        margin-top: 0;
        outline: none;
        border: 0;
        border-radius: 0;
        box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.36); }

    [class*='-option'] {
        font-weight: $font-medium;

        &:hover,
        &:active {
            background: $lightgray100; } }

    [class*='-control'] {
        border-radius: 0;
        height: 50px;
        border: 2px solid $lightgray100;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            border-color: $black50; } } }

.fui-dropdown {
    // position: relative

    &.is-show {
        position: relative;
        z-index: 1000;

        .fui-dropdown-item {
            > i {
                transform: rotate(180deg); } } }

    .fui-dropdown-item {
        position: relative;
        cursor: pointer;
        display: block;
        font-size: 1rem;
        color: $black100;
        background: none;
        border: 0;

        i {
            font-size: 24px; }

        &[aria-expanded="true"] + .fui-menu {
            display: block;
            opacity: 1; } }

    &.is-button {
        width: 100%;
        max-width: 100%;

        button {
            padding-left: $space * 1.5;
            width: 100%;
            text-align: left;
            background: #ffffff;
            height: 50px;
            border: 2px solid $lightgray100;

            i {
                float: right; } } }

    &.is-reverse .fui-dropdown-item {
        color: $white; }

    &.is-border {
        display: inline-block;
        background-color: #fff;
        padding: 12px 5px;
        border: 2px solid $lightgray100;
        width: 100%;
        height: 50px;

        button.fui-dropdown-item {
            display: flex;
            align-items: center;
            width: 100% !important;
            justify-content: space-between;

            span, i {
                color: $black50;
                font-weight: 500; } } }

    &.with-arrow {
        i[class*='icon-'] {
            font-size: 24px;
            margin-left: $space * 1; }

        * {
            vertical-align: middle; } }

    .fui-menu {
        position: absolute;
        min-width: 180px;
        width: 100%;
        background: $white;
        padding: 0;
        display: none;

        .fui-item {
            height: 42px;
            font-weight: 500;
            color: $black50;
            text-align: center;

            &.active .content,
            &.active:hover .content {
                color: $error; }

            .content {
                line-height: 26px; }

            &:hover {
                color: $black100;
                font-weight: 700;
                background: $lightgray100 !important; } } }

    .fui-dropdown .menu-wrapper {
        padding: 0; }

    .menu-wrapper {
        background-color: #fff;
        box-shadow: 0 24px 48px 0 #{"rgba(75, 88, 105, 0.36)"};
        padding: 8px 0;
        max-height: 280px;
        overflow-y: auto;
        overflow-x: hidden;

        .fui-menu-group {
            border-bottom: 2px solid $gray30;
            padding: 6px 0;

            &:last-child {
                border-bottom: 0; } }

        .fui-item:hover {
            color: $black50 !important; } }

    &.is-left {
        .menu-wrapper {
            .fui-item {
                text-align: left;
                padding-left: 14px; } } }

    &.is-right.is-top .fui-menu {
        transform-origin: 100% 0%; }

    &.is-right .fui-menu {
        right: 0; }

    &.is-top .fui-menu {
        top: 110%; }

    &.is-bottom .fui-menu {
        bottom: 110%; }

    .active {
        position: relative; }

    .check-icon.active {
        &::after {
            content: "";
            position: absolute;
            right: 18px;
            top: 0px;
            bottom: 0;
            width: 24px;
            height: 24px;
            background: none;
            display: block;
            margin: auto;
            color: $main;
            text-align: center;
            font-size: 16px;
            font-family: icomoon !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale; } }

    &.for-banner {
        position: relative;
        display: inline-block;
        margin-bottom: $space * 6;
        z-index: 2;

        .fui-dropdown-item {
            font-size: 36px;
            line-height: 48px;
            border-bottom: solid 4px $accent;
            text-align: left;
            display: flex;
            font-weight: $font-medium;
            align-content: center;
            align-items: center;
            padding-left: 0;
            padding-right: 0;

            i[class*='icon-'] {}

            font-size: 32px; }

        .fui-menu {
            width: 100%;
            top: 100%;
            bottom: 0;
            margin: auto;

            .menu-wrapper {
                margin-top: -40%; }

            .fui-item {
                height: auto;
                line-height: 1.25;
                font-size: 18px;
                padding: ($space * 2) ($space * 2.5);
                text-align: left; } } }

    @media screen and (min-width: 960px) {
        &.for-banner {
            margin-bottom: $space * 11;

            .fui-dropdown-item {
                font-size: 42px;
                line-height: 63px; }

            .fui-menu .menu-wrapper .fui-item {
                font-size: 28px; } } } }
