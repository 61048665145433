input,
textarea {
    &::-ms-clear {
        display: none !important; }

    &[disabled] {
        background: $lightgray70; } }

form.w-100 {
    width: 100%; }

form.fui-container {
    padding-bottom: 80px;

    .MuiGrid-root {
        .form-group {
            margin-bottom: $space * 2; } }

    button {
        width: 100%; } }

.fui-upload-container {
    margin-bottom: $space * 8; }

@keyframes scanning {
    5% {
        background-position: 0% 0%; }

    45% {
        background-position: 0% 100%; }

    55% {
        background-position: 0% 100%; }

    95% {
        background-position: 0% 0%; } }

.fui-dropzone {
    position: relative;
    margin-bottom: $space * 1.5;

    &.is-scanning {
        &::before,
        &::after {
            content: "";
            width: 82px;
            height: 72px;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: -20px;
            bottom: 0;
            margin: auto;
            background-repeat: no-repeat;
            z-index: 99; }

        &::before {
            background-image: url(#{$publicURL}/resources/cbu/estore/scan-border@2x.png);
            background-size: contain; }

        &::after {
            background-position: 0% 0%;
            background-size: 100% auto;
            background-image: url(#{$publicURL}/resources/cbu/estore/scan-bar@2x.png);
            animation: scanning 1.5s ease-in-out infinite; }

        > div::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(255, 255, 255, 0.8); } }

    &.has-error {
        margin-bottom: $space * 3;

        @media screen and ( max-width: 959px ) {
            margin-bottom: $space * 7; } }


    &.has-error > div {
        border: 2px solid #F05C38;
        background: rgba(240, 92, 56, 0.1);

        .error-message {
            margin-top: $space * 3;
            text-align: center; }

        .error {
            position: absolute;
            left: 18px;
            top: 18px;
            display: block;
            border-radius: 50%;
            width: 30px;
            line-height: 30px;
            height: 30px;

            * {
                vertical-align: middle; }

            .text {
                font-size: 1rem;
                color: $error; }

            .icon-error {
                position: relative;
                float: left;
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 6px;
                border-radius: 50%;
                background: #F05C38;
                position: relative;

                &::before {
                    top: 6px;
                    width: 3px;
                    height: 13px; }

                &::after {
                    width: 3px;
                    height: 3px;
                    bottom: 6px; }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    background: $white; } }

            // + .preview-img
            //     margin-top: 12px
            //     margin-bottom: 12px
 } }            //     height: 240px

    > div {
        width: 100%;
        height: 285px;
        border: dashed 4px rgba(149, 163, 164, 0.4);
        background-color: #f1f3f5;
        text-align: center;

        .upload-preview {
            position: absolute;
            display: inline-block;
            left: 0;
            width: 100%;
            height: 280px;

            .check {
                position: absolute;
                left: 18px;
                top: 18px;
                display: block;
                border-radius: 50%;
                width: 30px;
                line-height: 30px;
                height: 30px;
                color: $white;
                background: $blue50; }

            .preview-img {
                margin: ($space * 2.5) ($space * 2) ($space * 1.25);
                height: 195px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat; }

            img {
                height: 150px;
                width: auto;
                display: block;
                margin: 0 auto; }

            .fui-button {
                margin: 0; } }

        .upload-description {
            padding: ($space * 4) ($space * 2);

            p {
                color: $gray100;
                margin-top: 0;
                margin-bottom: $space * 3; } } }

    @media screen and (max-width: 768px) {
        > div {
            height: 340px;

            .upload-description {
                margin-top: $space * 2.5; }

            .upload-preview {
                padding: 0;
                height: 320px;

                .preview-img {
                    margin: ($space * 5) ($space * 2) ($space * 1.25);
                    height: 230px; }

                .check {
                    top: 12px;
                    left: 12px;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;

                    i {
                        font-size: 12px; } }

                img {
                    height: 140px; }

                .error {
                    top: 12px;
                    left: 12px;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;

                    .icon-error {
                        float: left;
                        transform: scale(0.8);
                        margin-left: -3px;
                        margin-top: -3px; }

                    // + .preview-img
                    //     margin-top: 0
 } } } } }                    //     height: 300px

.form-editor {
    &--list {
        border: solid 2px $lightgray100;
        border-bottom: 0;
        display: flex;
        flex-direction: row;

        &:nth-last-child(1) {
            border-bottom: solid 2px $lightgray100; }

        &--left {
            flex: 0 0 300px;
            border-right: solid 2px $lightgray100;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $darkgray50;
            background-color: $lightgray70; }

        &--right {
            background-color: $white;
            width: 100%;

            .head {
                padding: 12px 30px;
                border-bottom: solid 2px $lightgray100;
                color: $darkgray50; }

            .content {
                padding: 30px;

                .text-input {
 }                    // max-width: 492px

                small {
                    color: $gray100;
                    display: flex;
                    height: 100%;
                    align-items: center; } } }

        .d-flex {
            .form-editor--list--right:nth-child(2) {
                border-top: 2px solid $lightgray100; } } } }

.fui-select {
    // position: relative
    // z-index: 9

    &.is-invalid {
        .fui-dropdown.is-button button {
            border-color: $error; }

        .error-message {
            margin-top: $space * 1.5;
            display: inline-block; } }

    select {
        display: none;

        &[disabled] + .fui-dropdown {
            pointer-events: none;

            > button {
                background: $lightgray70;
                color: $gray70; } }

        + .fui-dropdown > button[aria-expanded="true"] {
            border-color: $grayblue; }

        + .fui-dropdown + .error-message {
            margin-top: $space * 1.5; } }

    .fui-dropdown {
        margin-left: 0;

        button {
            display: flex;
            width: 100%;
            height: 50px;
            text-align: left;
            padding-left: $space * 1.5;
            border: 2px solid $lightgray100;
            justify-content: space-between;
            align-content: center;
            align-items: center;

            i {
                float: right;
                margin-top: 4px; } }

        .fui-menu .menu-wrapper {
            .fui-item {
                text-align: left;

                .content {
                    font-weight: $font-regular;
                    padding: 0 ($space * 2); } } } } }

.form-description {
    margin-bottom: 12px;
    margin-top: 0; }

.required-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: $error;
    vertical-align: middle;
    margin-right: $space; }

.fui-textarea {
    line-height: 0;
    border: 2px solid $lightgray100;
    margin-bottom: 15px;

    &.is-focus {
        border-color: $grayblue; }

    textarea {
        border: 0;
        min-height: 306px;
        background: #FFF;
        padding: 18px 12px;
        line-height: 1.75;
        font-size: 1rem;
        border-bottom: 0 !important;
        width: 100%; }

    textarea + .max-length-counter {
        background: $lightgray100;
        color: $darkgray50;
        padding: 8px 18px;
        text-align: right;
        line-height: 1.75;
        height: 40px; } }

.input-row {
    .form-group {
        width: 100%;
        margin-top: 0; } }

.error-message {
    font-size: 0.875rem;
    color: $error;
    margin-top: $space * 1.5;
    text-align: left; }

.form-group {
    margin: 0 0 30px;
    display: flex;
    flex-direction: column;

    .example {
        margin-top: $space * 2;
        font-size: 1rem;

        span {
            font-size: 0.85rem;
            padding: 0 ($space / 2);
            margin-bottom: $space / 2;
            border: 1px solid $grayblue; } }

    .react-datepicker-wrapper input {
        cursor: pointer; }

    .fui-list {
        .fui-item {
            padding: ($space / 2) 0;

            .content {
                font-size: 1rem;
                line-height: 1.35; }

            .prefix i.bulleted-dot {
                background: $darkgray50; } } }

    + .fui-dropdown.fui-keyword-menu {
        margin-top: -$space;
        box-shadow: 0 16px 32px 0 rgba(75, 88, 105, 0.3);

        > .fui-item {
            text-align: left;

            .content {
                font-weight: $font-regular;
                padding: 0 ($space * 2); }

            &.is-selected {
                background: $lightgray70; } } }

    + .privacy-note {
        margin-top: -$space * 5;
        margin-bottom: 60px;
        font-size: 0.875rem;

        a:not(.sub-link-title):link,
        a:not(.sub-link-title):visited {
            color: #1A73E8 !important;

            &::after {
                background: #1A73E8 !important; } } }

    &.is-two {
        label.radio {
            width: calc( 50% - 12px); }

        .content {
            flex-direction: column; } }

    &.is-popup {
        .radio.is-icon-box {
            .content {
                padding: 54px 24px;

                .text {
                    position: absolute;
                    bottom: 25px; } }

            .icon {
                position: relative;

                .open-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    position: absolute;
                    right: 20px;
                    bottom: 30px;
                    background-color: $white;
                    box-shadow: 0 16px 32px 0 rgba(75, 88, 105, 0.3);
                    cursor: pointer;

                    i {
                        font-size: 30px;
                        color: $black100; } } } }

        label.radio {
            .content {
                height: auto;

                .icon {
                    img {
                        width: 100%;
                        height: auto; } } } } }

    @media screen and (max-width: 768px) {
        + .privacy-note {
            margin-top: -$space * 3; } }

    @media screen and (min-width: 600px) {
        &.two-buttons {
            display: block; } }

    .input-row {
        .MuiGrid-root.MuiGrid-container {
            margin-top: 0;
            margin-bottom: 0; }

        .MuiGrid-root.MuiGrid-item {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: $space * 3; }

        .text-input,
        .text-input input {
            width: 100%; } }

    label + .form-group,
    .radio-buttons {
        margin-bottom: 0; }

    &.last-full-width .radio-buttons .radio-button:last-child {
        width: 100%; }

    input.invalid {
        background-color: rgba(240, 92, 56, 0.1); }

    &.is-invalid {
        input {
            background-color: rgba(240, 92, 56, 0.1); }

        input,
        input:focus,
        .fui-textarea {
            border-color: $error; } }

    .text-input {
        margin-bottom: 0;

        .eye {
            position: absolute;
            width: 50px;
            height: 50px;
            right: 8px;
            top: 0;
            display: flex;
            justify-content: center;
            justify-items: center;
            align-content: center;
            align-items: center;

            + .reset {
                right: 60px; }

            &.pwd-show {
                opacity: 0.5; }

            i {
                font-size: 24px; } } }

    .credit-input {
        display: flex;
        align-items: center;
        align-content: center;

        input {
            min-width: 0; }

        span {
            margin: 0 $space; }

        @media screen and (max-width: 768px) {
            input {
                padding-left: $space;
                padding-right: $space; }

            span {
                margin: 0 ($space / 2); } } }

    label {
        display: block;
        font-size: 18px;
        font-weight: $font-medium;
        margin-bottom: $space * 2.25;

        i[class*="icon-"] {
            font-size: 24px;
            vertical-align: middle; } }

    input {
        border: solid 2px $lightgray100;

        &[disabled] {
            color: $gray70;

            + .reset {
                display: none; } } }

    input,
    textarea {
        min-width: 100%;
        max-width: 100%;
        color: $black50;
        font-size: 16px;
        padding: 12px;
        left: 0;
        width: 100%; }

    .fui-switch input {
        min-width: 0;
        width: auto; }

    input:focus,
    textarea:focus {
        border-color: $grayblue; }

    @media screen and (min-width: 960px) {
        label {
            min-height: 25px; } } }

@media screen and (max-width: 960px) {
    .form-editor {
        &--list {
            &:nth-last-child(1) {}

            &--left {
                flex: 0 0 86px;
                font-size: 14px;
                padding: 12px;
                text-align: center; }

            &--right {
                .head {
                    font-size: 14px;
                    padding: 6px 12px; }

                .content {
                    padding: 12px 12px;

                    .form-group {
                        label {
                            font-size: 18px; }

                        label.radio {
                            width: calc( 50% - 6px );
                            font-size: 18px; } }

                    .is-two {
                        label.radio:nth-last-child(1) {
                            margin-right: 0; } }

                    .radio {
                        .content {
                            .text {
                                display: flex;
                                flex: 1 1 auto;
                                align-items: center; } } } } } } }

    .form-group.is-two label.radio {
        width: 100%; }

    .form-group.is-popup {
        .radio.is-icon-box {
            .content {
                &::before {
                    bottom: auto;
                    top: 12px;
                    left: 12px; }

                &::after {
                    bottom: auto;
                    top: 22px;
                    left: 22px; } } } } }

.password-strength {
    display: flex;
    color: $red70;
    font-size: 0.875rem;
    margin-top: -15px;
    margin-bottom: 54px;
    align-content: center;
    align-items: center;

    @media screen and (min-width: 960px) {
        margin-top: -45px; }

    &.is-short::after {
        display: none; }

    &::after {
        content: "";
        display: flex;
        height: 12px;
        flex: 1;
        flex-grow: 1;
        background: $red70;
        margin-left: $space * 1.5; }

    &.is-normal {
        color: $yellow100;

        &::after {
            background: $yellow100; } }

    &.is-strong {
        color: #5f7b80;

        &::after {
            background: #5f7b80; } } }

.with-note .form-group {
    margin-bottom: 0; }

.modal-radio {
    .radio-buttons {
        flex-direction: column;

        .radio-button {
            margin-right: 0;
            width: 100%; }

        .d-flex {
            width: 100%; }

        input.input-column {
            width: 100%;
            position: relative;
            opacity: 1; } } }

.manage-input-group {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    h4 {
        margin: 0 0 18px 0; }

    h6 {
        margin: 18px 0; }

    .radio-buttons {
        margin-bottom: 0; }

    .input-group {
        input {
            margin-top: 18px; } }

    label {
        font-size: 18px;
        font-weight: $font-medium;
        margin-bottom: $space * 1.5;

        &.is-required::after {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            vertical-align: top;
            background: $error;
            margin-left: $space; }

        &::after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: #f05c38;
            border-radius: 50%;
            position: absolute;
            margin-left: 4px; } }

    input {
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding: 15px;
        border: solid 2px $lightgray100;
        color: $gray100; } }

.text-input {
    position: relative;
    display: inline-block;
    min-width: 300px;
    max-width: 100%;
    margin-bottom: 36px;

    input {
        font-size: 16px;
        padding: 12px 36px 12px 12px;
        width: 100%;
        border: 2px solid $lightgray100;

        &::-ms-clear {
            display: none; }

        &::placeholder {
            color: $gray100;
            font-weight: 500;

            @media screen and (max-width: 374px) {
                font-size: 0.875rem; } }

        &:-ms-input-placeholder {
            color: $gray100;
            font-weight: 500; }

        &::-ms-input-placeholder {
            color: $gray100;
            font-weight: 500; } }

    span {
        margin: 12px 0 -8px 0;
        color: #f05c38;
        font-size: 14px;
        display: block;

        &.error-message {
            margin: 12px 0 4px 0; } }

    .invalid {
        border: 2px solid #f05c38; }

    .valid {
        position: absolute;
        right: 15px;
        text-align: center;
        top: 0;
        bottom: 0;
        width: 24px;
        height: 24px;
        line-height: 26px;
        margin: auto;
        color: $white;
        border-radius: 50%;
        background: #85d1dc;

        i {
            font-size: 14px;
            margin-top: 2px; }

        + .reset {
            right: 50px; } }

    .reset {
        position: absolute;
        right: 14px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 24px;
        height: 24px;
        line-height: 24px;
        display: flex;
        font-size: 20px;
        cursor: pointer;

        i {
            line-height: 24px; } } }

// main.fui-form > .fui-container
//     margin-top: 60px

header.is-fixed ~ main.fui-form {
    margin-top: 60px;

    .fui-banner.is-form {
        .fui-container {
            margin-top: 0;
            height: 100%; } }

    .fui-banner.is-product {
        .fui-container {
            margin-top: 0;
            height: 100%; } }

    .fui-container {
        margin-top: 50px; }

    .fui-content.bg-arrow {
        padding-bottom: 0; } }

@media screen and (max-width: 960px) {
    .fui-form {
        .fui-banner.is-form {} }

    .text-input {
        width: 100%;
        min-width: initial;

        span.error-message {
            margin: 12px 0 -12px 0; } }

    .form-group textarea {
        height: 266px;
        min-height: initial; }

    .form-description {
        margin-top: 0; } }

@media screen and (min-width: 960px) {
    .fui-textarea textarea {
        min-height: 120px; }

    .form-group {
        margin-bottom: 54px;

        label {
            font-size: 20px; } }

    form.fui-container {
        padding-bottom: 120px;

        .MuiGrid-root .form-group {
            margin: 0 0 46px; }

        button {
            width: auto; } } }

input {
    -webkit-appearance: none; }

input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: #FFF !important;
    color: $black50 !important;
    box-shadow: 0 0 0 1000px white inset;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #333 !important; }
