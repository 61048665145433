.react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
        width: 100%;

        input {
            background: #ffffff url(#{$publicURL}/resources/cbu/e-service/images/icon-calendar.svg) 96% 9px no-repeat; }

        input[disabled],
        input:disabled {
            border: 2px solid #E8ECF0;
            background: #f1f3f5 url(#{$publicURL}/resources/cbu/e-service/images/icon-calendar-disable.svg) 96% 9px no-repeat;
            background-size: 28px; } } }

.react-datepicker-popper,
.react-datepicker__month-container {
    width: 100%;
    max-width: 492px; }

.react-datepicker {
    width: 100%;
    padding: 30px 0;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);

    .preorder-caption {
        text-align: center;
        padding: $space;

        > div {
            display: inline-flex;
            align-content: center;
            align-items: center;
            margin-right: $space * 2;

            > span {
                display: inline-block;
                width: $space * 1.5;
                height: $space * 1.5;
                border-radius: 50%;
                margin-right: $space * 0.5; } } }

    .react-datepicker__day--outside-month {
        color: $gray100;
        opacity: 0; }

    .react-datepicker__day--highlighted,
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__day--highlighted:active {
        background: $lightgray100;
        color: $black50; }

    .react-datepicker__navigation {
        top: 30px;
        width: 25px;
        height: 25px;
        border: 0 !important;

        &::after {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            transform: rotate(-45deg); }

        &.react-datepicker__navigation--previous::after {
            border-left: 2px solid $black50;
            border-top: 2px solid $black50; }

        &.react-datepicker__navigation--next::after {
            border-right: 2px solid $black50;
            border-bottom: 2px solid $black50; } }

    .react-datepicker__triangle {
        left: auto;
        right: 16px;
        margin: 0;
        border: 0;
        border-bottom-color: $white !important;

        &::before {
            border-bottom-color: $white !important; } }

    .react-datepicker__day-names {
        display: flex;

        .react-datepicker__day-name {
            flex: 1;
            flex-grow: 1;
            font-size: 18px;
            font-weight: $font-medium;

            @media screen and (max-width: 600px) {
                font-size: 16px; } } }

    .react-datepicker__header {
        background: none;
        padding: 0;
        border: 0;

        .react-datepicker__current-month {
            font-size: 18px;
            font-weight: $font-medium;
            margin-bottom: $space * 2;

            @media screen and (max-width: 600px) {
                font-size: 16px; }

            &.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
                opacity: 0; } }

        .react-datepicker__header__dropdown {
            margin-top: -41px;
            margin-bottom: $space * 2;

            .react-datepicker__year-dropdown,
            .react-datepicker__month-dropdown {
                background: $white;
                width: 120px;
                max-height: 300px;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                left: -42%;

                .react-datepicker__year-option,
                .react-datepicker__month-option {
                    height: 40px;
                    line-height: 40px;

                    &.react-datepicker__year-option--selected_year,
                    &.react-datepicker__month-option--selected_month,
                    &.react-datepicker__year-option--selected_year,
                    &.react-datepicker__month-option--selected_month {
                        color: $error; }

                    .react-datepicker__navigation {
                        top: 10px;

                        &.react-datepicker__navigation--years-upcoming::after {
                            border-top: 2px solid $black50;
                            border-right: 2px solid $black50; }

                        &.react-datepicker__navigation--years-previous::after {
                            border-bottom: 2px solid $black50;
                            border-left: 2px solid $black50; } }

                    .react-datepicker__month-option--selected,
                    .react-datepicker__year-option--selected {
                        display: none; }

                    &:hover {
                        background: $lightgray100; } } }

            .react-datepicker__year-read-view--selected-year,
            .react-datepicker__month-read-view--selected-month {
                visibility: visible !important; }

            .react-datepicker__month-dropdown-container,
            .react-datepicker__year-dropdown-container {
                position: relative;
                font-size: 18px;
                margin: 0 3px;

                .react-datepicker__year-read-view--down-arrow,
                .react-datepicker__month-read-view--down-arrow {
                    border: 0;
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                    top: 3px;
                    visibility: visible !important;

                    &::before {
                        border: 0;
                        left: 0;
                        z-index: 0;
                        bottom: auto;
                        font-family: "icomoon";
                        content: "";
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1; } } } } }

    .react-datepicker__month {
        .react-datepicker__week {
            display: flex;

            .react-datepicker__day {
                flex: 1;
                flex-grow: 1;
                font-size: 18px;
                padding: 6px 0;
                font-weight: $font-medium;
                border-radius: 0;

                @media screen and (max-width: 600px) {
                    font-size: 16px; }

                &:hover {
                    border-radius: 0; } }

            .react-datepicker__day--selected {
                background: $main !important;
                border-radius: 0; } } } }

@media screen and (max-width: 600px) {
    .react-datepicker {
        padding: 15px 0 10px;

        .react-datepicker__month .react-datepicker__week .react-datepicker__day {
            font-size: 16px;
            padding: 3px 0;
            line-height: 1.2rem;
            border-radius: 0; }

        .react-datepicker__header .react-datepicker__header__dropdown {
            .react-datepicker__year-read-view--selected-year,
            .react-datepicker__month-read-view--selected-month {
                font-size: 16px; } } } }

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
    background: $main !important; }
