.fui-menu-drawer {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: $white;
    z-index: 9;
    transform: translateX(100%);
    transition: transform 0.5s ease;

    .fui-menu-drawer-head {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        box-shadow: 0 2px 0 0 #e9e9e9;
        padding: 0 15px;
        z-index: 1;

        div[role="button"] {
            display: flex;
            width: 30px;
            height: 30px;
            align-items: center;
            align-content: center;

            i[class*='icon-'] {
                font-size: 1.5rem; } }

        .drawer-bar-center {
            flex: 1;
            flex-grow: 1;
            font-size: 1.125rem;
            font-weight: $font-medium;
            text-align: center; } }

    .fui-menu-drawer-body {
        position: absolute;
        display: block;
        width: 100%;
        top: 50px;
        height: calc(100% - 50px);

        .fui-menu-drawer-sidemenu {
            position: absolute;
            display: block;
            width: 116px;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            background: $lightgray50;
            box-shadow: 2px 0 0 0 $gray30;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none; }

            .fui-drawer-item {
                position: relative;
                text-align: center;
                padding: 20px 15px;

                img.icon {
                    width: 30px;
                    height: 30px;
                    display: block;
                    margin: 0 auto;

                    &.is-active {
                        display: none; } }

                .text {
                    white-space: wrap;
                    font-size: 1rem;
                    font-weight: $font-medium; }

                &.is-active {
                    background: $white;
                    color: $accent;

                    img.icon {
                        display: none; }

                    img.icon.is-active {
                        display: block; }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 8px;
                        height: 100%;
                        left: 0;
                        top: 0;
                        display: block;
                        background: $accent; } } } }

        .fui-menu-drawer-container {
            position: absolute;
            display: block;
            margin-left: 116px;
            left: 0;
            right: 0;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            display: none;
            -webkit-overflow-scrolling: touch;

            &.is-active {
                display: block; }

            > a.fui-item {
                height: auto;
                padding: 0;
                box-shadow: inset 0 -1px 0 0 $lightgray100;

                > span {
                    padding: 24px 28px;
                    font-size: 1.125rem; } }

            .fui-collapse {
                width: 100%;
                box-shadow: inset 0 -1px 0 0 $lightgray100;

                .collapse-body {
                    margin: 0;

                    article {
                        padding: 0;

                        a.fui-item {
                            height: 52px;
                            padding-left: 28px;
                            padding-right: 28px;
                            box-shadow: inset 0 -1px 0 0 $lightgray100; } } }

                .collapse-header {
                    padding: ($space * 3) ($space * 8) ($space * 3) 28px !important;
                    box-shadow: inset 0 -1px 0 0 $lightgray100;
                    margin: 0;

                    &::before,
                    &::after {
                        right: 30px; } } } } } }

.menu-open .fui-menu-drawer {
    transform: translateX(0%); }
