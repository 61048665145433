@media screen and (min-width: 768px) {
    section.promotion-card.card-multi-step {
        .fui-card {
            padding-top: 70px;

            .circle {
                font-size: 42px;
                width: 80px;
                height: 80px;
                top: -40px; } } } }

.fui-banner.is-estore,
.fui-banner.is-exclusive {
    height: 450px;
    padding: 0;
    min-height: 0;

    .fui-arrow {
        position: absolute;
        bottom: 30px;
        left: 15px;

        i {
            font-size: 24px; } }

    .bg {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 450px;
        background-size: cover;
        background-position: bottom center;

        img {
            position: absolute;
            width: 100%;
            height: auto;
            margin: auto;
            left: 0;
            top: auto;
            bottom: 0; } }

    .slick-initialized {
        .slick-slide {
            position: relative;
            height: 450px;
            overflow: hidden; }

        ul.slick-dots {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            padding: 0;
            height: 56px;
            bottom: 18px;
            text-align: center;
            list-style: none;
            vertical-align: bottom;

            li {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 56px;
                margin: 0 3px;
                opacity: 0.25;
                vertical-align: text-bottom;

                a,
                button {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 1;
                    cursor: pointer;
                    overflow: hidden;
                    border: 0;
                    text-indent: -9999px;
                    background: none;
                    cursor: pointer;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        bottom: 8px;

                        // top: 0
                        left: 0;

                        // margin: auto
                        background-color: $black50; } }

                &.slick-active {
                    opacity: 1; } } } }

    .fui-container {
        height: 100%;
        top: 0;
        padding: 0 15px 0; }

    .caption {
        width: 100%;
        padding-top: $space * 3;
        font-size: 20px;
        font-weight: bold;

        .fui-item {
            align-content: flex-start;
            align-items: flex-start;

            .text {
                font-size: 20px; }

            img {
                vertical-align: top; }

            img + .text {
                margin-left: 6px; } }

        .tag {
            position: relative;
            color: $accent;
            display: inline-block;
            font-size: 16px;
            height: 31px;
            line-height: 31px;
            margin-bottom: 12px;
            font-weight: $font-medium;

            .tag-bg {
                position: absolute;
                margin: 0 auto;
                width: calc(100% - 36px);
                font-size: 16px;
                left: 0;
                right: 0;
                top: 0;
                display: block;
                height: 31px;
                border-top: 3px solid $accent;
                border-bottom: 3px solid $accent;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: -3px;
                    background-repeat: no-repeat; }

                &::before {
                    width: 18px;
                    height: 45px;
                    left: -18px;
                    background-image: url(#{$publicURL}/resources/cbu/estore/tag-shape-01@2x.png);
                    background-position: left top;
                    background-size: 19px auto; }

                &::after {
                    width: 18px;
                    height: 31px;
                    right: -18px;
                    background-image: url(#{$publicURL}/resources/cbu/estore/tag-shape-02@2x.png);
                    background-position: right top;
                    background-size: auto 31px; } } }

        h1 {
            margin: 0 0 12px; }

        h5 {
            font-size: 20px;
            height: 64px;
            margin: 0 0;

            @include ellipsis(2); }

        .action {
            padding-top: $space * 3;

            .fui-button {
                min-width: 166px; } } }

    @media screen and (min-width: 600px) {
        .bg img {
            height: 500px;
            width: auto;
            top: 0;
            left: -100%;
            right: -100%; }

        .slick-initialized ul.slick-dots {
            bottom: $space * 6;

            li {
                width: 50px; } }

        .caption {
            position: relative;
            max-width: 680px;

            // font-size: 18px
            padding-top: 90px; }

        .tag {
            font-size: 1rem; }

        h5 {
            font-size: 22px;
            height: 66px; } }

    @media screen and (min-width: 1440px) {
        .bg img {
            width: 100%;
            height: auto;
            top: -100%;
            bottom: -100%; } } }

@media screen and (max-width: 600px) {
    .fui-banner.is-estore .action {
        .fui-button {
            min-width: calc(50% - 4px) !important;
            max-width: calc(50% - 4px);

            + .fui-button {
                margin-right: 0; } } } }

.fui-banner.is-exclusive {
    height: 450px;

    .bg,
    .slick-initialized .slick-slide {
        height: 450px; }

    .caption .action {
        display: flex;

        > * {
            min-width: 0;
            width: 50%;
            padding-left: $space * 3;
            padding-right: $space * 3;
            max-width: 220px; } }

    @media screen and (max-width: 374px) {
        .bg img {
            height: 100%; }

        .caption .action .fui-button {
            min-width: 142px; } }

    @media screen and (min-width: 768px) {
        height: 500px;

        .bg,
        .bg img,
        .slick-initialized .slick-slide {
            height: 500px;

            .caption .fui-container {
                width: 90%; } }

        .bg img {
            width: auto; } } }

.fui-estore-promotion {
    padding: 30px 0;
    background-size: cover;
    min-height: 360px;

    .promotion-container {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 15px;
        color: $white;

        h2 {
            margin-top: 3px;
            margin-bottom: 12px; }

        .fui-button {
            margin: 0; } }

    @media screen and (max-width: 767px) {
        background-position: bottom right !important;
        background-repeat: no-repeat !important; }

    @media screen and (min-width: 768px) {
        background-position: center right 35%; }

    @media screen and (min-width: 960px) {
        padding: 110px 0;
        min-height: 360px;

        h2 {
            margin-top: 6px;
            margin-bottom: 30px; } }

    @media screen and (min-width: 1200px) {
        background-position: center; } }

section.fui-promo-prod-section {
    .fui-tab,
    .fui-tab.with-scroller {
        a.fui-tab-item::before,
        .fui-tab-item::before {
            height: 4px; }

        .active-bar {
            height: 4px; } } }

.fui-cards.is-product {
    margin: 0 -6px;
    padding: 0;

    &.no-scrollbar {
        .fui-card.is-prod-promo,
        .fui-card.is-promotion,
        .fui-card.is-product {
            margin: 0 6px 12px;
            width: calc(50% - 12px);

            .fui-card-image img {
                transform: none; }

            @media screen and (min-width: 960px) {
                width: calc(25% - 12px); }

            .body {
                line-height: 30px; } } } }

.fui-card.is-product {
    position: relative;
    width: calc(50% - 12px);
    padding-top: $space * 4.5;
    background: $white;

    .fui-card-image {
        padding-bottom: 0;
        height: 180px;

        // text-align: center
 }        // position: absolute

    .fui-card-image img {
        position: absolute;
        width: auto;
        height: 180px;
        display: block;
        left: -100%;
        right: -100%;
        margin: auto;
        top: auto;
        bottom: auto; }

    @media screen and (max-width: 375px) {
        .fui-card-image > img {
            width: auto;
            height: 180px; } }

    .fui-card-action:hover {
        .fui-card-image img {
            transform: scale(1); } }

    .fui-card-caption {
        padding: 18px 12px;

        .fui-card-meta {
            color: $darkgreen;
            font-size: 0.875rem;
            margin-bottom: 3px; }

        .body {
            height: 60px;
            overflow: hidden;
            flex: 1 1 auto;
            flex-grow: 1;

            @include ellipsis(2);

            .tag {
                display: inline-block;
                line-height: 18px;
                height: 20px;
                padding: 0 6px;
                font-size: 0.75rem;
                border: 1px solid $black50;
                border-radius: 3px;
                margin-right: 6px;
                vertical-align: middle;
                font-weight: $font-medium; } }

        .fui-card-price {
            margin-top: 18px;
            font-size: 0.875rem;

            > div {
                margin-bottom: 3px; }

            .label {
                margin-right: 6px; }

            del {
                color: $gray100; }

            .price.is-text-error {
                font-size: 1rem; } } }

    .ribbon {
        position: absolute;
        left: 0;
        top: 0;
        color: $white;
        height: 26px;
        padding: 0 6px 0;
        line-height: 26px;
        font-size: 0.75rem;
        background-color: $coral100;
        z-index: 1;

        > * {
            position: relative;
            z-index: 1; }

        &::before {
            content: "";
            position: absolute;
            right: -26px;
            z-index: 0;
            border-left: 13px solid $coral100;
            border-top: 13px solid $coral100;
            border-bottom: 13px solid transparent;
            border-right: 13px solid transparent; } } }

.fui-table-scrollbox {
    max-height: 500px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }

@media screen and (min-width: 960px) {
    .fui-cards.four-cards .fui-card.is-product,
    .slick-slider .fui-card.is-product {
        .fui-card-image img {
            width: auto;
            max-width: 100%;
            height: 160px; } }

    .fui-card.is-product {
        padding-top: $space * 6;
        width: calc(25% - 12px);

        .fui-card-image img {
            width: auto;
            height: 180px; }

        .ribbon {
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            padding: 0 12px;
            font-size: 0.75rem;

            &::before {
                right: -26px;
                border-left-width: 13px;
                border-top-width: 13px;
                border-bottom-width: 13px;
                border-right-width: 13px; } }

        .fui-card-price {
            font-size: 1rem;
            margin-top: 12px; }

        .fui-card-caption {
            padding: ($space * 3) 30px 30px;

            .body {
                font-size: 20px;

                .tag {
                    height: 24px;
                    line-height: 22px; } }

            .fui-card-meta {
                margin-bottom: 6px; }

            .fui-card-meta,
            .body .tag {
                font-size: 0.875rem; } } } }

.fui-section-collapse {
    &.with-cart {
        margin-bottom: 80px; } }
