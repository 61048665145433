html, body {
    font-size: 16px;

    // min-height: 100vh
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

html {
    overflow-x: hidden; }

body {
    display: flex;
    flex-direction: column;
    overflow: hidden; }

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; }

.is-ebu:not(.is-help-center) main {
    margin-top: 50px;

    @media screen and (min-width: 960px) {
        margin-top: 100px;

        .is-ebu:not(.is-help-center) .fui-header.is-blank main {
            margin-top: 60px; } } }

main {
    display: block;
    margin-top: 50px;

    @media screen and (min-width: 960px) {
        margin-top: 100px; } }

@media screen and (min-width: 960px) {
    body.is-blank-header header + main .fui-nav-anchor + .fui-breadcrumb-container,
    body.is-blank-header header ~ main .fui-nav-anchor + .fui-breadcrumb-container,
    body.is-blank-header header + main .fui-nav-tab + .fui-breadcrumb-container,
    body.is-blank-header header ~ main .fui-nav-tab + .fui-breadcrumb-container {
        top: 45px; }

    body.is-blank-header header + main,
    body.is-blank-header header ~ main,
    .is-help-center header.is-fixed ~ main {
        margin-top: 60px; } }

header + main {
    position: relative;
    min-height: 650px;
    z-index: 3;

    &.is-fixed + main {
        display: block;
        margin-top: 50px; }

    @media screen and (min-width: 960px) {
        &.is-fixed + main {
            margin-top: 100px; }

        &.is-fixed.is-blank + main,
        &.is-fixed.is-help-center + main {
            margin-top: 60px; } } }

.promotion.expand {
    transform: scaleY(0);
    transform-origin: 50% 100%;

    > img {
        width: 100%;
        height: auto; } }

.promotion.corner-button {
    width: 58px;
    height: 58px;
    border-radius: 30px;
    border: 0;
    background: $black50;
    color: #FFF; }

.no-shadow {
    box-shadow: none !important; }

[role="button"] {
    cursor: pointer; }

@import "./header";
@import "./sidebar";
@import "./form";
@import "./footer";
@import "./figure";
@import "./section";
@import "./for-aem";
