section.login-renewal {
    background: url(#{$publicURL}/resources/cbu/estore-renewal/bg-cbu-colorslash-pattern-1440-x-594@2x.png);
    background-repeat: no-repeat;
    background-size: 100% auto;

    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 101px;
        height: 144px;
        left: 0;
        top: 99px;
        background: url(#{$publicURL}/resources/cbu/estore-renewal/img-cbu-arrow-pattern@2x.png);
        background-repeat: no-repeat;
        background-size: contain; }

    .login-renewal {
        padding: 60px; }

    .is-promotion-slider {
        position: relative;

        .slick-dots {
            width: 100%;
            padding: 0;
            margin: 0;
            position: absolute;
            bottom: 30px;
            text-align: center;

            li {
                margin: 0 3px;
                display: inline-block;
                height: 4px;
                vertical-align: middle;

                button {
                    line-height: 0;
                    border: 0;
                    width: 50px;
                    height: 4px;
                    color: transparent;
                    overflow: hidden;
                    background: rgba(0, 0, 0, 0.5); }

                &.slick-active {
                    button {
                        background: #fff; } } } }

        .fui-card.is-promotion {
            .fui-card-action .fui-card-content {
                padding: 60px;
                justify-content: flex-start;
                background: url(#{$publicURL}/resources/cbu/estore-renewal/subtitle-title-content@2x.png);

                h2 {
                    font-size: 36px; }

                h5 {
                    margin-top: 24px; }

                .action {
                    margin-top: 60px;

                    a:link,
                    a:visited {
                        color: $white;
                        font-size: 1rem;

                        &::after {
                            width: 100%;
                            background: $white !important; } } } } } }

    + section.is-sectin-feature {
        background: url(#{$publicURL}/resources/cbu/estore-renewal/cbu-feature-icon-green-1920x440.jpg);
        background-size: cover;

        img {
            margin-right: 30px;
            margin-bottom: 0; }

        img,
        h5 {
            display: inline-block;
            vertical-align: middle; }

        h5 {
            margin: 30px 0 0; } }

    @media screen and (max-width: 768px) {
        background: none;

        &::before {
            width: 48px;
            top: 0; }

        .is-promotion-slider {
            margin: 0 -20px;

            .fui-card.is-promotion {
                .fui-card-action .fui-card-content {
                    min-height: 321px;
                    padding: ($space * 6) 30px;

                    h2 {
                        font-size: 28px; }

                    h5 {
                        font-size: 20px;
                        margin-top: 12px; }

                    .action {
                        margin-top: 30px; } } } }

        + section.is-sectin-feature {
            background: url(#{$publicURL}/resources/cbu/estore-renewal/cbu-feature-icon-green-750x440.jpg);

            .MuiGrid-root.MuiGrid-container {
                padding-left: 30px; }

            h5 {
                margin-top: 0; }

            img {
                margin-right: 12px; }

            h2 {
                margin-bottom: 40px; } } } }

.number-preview {
    * {
        display: inline-block;
        line-height: 1.25;
        vertical-align: middle; }

    span {
        display: inline-flex;
        align-items: center; }

    span.divider {
        display: inline-block;
        margin: 0 ($space * 2);
        width: 1px;
        height: 36px;
        background: #d6d6d6; }

    @media screen and (max-width: 767px) {
        display: inline-block;
        width: 100%;

        > span {
            display: block;
            font-size: 22px;
            margin-top: 6px;

            &.divider {
                display: none; } } } }
