.fui-tab-pane .fui-checkbox-cards-container {
    padding-top: $space * 1.5; }

.fui-checkbox-cards-container {
    .no-result {
        text-align: center;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        color: $gray100;

        img {
            margin: 0 auto ($space * 1.5);
            line-height: 0; } }

    .tag-filter {
        margin-bottom: 18px;

        .fui-button.is-label {
            height: 36px;

            .text {
                height: 36px;
                line-height: 32px; }

            &.is-active {
                border-color: $black50 !important; } } }

    .fui-cards.no-scrollbar {
        padding-top: 0; }

    .slick-slider {
        .slick-arrow {
            display: block !important;
            position: absolute;
            width: 40px;
            height: 40px;
            background: $white;
            border-radius: 20px;
            overflow: hidden;
            border: 0;
            z-index: 1;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            color: transparent;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 1;
            transition: opacity 0.3s ease;

            &.slick-disabled {
                opacity: 0; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 28px;
                height: 28px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 28px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                z-index: 0; }

            &.slick-prev {
                left: -10px;

                &::after {
                    content: ""; } }

            &.slick-next {
                right: -10px;

                &::after {
                    content: ""; } } } }

    .slick-slider {
        margin: 0 -6px; }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        padding-bottom: $space * 3;

        .slick-slide {
            padding: 0 6px; } }

    .slick-slider .slick-track {
        white-space: nowrap;
        margin-left: 0;
        margin-right: auto;

        .slick-slide {
            float: none;
            display: inline-block;
            vertical-align: top; } }

    .slick-slider .slick-list {
        overflow: visible;
        height: auto !important; }

    .fui-card {
        position: relative;
        border: solid 2px rgba(47, 89, 196, 0.2);
        box-shadow: none !important;
        background: $white;
        display: inline-flex;
        flex-direction: column;

        label.fui-card-action {
            position: relative;
            padding-top: 48px;
            background: none;
            z-index: 1;
            display: flex;
            flex: 1 1 auto;
            flex-grow: 1;
            flex-direction: column; }

        &.is-checkbox.is-wireless {
            &::after {
                background: $white;
                z-index: 999; }

            &.is-checked::after {
                background: $error; }

            .fui-card-action {
                padding-top: 0;
                z-index: 0;

                .fui-card-image {
                    height: 214px;
                    padding-bottom: 0;

                    img {
                        height: 100%;
                        max-width: none;
                        width: auto;
                        left: -100%;
                        right: -100%;
                        margin: auto; } } } }

        &.is-checkbox {
            padding-top: 0;

            input[type='checkbox'] {
                position: absolute;
                opacity: 0; }

            &::after {
                content: "";
                font-family: "icomoon" !important;
                position: absolute;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                font-size: 1rem;
                display: block;
                width: 30px;
                height: 30px;
                color: transparent;
                display: block;
                top: 12px;
                left: 12px;
                pointer-events: none;
                border: solid 2px rgba(47, 89, 196, 0.2);
                text-align: center;
                line-height: 26px;
                box-sizing: border-box;
                z-index: 99; } }

        &.is-checked {
            border-color: $error;

            &::after {
                background: $error;
                border-color: $error;
                color: $white; } }

        &.is-plan {
            height: 380px;
            display: flex !important;
            flex-direction: column;

            .fui-card-extra {
                padding-bottom: 30px; }

            .fui-card-action {
                flex: 1 1 auto;
                padding-top: $space * 6;

                .fui-card-plan {
                    width: 100%;
                    height: 141px;
                    text-align: center;
                    color: $white;
                    background-size: auto 141px;
                    background-position: center;
                    background-repeat: no-repeat;

                    h1, h4 {
                        margin: 0; }

                    h1 {
                        line-height: 63px; }

                    h4 {
                        margin-top: -12px; }

                    &.is-friday {
                        background-image: url(#{$publicURL}/resources/cbu/estore-product/addition-plan-friday@2x.png); }

                    &.is-music {
                        background-image: url(#{$publicURL}/resources/cbu/estore-product/addition-plan-music@2x.png); } }

                .fui-card-caption {
                    .fui-card-title {
                        font-size: 20px;
                        height: auto;
                        font-weight: $font-regular; }

                    .fui-card-price {
                        margin-top: $space * 1.5; } } } }

        .fui-card-caption {
            padding: ($space * 3) 30px ($space * 1.5);

            .fui-card-title {
                font-size: 20px;
                height: 56px;

                @include ellipsis(2);

                span.tag {
                    display: inline-block;
                    line-height: 18px;
                    height: 20px;
                    padding: 0 6px;
                    font-size: 0.875rem;
                    border: 1px solid $black50;
                    border-radius: 3px;
                    margin-right: 6px;
                    vertical-align: middle;
                    font-weight: $font-medium; } }

            .fui-card-price {
                * {
                    font-size: 1rem; }

                .label {
                    margin-right: 6px; }

                del {
                    color: $gray100; }

                .price {
                    margin-left: 3px; } } }

        .fui-card-extra {
            padding-left: 30px;
            padding-right: 30px;

            > * {
                font-size: 0.875rem;
                font-weight: $font-medium; }

            .is-text-underline {
                display: inline-block;

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: $black50; } } }

        .fui-number-selector-container {
            padding: 0 30px 30px;
            width: 100%;

            .fui-number-selector {
                width: 100%;
                display: table;

                > div {
                    display: table-cell;
                    width: calc(100% - 80px);
                    margin: 0 40px;
                    line-height: 0;
                    height: 40px;
                    box-sizing: border-box;
                    vertical-align: middle;

                    input {
                        padding: 0;
                        height: 36px;
                        line-height: 36px; } }

                [role="button"] {
                    width: 40px;
                    line-height: 36px; } } } }

    @media screen and (max-width: 1024px) {
        .slick-slider .slick-track .slick-slide {
            min-width: 302px;
            max-width: 302px;

            .fui-card {
                width: 100%; } } }

    @media screen and (max-width: 960px) {
        .slick-list {
            overflow: visible; }

        .fui-cards.no-scrollbar {
            padding-top: 0;

            .fui-card {
                width: calc(50% - 12px); } }

        .fui-cards:not(.no-scrollbar) .fui-card {
            max-width: 290px; }

        .fui-card {
            width: 100%;

            &.is-plan {
                min-width: 290px;
                width: 290px;
                max-width: 290px; }

            &.is-checkbox::after {
                width: 24px;
                height: 24px;
                line-height: 20px; }

            .fui-card-caption {
                .fui-card-title {
                    height: 46px;
                    font-size: 1rem;

                    span.tag {
                        font-size: 0.75rem; } } }

            .fui-card-extra,
            .fui-card-caption {
                padding-left: 12px;
                padding-right: 12px; }

            .fui-card-action {
                padding-top: $space * 3; }

            .fui-number-selector-container {
                padding-left: 12px;
                padding-right: 12px;
                padding-bottom: 12px;

                .fui-number-selector > div {
                    position: relative;

                    input {
                        left: 0;
                        top: 0;
                        width: 100%; } } } } }

    @media screen and (max-width: 374px) {
        .slick-slider .slick-track .slick-slide {
            min-width: 280px;
            max-width: 280px;

            .fui-card-image img {
                width: auto;
                height: 140px; } } } }
