@font-face {
  font-family: 'icomoon';
  src: url('#{$publicURL}/resources/common/fonts/icomoon.eot?t121w2');
  src: url('#{$publicURL}/resources/common/fonts/icomoon.eot?t121w2#iefix') format('embedded-opentype'),
    url('#{$publicURL}/resources/common/fonts/icomoon.ttf?t121w2') format('truetype'),
    url('#{$publicURL}/resources/common/fonts/icomoon.woff?t121w2') format('woff'),
    url('#{$publicURL}/resources/common/fonts/icomoon.svg?t121w2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-carousel-next:before {
  content: '\e901';
}
.icon-carousel-prev:before {
  content: '\e902';
}
.icon-check:before {
  content: '\e900';
}
.icon-chevron-down:before {
  content: '\e906';
}
.icon-chevron-left:before {
  content: '\e907';
}
.icon-chevron-right:before {
  content: '\e908';
}
.icon-close:before {
  content: '\e903';
}
.icon-ebu-domain:before {
  content: '\e909';
}
.icon-ebu-email:before {
  content: '\e90a';
}
.icon-ebu-flow:before {
  content: '\e90b';
}
.icon-ebu-internet:before {
  content: '\e90c';
}
.icon-ebu-office365:before {
  content: '\e90d';
}
.icon-facebook:before {
  content: '\e912';
}
.icon-fetnet-arrow:before {
  content: '\e904';
}
.icon-fetnet-arrow-lg:before {
  content: '\e905';
}
.icon-lab:before {
  content: '\e90e';
}
.icon-member-cbu:before {
  content: '\e90f';
}
.icon-member-ebu:before {
  content: '\e910';
}
.icon-minus:before {
  content: '\e911';
}
.icon-office-idea:before {
  content: '\e913';
}
.icon-plus:before {
  content: '\e914';
}
.icon-search:before {
  content: '\e915';
}
.icon-shop-filled:before {
  content: '\e916';
}
.icon-strategy:before {
  content: '\e917';
}
.icon-youtube:before {
  content: '\e918';
}
.icon-view:before {
  content: '\e919';
}
.icon-line-sm:before {
  content: '\e91a';
}
.icon-facebook-sm:before {
  content: '\e91b';
}
.icon-no-more:before {
  content: '\e91c';
}
.icon-first:before {
  content: '\e91d';
}
.icon-last:before {
  content: '\e91e';
}
.icon-phone:before {
  content: '\e91f';
}
.icon-pc:before {
  content: '\e920';
}
.icon-help-center:before {
  content: '\e921';
}
.icon-business-response:before {
  content: '\e922';
}
.icon-business-customer-center:before {
  content: '\e923';
}
.icon-information:before {
  content: '\e924';
}
.icon-hide-view:before {
  content: '\e925';
}
