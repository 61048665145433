.fui-banner {
    position: relative;
    overflow: hidden;
    background-position: left center;
    background-size: cover;
    z-index: 3;
    padding-top: 0;
    min-height: 620px;

    &.is-360 {
        height: 360px;
        min-height: auto !important;

        .caption {
            top: 64px !important; } }

    &.is-text-dark {
        .caption {
            h1, .subtitle {
                color: $black100; } } }

    &.is-rate-plan {
        .fui-container {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;

            .caption {
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0;
                top: 0 !important;

                .subtitle {
                    margin-bottom: 0; } } } }

    &.is-adaptive {
        min-height: auto;
        background-position: center;

        .fui-container {
            height: 100%;
            top: 0; }

        h1 {
            display: flex;
            align-items: center;
            margin: 0;
            color: $white;
            height: 100%;
            padding: 0 15px; } }

    &.fui-cbu {
        background: $white; }

    &.fui-cbu,
    &.fui-ebu {
        overflow: visible;

        .fui-tab-container {
            .fui-tab {
                height: auto;
                overflow: hidden; } }

        + .fui-tab-pane {
            margin-bottom: 0; } }

    &.is-search {
        padding: 30px 0 24px;
        min-height: 0;
        background: $lightgray100;
        overflow: visible;
        background: url(#{$publicURL}/resources/common/images/ebu-banner-search.png) center no-repeat;
        background-size: cover;

        .fui-container {
            position: relative;
            z-index: 9;
            padding: 0 ($space * 2);

            &.keyword-container {
                z-index: 0;
                padding-right: 0; } }

        h2 {
            line-height: 36px;
            font-size: 1.5rem;
            margin-bottom: $space * 1.5; } }

    &.is-form {
        min-height: 170px;
        height: 170px;
        background-color: $gray50;
        color: $white;
        background-size: cover;
        background-position: center;
        z-index: 0;

        .fui-container {
            top: 0;
            display: flex;
            align-items: center;
            padding: 0 ($space * 2);
            margin-top: 0;
            height: 100%; }

        h1 {
            margin-bottom: 0;
            width: 100%; } }

    &.is-product {
        min-height: 450px;
        background-color: $navy50;
        background-position: center;
        background-size: cover;
        padding: 0 !important;
        z-index: 0;

        > img,
        .slick-slide {
            .banner-img > div {
                background-position: center;
                background-size: cover; }

            img {
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%; } }

        &.is-section-banner-1 {
            h1 {
                @include ellipsis(2); } }

        &.is-style-2 {
            .banner-img > div {
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                background-position: center;
                background-size: cover; }

            .caption {
                top: 80px;

                h1 {
                    font-size: 32px;

                    @include ellipsis(2); }

                .subtitle {
                    @include ellipsis(2);

                    > p {
                        margin: 0; }

                    h6 {
                        display: flex;
                        align-items: center;
                        margin: 0;

                        img {
                            margin: 0;
                            margin-right: 6px; }

                        &:nth-of-type(1) {
                            margin-top: 34px; } } }

                &::before {
                    content: none; } } }

        .slick-slider {
            ul.slick-dots {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                padding: 0;
                height: 4px;
                bottom: 30px;
                text-align: center;
                list-style: none;
                vertical-align: bottom;

                li {
                    display: inline-block;
                    width: 50px;
                    height: 4px;
                    margin: 0 3px;
                    background-color: $gray100;

                    button {
                        opacity: 0; }

                    &.slick-active {
                        background-color: $white; } } }

            .slick-slide,
            .slick-slide > div {
                position: relative;
                min-height: 468px;

                .bg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-position: center;
                    background-size: cover; }

                .caption {
                    position: absolute;
                    width: 100%;
                    max-width: 700px;
                    top: 105px;
                    bottom: auto;

                    h1 {
                        line-height: 42px;
                        font-size: 28px;

                        @include ellipsis(2); } } } }

        .caption {
            position: absolute;
            max-width: 620px;
            bottom: auto;
            padding: 46px 15px 0 15px;
            color: $white;
            top: 46px;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                top: 0;
                height: 36px;
                background: url(#{$publicURL}/resources/common/images/quotation.png) no-repeat left top;
                background-size: 36px;
                margin-bottom: $space * 1.5; }

            .subtitle {
                margin-bottom: $space * 4.5; } } }

    &.fade > .fui-banner-bg {
        opacity: 0; }

    .fui-container {
        position: absolute;
        padding: 0;
        left: 0;
        right: 0;
        width: 100%;

 }        // height: 100%

    .fui-tab-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        background: none;
        box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);

        @media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
            top: 0; }

        .fui-tab-header {
            background: #FFF;

            .fui-tab .fui-tab-item {
                padding: 12px 12px 0; } }

        .fui-tab-body {
            position: absolute;
            bottom: 0;
            background: none;
            z-index: 2;

            .fui-tab-pane {
                max-width: 100%;
                padding: 0 15px ($space * 6);

                .fui-button {
                    height: 50px;
                    display: block;
                    max-width: 180px;

                    span {
                        line-height: 50px; }

                    &.is-secondary {
                        span {
                            line-height: 46px; } } }

                h3.tab-title {
                    font-size: 32px;
                    line-height: 48px;
                    margin-top: $space * 4;
                    margin-bottom: 0;
                    width: 70%;
                    max-width: 400px; }

                @media screen and (min-width: 960px) {
                    h3.tab-title {
                        width: 100%; }

                    .fui-button {
                        display: inline-block; } } } } }

    .fui-banner-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 80px;
        left: 0;
        display: block;
        overflow: hidden;

        img.kv-bg {
            opacity: 0;
            transition: opacity 2s ease; }

        &.fade-out img.kv-bg {
            opacity: 0; }

        &.is-active img.kv-bg {
            opacity: 1; }

        .main-image {
            position: absolute;
            width: 100%;
            height: auto;
            top: -40px; }

        img.trapzoidal {
            position: absolute;
            height: 100%;
            left: -90px;
            bottom: 24px; }

        img.triangle {
            position: absolute;
            width: 100px;
            left: 15%;
            top: 18%; } }

    &.fui-cbu {
        min-height: 570px;
        height: 570px;

        .fui-banner-bg {
            height: 490px; }

        .fui-container {
            height: 100%;

            .fui-tab-body {
                bottom: 40px;

                .fui-tab-pane {
                    padding-bottom: 0;

                    .fui-dropdown.for-banner {
                        margin-bottom: 0;

                        @media screen and (max-width: 374px) {
                            max-width: 72%; } }

                    .tab-link-content {
                        > div {
                            position: relative;
                            display: block;
                            margin-top: 18px;

                            &:first-child > a.tab-link:link {
                                margin-top: 0; } } }

                    a.tab-link,
                    a.tab-link:link {
                        font-size: 24px;
                        position: relative;
                        display: inline-block;
                        font-weight: $font-medium;

                        &::after {
                            content: "";
                            position: absolute;
                            display: block;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            background: $black50; } }

                    @media screen and (min-width: 960px) {
                        padding-bottom: $space * 5;

                        .tab-link-content {
                            height: 260px;
                            column-width: 180px;
                            column-count: 2;
                            column-gap: 90px;
                            column-span: all;
                            margin-top: -$space * 4;

                            > div {
                                padding-top: $space * 4;
                                margin-top: 0; }

                            a.tab-link,
                            a.tab-link:link {
                                display: block;
                                font-size: 28px; } } } } } }

        .kv-arrow {
            position: absolute;
            max-width: 1920px;
            height: 100%;
            top: 80px;
            max-height: 490px;
            width: 100%;
            overflow: hidden; }

        .kv-arrow img {
            position: absolute;
            max-width: 1920px;
            height: auto;
            width: 100%;
            top: 0;
            left: -100%;
            right: -100%;
            margin: auto;
            z-index: 1;

            @media screen and (min-width: 510px) {
                width: 100%;
                height: auto; } }

        .kv-bg {
            position: absolute;
            max-width: 1920px;
            margin: auto 0 auto auto;
            height: auto;
            width: 100%;
            z-index: 0;

            @media screen and (min-width: 510px) {
                right: 0;
                left: auto; }

            @media screen and (min-width: 510px) and (max-width: 959pc) {
                height: auto;
                width: 100%; } }

        .fui-tab.with-icon {
            .fui-tab-item {
                .icon,
                .icon * {
                    width: 30px;
                    margin: 0 auto; } } }

        .fui-dropdown {
            max-width: 75%;
            margin-bottom: 0;

            .fui-dropdown-item {
                i.icon-chevron-down {
                    align-self: flex-end;
                    margin-bottom: 10px; } } }

        .action {
            margin: ($space * 3) 0;

            .fui-button {
                margin: 0 $space $space 0; } }

        .fui-tab-body {
            padding-top: 52px; }

        .fui-banner-tags {
            .fui-button.is-label {
                min-width: 0px !important;
                height: 34px;
                line-height: 30px;
                padding: 0 12px;
                text-align: center;
                font-size: 0.875rem;
                border: solid 2px $gray30;
                border-radius: 30px;
                margin-right: 6px;
                margin-bottom: 6px;

                &:hover {
                    border-color: $black50; }

                @media screen and (max-width: 374px) {
                    padding: 0 8px;
                    margin-right: 6px; } } }

        @media screen and (min-width: 960px) {
            .fui-container {
                top: 0;

                .fui-tab-container {
                    top: 95px;

                    .fui-tab-body {
                        bottom: 0;

                        .fui-tab-pane {
                            padding-top: 0; } } } }

            .kv-arrow,
            .fui-banner-bg {
                height: 620px;
                max-height: 620px; }

            .kv-arrow img {
                height: 100%;
                width: auto; }

            .kv-bg {
                width: auto;
                height: 100%;
                left: -100%;
                right: -100%;
                margin: auto;

 } }                // margin: auto 0 auto 5%

        @media screen and (min-width: 960px) and (max-width: 1920px) {
            .kv-bg {
                height: 100%;
                width: auto; } }

        @media screen and (min-width: 1920px) {
            .kv-bg {
                height: auto;
                width: 100%; } } }

    @media screen and (min-width: 600px) and (max-width: 812px) and (orientation: landscape) {
        &.is-solution .slick-slider .slick-slide img.d-md-block.d-none,
        img.d-sm-block.d-none {
            display: block !important;
            height: 100%;
            width: auto;
            max-width: none; }

        img.d-sm-none.d-block {
            display: none !important; } }

    @media screen and (max-width: 960px) {
        &.is-360 {
            height: 200px !important;

            .caption {
                position: relative;
                top: 0 !important; } }

        &.fui-cbu {
            .fui-container .fui-tab-body .fui-tab-pane .fui-dropdown {
                max-width: 350px; } } }

    @media screen and (max-width: 960px) and (min-width: 540px) {
        &.fui-cbu {
            .kv-arrow img {
                top: -20%; } } }

    @media screen and (max-width: 540px) and (min-width: 376px) {
        &.fui-cbu {
            .kv-arrow img {
                top: -25px; } } }

    @media screen and (min-width: 768px) {
        .fui-banner-bg {
            img.triangle {
                left: 15%;
                top: 28%;
                width: 15%; }

            img.trapzoidal {
                height: 120%;
                left: -240px;
                bottom: -1px; }

            .main-image {
                top: 0;
                right: 0;
                width: auto;
                height: 100%; } } }

    @media screen and (min-width: 960px) {
        padding: ($space * 12) 0;

        &.fui-cbu {
            height: 620px;

            .kv-arrow,
            .fui-banner-bg {
                top: 0; }

            .fui-dropdown {
                max-width: 75%;

                .fui-dropdown-item i.icon-chevron-down {
                    align-self: center;
                    margin: 0; } }

            .fui-tab-container .fui-tab-header .fui-tab .fui-tab-item {
                height: 100px;
                padding-top: 20px; } }

        .fui-banner-bg {
            img.triangle {
                left: 55%; } }

        &.is-search {
            padding: 84px 0 35px;
            min-height: 0px; }

        &.is-form {
            min-height: 240px;
            height: 240px;

            .fui-container {
                margin-top: 90px;
                height: initial; }

            img {
                position: absolute;
                height: auto;
                width: 100%; }

            h1 {
                max-width: initial; } }

        &.is-product {
            margin-top: 0;
            min-height: 468px;
            padding: 0;

            img {
                left: 0;
                right: 0;
                margin: auto; }

            .caption {
                top: 80px; }

            &.is-style-2 .slick-slider .slick-slide img {
                width: 100%;
                height: auto; }

            &.is-style-2 .caption,
            &.is-style-2 .slick-slider .slick-slide > div .caption {
                top: 80px;
                bottom: auto;

                h1 {
                    font-size: 42px; } }

            .slick-slider {
                min-height: 468px;

                .slick-slide,
                .slick-slide > div {
                    .caption {
                        // top: auto
                        bottom: 68px;

                        h1 {
                            font-size: 42px !important;
                            line-height: 63px; } } } } }

        .fui-container {
            padding: 0 15px; }

        .fui-tab-container {
            position: relative;
            background: #FFF;
            height: auto;
            max-width: 695px;

            .fui-tab-body {
                position: relative;

                .fui-tab-pane {
                    max-width: 100%;
                    padding: ($space * 4) ($space * 4) ($space * 7);

                    h3.tab-title {
                        font-size: 42px;
                        line-height: 60px;
                        margin-bottom: 0; } } } }

        .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
            max-width: initial; } }

    @media screen and (min-width: 1200px) {
        .fui-banner-bg {
            .main-image {
                left: -100%;
                right: -100%;
                width: auto;
                height: 100%;
                margin: auto; }

            img.trapzoidal {
                left: 0;
                width: 75%;
                height: auto; } } }

    // @media screen and (max-width: 1440px) and (min-width: 960px)
    //     &.fui-cbu .fui-banner-bg
    //         img.kv-bg
    //             margin-left: 5%

    @media screen and (max-width: 1920px) and (min-width: 960px) {
        &.fui-cbu .fui-banner-bg {
            img.kv-bg {
                height: 100%;
                width: auto;

 } } }                // margin-left: 5%

    @media screen and (min-width: 1921px) {
        &.fui-cbu .fui-banner-bg {
            img.kv-bg {
                height: auto;
                width: 100%;
                max-width: none; } } }

    @media screen and (min-width: 1440px) {
        .fui-banner-bg {
            img.trapzoidal {
                width: 60%;
                height: auto; }

            img.triangle {
                left: 50%; } } } }

.is-eservice .fui-banner.is-form {
    min-height: 147px;
    height: 147px;

    @media screen and (min-width: 960px) {
        min-height: 156px;
        height: 156px;
        padding: 0;

        .fui-container {
            margin-top: 50px;
            width: 90%; } } }

.is-eservice .fui-banner.is-helpcenter {
    min-height: 172px;
    height: 172px;

    @media screen and (min-width: 960px) {
        min-height: 240px;
        height: 240px;

        .fui-container {
            margin-top: 90px; } } }

.fui-nav-anchor + .fui-banner {
    margin-top: 60px; }

.fui-banner.fui-cbu {
    .fui-tab-container,
    .fui-banner-bg,
    .kv-arrow {
        opacity: 0; } }

.fui-banner.fui-cbu.fade-in {
    .fui-tab-container {
        animation: fadeIn 0.5s 1s ease forwards; }

    .fui-banner-bg {
        opacity: 0;
        transform: scale(1.1);
        animation: zoomIn 1s 0.5s ease forwards; }

    .kv-arrow {
        opacity: 0;
        transform: scale(1.1);
        animation: zoomIn 1s ease forwards; } }
