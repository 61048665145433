html, body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    font-size: $font-size;
    background: $lightgray50;

    @media screen and (max-width: 768px) {
        font-size: $font-size-sm; }

    &.sidebar-open {
        overflow: hidden; }

    &.no-promotion {
        footer.fui-footer {
            .fui-section-promo {
                display: none; } } } }

.is-loading #root {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;

 }    // height: 100vh

* {
    font-family: $font-family;
    box-sizing: border-box;
    outline: none; }

a, a:link, a:visited {
    display: inline-block;
    color: $black50;
    cursor: pointer;
    text-decoration: none; }

a.info-link {
    color: $accent;

    &::after {
        display: none !important; }

    &:not(:focus):hover {
        text-decoration: underline;
        color: $accent; } }

section p {
    a, a:link, a:visited {
        &:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button)::after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            background: $main;
            transition: all 0.3s ease; }

        &:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):not(:focus):hover::after,
        &:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):not(:focus):active::after {
            width: 100%; } } }

section p {
    a, a:link, a:visited {
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background: $accent;
            opacity: 0; }

        &:not(:focus):hover::after,
        &:not(:focus):active::after {
            opacity: 1 !important; } } }

.fui-section-collapse {
    a, a:link, a:visited {
        color: $accent; } }

*.is-api-loading {
    position: relative;
    min-height: 300px;
    pointer-events: none;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: block;
        background: rgba(255, 255, 255, 0.5) url(#{$publicURL}/resources/common/images/circle_loading.gif) no-repeat center;
        background-size: 60px;
        z-index: 9; } }

*.fui-button.is-api-loading {
    min-height: 0;
    pointer-events: none;

    &::before {
        background-size: 24px; } }

img {
    border: 0;

    // width: auto
 }    // height: auto

// .is-ebu,
//     a:not(.fui-item):not(.fui-button),
//     a:not(.fui-item):not(.fui-button):link,
//     a:not(.fui-item):not(.fui-button):visited
//         color: $business100
//         *
//             vertical-align: middle
//         &:hover,
//         &:active
//             color: $business50

//         &::after
//             background: $business100
//
// section p
//     a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button),
//     a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):link,
//     a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):visited
//         display: inline-block
//
//         &::after
//             background: $business50

body.is-webview {
    header, footer, .fui-nav-anchor {
        display: none !important; }

    header ~ main {
        margin-top: 0 !important; } }

img {
    line-height: 0; }

hr {
    border: 0;
    border-top: solid 1px $lightgray100;
    margin-top: $space * 3;
    margin-bottom: 30px; }

body.dropdown-open {
    .fui-nav-anchor,
    .fui-nav-tab.is-content-1 {
        z-index: 19; } }

main.delivery-page {
    position: relative; }

.is-underline-link,
.is-underline-link:link {
    display: inline-block !important;
    position: relative !important;
    color: $black50;
    cursor: pointer;
    font-size: 1rem;
    font-weight: $font-medium;
    margin-left: 6px;

    &.is-text-underline::after {
        width: 100% !important;
        opacity: 1; }

    &:hover {
        color: $accent;

        &::after {
            width: 100%;
            background: $accent !important; } }

    &::after {
        content: "";
        display: block;
        width: 0%;
        height: 2px !important;
        background: $black50 !important; }

    &.is-text-black50,
    &.is-text-black50:not(:focus):hover,
    &.is-text-black50:link,
    &.is-text-black50:link:not(:focus):hover {
        color: $black50;

        &::after {
            background: $black50 !important; } }

    &.is-text-darkgray50,
    &.is-text-darkgray50:not(:focus):hover,
    &.is-text-darkgray50:link,
    &.is-text-darkgray50:link:not(:focus):hover {
        color: $darkgray50;

        &::after {
            background: $darkgray50 !important; } }

    &.is-text-accent,
    &.is-text-accent:not(:focus):hover,
    &.is-text-accent:link,
    &.is-text-accent:link:not(:focus):hover {
        color: $accent;

        &::after {
            background: $accent !important; } } }

@import "./icon";
@import "./typography";
@import "./grid";
@import "./container";
@import "./display";
@import "./color";
@import "./space";
@import "./align";
@import "./table";
